/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import { FileUploader } from 'react-drag-drop-files';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import CircularProgress from '@mui/material/CircularProgress';

import FormFields from '../common/form-fields/FormFields';

import CloseIcon from '@mui/icons-material/Close';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import PDFViewer from '../common/PDFViewer';

interface AttachmentModalProps {
  handleClose: () => void;

  isOpen: boolean;
  handleSubmit: (file: any) => void;

  isAttachmentUpdating: boolean;
}

const AttachmentModal: React.FunctionComponent<AttachmentModalProps> = (props) => {
  const [fileUpload, setFileUpload] = React.useState<any>(null);

  const [isOpenPdfViewer, setIsOpenPDFViewer] = React.useState<boolean>(false);

  const handleErrorFileType = (err: any) => {
    console.log('ERRROR FILE', err);
    setFileUpload(null);
  };

  const handleChangeFileUpload = (file: any) => {
    console.log(file);
    setFileUpload(file);
  };

  const handleClickViewPDF = () => {
    setIsOpenPDFViewer(true);
  };

  const tooglePDFViewer = () => {
    setIsOpenPDFViewer(false);
  };

  return (
    <Dialog
      onClose={props.handleClose}
      aria-labelledby='customized-dialog-title'
      open={props.isOpen}
      sx={{
        '& .MuiDialog-paper': {
          width: '40%',
          height: '45%',
        },
      }}>
      <DialogTitle sx={{ m: 0, p: 2 }} id='customized-dialog-title'></DialogTitle>
      <IconButton
        aria-label='close'
        onClick={props.handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}>
        <CloseIcon />
      </IconButton>
      <DialogContent>
        {isOpenPdfViewer && <PDFViewer url={URL.createObjectURL(fileUpload) as any} isOpen={isOpenPdfViewer} handleClose={tooglePDFViewer} />}
        <Box>
          {fileUpload && ['image/png', 'image/gif', 'image/jpeg']?.includes(fileUpload?.type) && <img src={URL.createObjectURL(fileUpload)} alt='image' width='100%' />}

          {fileUpload && ['application/pdf']?.includes(fileUpload?.type) && (
            <Grid item xs={8} container gap={1} sx={{ border: '1px solid black', p: 1, borderRadius: '4px' }} alignItems='center' mb={2}>
              <Grid item xs={9.5}>
                {fileUpload?.name}
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={handleClickViewPDF}>
                  <RemoveRedEyeIcon />
                </IconButton>
              </Grid>
            </Grid>
          )}

          <FormFields
            id='attachment-file'
            componentType='CUSTOM_INPUT_COMPONENT'
            label='File'
            inputComponent={<FileUploader name='file' maxSize={10} types={['JPG', 'PNG', 'GIF', 'PDF']} handleChange={handleChangeFileUpload} onTypeError={handleErrorFileType} />}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant='outlined' onClick={props.handleClose} size='small'>
          Cancel
        </Button>

        <Button variant='contained' onClick={() => props.handleSubmit(fileUpload)} size='small' disabled={_.isEmpty(fileUpload?.name) || props.isAttachmentUpdating}>
          {props.isAttachmentUpdating && <CircularProgress />}
          {!props.isAttachmentUpdating && 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttachmentModal;
