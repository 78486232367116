/** @format */

import * as React from 'react';
import * as Joi from 'joi';
import * as _ from 'underscore';
import * as lodash from 'lodash';
import { DateTime } from 'luxon';
import axios, { CancelTokenSource } from 'axios';
import { useMutation } from '@tanstack/react-query';

import PrescriptionsGenerator from './PrescriptionsGenerator';

import {
  ComplaintsType,
  InvestigationTestType,
  PrescriptionVitalsType,
  PrescriptionsGeneratorMedicationsCommonItemType,
  PatientAlreadyPrescribedMedicationsType,
  PrescriptionGeneratorPatientAppointmensType,
  PrescriptionGeneratorTabsType,
  ExaminationsType,
  DiagnosisType,
  PatientPrescriptionsReportType,
  PatientPrescriptionPdfAttachemntsType,
  PrescriptionNotesType,
  PatientPrescriptionReviewListType,
  FluidIntakeType,
} from '../../types/PrescriptionsGenerator.types';

import { useAppDispatch } from '../../app/hooks';
import { setToast } from '../../reducers/toast-reducer/ToastReducer';

import { getPatientPrescriptionsPdfReport } from '../../service/patients';
import { getSystemConfigMedicationsWithBrandNames } from '../../service/config-medications';

import { formatPatientVitals } from '../../utils/FormatPatientVitals';
import {
  ObservationGeneralSchema,
  investigationTestSchema,
  notesSchema,
  patientAppointmentSchema,
  patientPrescriptionMedicationSchema,
  vitalsSchema,
} from '../../validations/prescription-generator.joi';

import { DIASTOLIC, SYSTOLIC, VITALS_BLOOD_PRESSURE, VITALS_BODY_TEMPERATURE, VITALS_HEART_RATE, VITALS_RESPIRATION_RATE, VITALS_SP02 } from '../../constants/PatientVitals';
import { ObservationInterface } from '../common/custom-observations/CustomObservations';
import { SystemConfigMedicationWithBrandName, SystemConfigMedicationWithBrandNameOptions } from '../../types/SystemMedications.types';
import { PatientInfoType } from '../../types/Patients.types';

export interface PrescriptionGeneratorContextProps {
  containerHeight: string;

  tabValue: PrescriptionGeneratorTabsType;
  handleChangeTabs: (event: React.SyntheticEvent, newValue: PrescriptionGeneratorTabsType) => void;

  complaints: ObservationInterface[];
  handleChangeComplaints: (payload: ObservationInterface[]) => void;
  complaintsErrors?: ComplaintsType[];

  vitals: PrescriptionVitalsType;
  vitalsErrors?: PrescriptionVitalsType;
  handleChangeVitals: (value: any, vitalType: string) => void;

  examinations: ObservationInterface[];
  handleChangeExaminations: (payload: ObservationInterface[]) => void;
  examinationsErrors?: ExaminationsType[];

  diagnosis: ObservationInterface[];
  handleChangeDiagnosis: (payload: ObservationInterface[]) => void;
  diagnosisErrors?: DiagnosisType[];

  labInvestigationTest: InvestigationTestType[];
  labInvestigationTestErrors?: InvestigationTestType[];
  handleNewLabInvestigationTest: (investagionTest: InvestigationTestType) => void;
  handleChangeLabInvestigationTest: (value: any, variable: string, rowIndex: number) => void;
  handleRemoveLabInvestigationTest: (index: number) => void;

  imageInvestigationTest: InvestigationTestType[];
  imageInvestigationTestErrors?: InvestigationTestType[];
  handleNewImageInvestigationTest: (investagionTest: InvestigationTestType) => void;
  handleChangeImageInvestigationTest: (value: any, variable: string, rowIndex: number) => void;
  handleRemoveImageInvestigationTest: (index: number) => void;

  procedures: ObservationInterface[];
  proceduresErrors?: ObservationInterface[];
  handleNewProcedures: (procedures: ObservationInterface) => void;
  handleChangeProcedures: (payload: ObservationInterface[]) => void;
  handleRemoveProcedures: (index: number) => void;

  nextReviewDate: string;
  nextReviewDateErrors?: string | null;
  handleChangeNextReviewDate: (date: string) => void;

  prescriptionDate: string;
  prescriptionDateErrors?: string | null;
  handleChangePrescriptionDate: (date: string) => void;

  patientAppointment: PrescriptionGeneratorPatientAppointmensType;
  patientAppointmentErrors?: PrescriptionGeneratorPatientAppointmensType;
  handleChangePatientAppointments: (appointmentValue: unknown, variable: keyof PrescriptionGeneratorPatientAppointmensType) => void;

  patientPrescriptionMedications: PrescriptionsGeneratorMedicationsCommonItemType[];
  patientPrescriptionMedicationsErrors?: PrescriptionsGeneratorMedicationsCommonItemType[];
  handleNewPatientPrescriptionsMedications: (payload: PrescriptionsGeneratorMedicationsCommonItemType) => void;
  handleRemovePatientPrescriptionsMedications: (index: number) => void;
  handleChangePatientPrescriptionMedications: (value: any, variable: keyof PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => void;

  prescriptionNotes: PrescriptionNotesType[];
  prescriptionNotesErrors?: PrescriptionNotesType[];
  handleNewPrescriptionsNotes: (notes: PrescriptionNotesType) => void;
  handleRemovePrescriptionNotes: (index: number) => void;
  handleChangeNotes: (payload: ObservationInterface[]) => void;

  reasonForChange: PrescriptionNotesType[];
  handleChangeReasonForChange: (payload: ObservationInterface[]) => void;

  searchConfigMedicationWithBrandName: (searchTerm: string) => Promise<SystemConfigMedicationWithBrandNameOptions[]>;

  isOpenPreviewModal: boolean;
  patientPreviewPresciptions: PatientPrescriptionsReportType | null;
  prescriptionsPreviewReportURL: PatientPrescriptionPdfAttachemntsType | null;
  prescriptionsPreviewReportURLIsLoading: boolean;
  handleClickOnPreviewBtn: () => void;
  handleClickOnDraftBtn: () => void;

  togglePatientPreviewModal: () => void;

  isSubmitApprovalPrescriptionUpdating: boolean;
  handleSubmitApprovalPrescriptions: () => void;

  isPatientPrescriptionUpdating: boolean;
  handleSubmitPatientPrescription: () => void;

  handleClickRejectPrescriptions: () => void;

  isDraftPrescriptionUpdating: boolean;

  patientReviewPrescriptions?: PatientPrescriptionReviewListType | null;

  patientAlreadyPrescribedMedications: PatientAlreadyPrescribedMedicationsType[] | null;

  patientInfo?: PatientInfoType;

  isOpenReviewDateWarningModal: boolean;
  handleClickOnSubmitReviewDateWarning: () => void;
  toggleReviewDateWarningModal: () => void;

  tabSwitchSaveObservations: { complaints: string; examinations: string; diagnosis: string; notes: string; reasonForChange: string; procedures: string };
  handleTabSwitchAndSaveObservationToContext: (value: string, observationName: 'COMPLAINTS' | 'EXAMINATIONS' | 'DIAGNOSIS' | 'NOTES' | 'REASON_FOR_CHANGE' | 'PROCEDURES') => void;

  fluidIntake: FluidIntakeType | null;
  fluidIntakeError: string | null;
  originalFluidIntake: FluidIntakeType;
  handleChangeFluidIntake: (value: number, variable: keyof FluidIntakeType) => void;
}

const defaultValue: PrescriptionGeneratorContextProps = {
  containerHeight: '50vh',

  tabValue: 'OBSERVATION_ASSESSMENTS',
  handleChangeTabs: console.info,

  complaints: [],
  handleChangeComplaints: console.info,

  vitals: {},
  handleChangeVitals: console.info,

  examinations: [],
  handleChangeExaminations: console.info,

  diagnosis: [],
  handleChangeDiagnosis: console.info,

  labInvestigationTest: [],
  handleNewLabInvestigationTest: console.info,
  handleChangeLabInvestigationTest: console.info,
  handleRemoveLabInvestigationTest: console.info,

  imageInvestigationTest: [],
  handleNewImageInvestigationTest: console.info,
  handleChangeImageInvestigationTest: console.info,
  handleRemoveImageInvestigationTest: console.info,

  procedures: [],
  handleNewProcedures: console.info,
  handleChangeProcedures: console.info,
  handleRemoveProcedures: console.info,

  prescriptionDate: '',
  handleChangePrescriptionDate: console.info,

  nextReviewDate: '',
  handleChangeNextReviewDate: console.info,

  patientAppointment: {} as PrescriptionGeneratorPatientAppointmensType,
  handleChangePatientAppointments: console.info,

  patientPrescriptionMedications: [],
  handleNewPatientPrescriptionsMedications: console.info,
  handleRemovePatientPrescriptionsMedications: console.info,
  handleChangePatientPrescriptionMedications: console.info,

  prescriptionNotes: [],
  handleNewPrescriptionsNotes: console.info,
  handleRemovePrescriptionNotes: console.info,
  handleChangeNotes: console.info,

  reasonForChange: [],
  handleChangeReasonForChange: console.info,

  searchConfigMedicationWithBrandName: async () => [] as SystemConfigMedicationWithBrandNameOptions[],

  isOpenPreviewModal: false,
  patientPreviewPresciptions: null,
  prescriptionsPreviewReportURL: {},
  prescriptionsPreviewReportURLIsLoading: false,
  handleClickOnPreviewBtn: console.info,
  handleClickOnDraftBtn: console.info,
  togglePatientPreviewModal: console.info,

  isSubmitApprovalPrescriptionUpdating: false,
  handleSubmitApprovalPrescriptions: console.info,

  isPatientPrescriptionUpdating: false,
  handleSubmitPatientPrescription: console.info,

  handleClickRejectPrescriptions: console.info,

  isDraftPrescriptionUpdating: false,

  patientReviewPrescriptions: null,

  patientAlreadyPrescribedMedications: null,

  isOpenReviewDateWarningModal: false,
  handleClickOnSubmitReviewDateWarning: console.info,
  toggleReviewDateWarningModal: console.info,

  tabSwitchSaveObservations: {
    complaints: '',
    examinations: '',
    diagnosis: '',

    notes: '',
    reasonForChange: '',

    procedures: '',
  },
  handleTabSwitchAndSaveObservationToContext: console.info,

  fluidIntake: null,
  fluidIntakeError: null,
  originalFluidIntake: { warningHigh: 1.5 },
  handleChangeFluidIntake: console.info,
};

export const PrescriptionGeneratorContext = React.createContext(defaultValue);

interface PrescriptionGeneratorProviderProps {
  patientSourceId: string;
  patientInfo: PatientInfoType;

  containerHeight: string;

  tabValue: PrescriptionGeneratorTabsType;
  handleChangeTabs: (event: React.SyntheticEvent, newValue: PrescriptionGeneratorTabsType) => void;

  patientAlreadyPrescribedMedications: PatientAlreadyPrescribedMedicationsType[];
  handleRemovePrescriptionsMedications?: (medications?: PrescriptionsGeneratorMedicationsCommonItemType) => void;

  isPatientPrescriptionUpdating: boolean;
  handleUpdatePatientPrescriptions: (payload: PatientPrescriptionsReportType) => void;

  isDraftPrescriptionLoading: boolean;
  handleSubmitDraftPrescriptions: (payload: PatientPrescriptionsReportType) => void;

  isSubmitApprovalPrescriptionUpdating: boolean;
  handleSubmitApprovalPrescriptions: (payload: PatientPrescriptionsReportType) => void;

  handleClickRejectPrescriptions: (payload: PatientPrescriptionReviewListType) => void;

  patientReviewPrescriptions?: PatientPrescriptionReviewListType | null;

  fluidDefaultValue: FluidIntakeType;
}

const PrescriptionGeneratorProvider: React.FunctionComponent<PrescriptionGeneratorProviderProps> = (props) => {
  const reduxDispatch = useAppDispatch();

  const [tabValue, setTabValue] = React.useState<PrescriptionGeneratorTabsType>('OBSERVATION_ASSESSMENTS');

  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);

  const [complaints, setComplaints] = React.useState<ObservationInterface[]>([]);
  const [complaintsErrors, setComplaintsErrors] = React.useState<ComplaintsType[]>([]);

  const [vitals, setVitals] = React.useState<PrescriptionVitalsType>({});
  const [vitalsErrors, setVitalsErrors] = React.useState<PrescriptionVitalsType>({});

  const [examinations, setExaminations] = React.useState<ObservationInterface[]>([]);
  const [examinationsErrors, setExaminationsErrors] = React.useState<ExaminationsType[]>([]);

  const [diagnosis, setDiagnosis] = React.useState<ObservationInterface[]>([]);
  const [diagnosisErrors, setDiagnosisErrors] = React.useState<DiagnosisType[]>([]);

  const [labInvestigationTest, setLabInvestigationTest] = React.useState<InvestigationTestType[]>([]);
  const [labInvestigationTestErrors, setLabInvestigationTestErrors] = React.useState<InvestigationTestType[]>([]);

  const [imageInvestigationTest, setImageInvestigationTest] = React.useState<InvestigationTestType[]>([]);
  const [imageInvestigationTestErrors, setImageInvestigationTestErrors] = React.useState<InvestigationTestType[]>([]);

  const [procedures, setProcedures] = React.useState<ObservationInterface[]>([]);
  const [proceduresErrors, setProceduresErrors] = React.useState<ObservationInterface[]>([]);

  const [nextReviewDate, setNextReviewDate] = React.useState<string>('');
  const [nextReviewDateErrors, setNextReviewDateErrors] = React.useState<string | null>(null);

  const [prescriptionDate, setPrescriptionDate] = React.useState<string>(DateTime.local().toISO() || '');
  const [prescriptionDateErrors, setPrescriptionDateErrors] = React.useState<string | null>(null);

  const [patientAppointment, setPatientAppointment] = React.useState<PrescriptionGeneratorPatientAppointmensType>({} as PrescriptionGeneratorPatientAppointmensType);
  const [patientAppointmentErrors, setPatientAppointmentErrors] = React.useState<PrescriptionGeneratorPatientAppointmensType>({} as PrescriptionGeneratorPatientAppointmensType);

  const [patientPrescriptionMedications, setPatientPrescriptionMedications] = React.useState<PrescriptionsGeneratorMedicationsCommonItemType[]>([]);
  const [patientPrescriptionMedicationsErrors, setPatientPrescriptionMedicationsErrors] = React.useState<PrescriptionsGeneratorMedicationsCommonItemType[]>([]);

  const [prescriptionNotes, setPrescriptionNotes] = React.useState<PrescriptionNotesType[]>([]);
  const [prescriptionNotesErrors, setPrescriptionNotesErrors] = React.useState<PrescriptionNotesType[]>([]);

  const [reasonForChange, setReasonForChange] = React.useState<PrescriptionNotesType[]>([]);

  const [isOpenReviewDateWarningModal, setIsOpenReviewDateWarningModal] = React.useState<boolean>(false);

  const [isOpenPreviewModal, setIsOpenPreviewModal] = React.useState<boolean>(false);
  const [patientPreviewPresciptions, setPatientPreviewPresciptions] = React.useState<PatientPrescriptionsReportType | null>(null);
  const [prescriptionsPreviewReportURL, setPrescriptionsPreviewReportURL] = React.useState<PatientPrescriptionPdfAttachemntsType | null>(null);

  const [fluidIntake, setFluidIntake] = React.useState<FluidIntakeType | null>(null);
  const [fluidIntakeError, setFluidIntakeErrors] = React.useState<string | null>(null);

  const [originalFluidIntake, setOriginalFluidIntake] = React.useState<FluidIntakeType>({ warningHigh: 1500 });

  const axiosSearchMedicationRef: { current: CancelTokenSource } = React.useRef<any>(null);

  const prescriptionsPreviewReportMutate = useMutation({
    mutationFn: async ({ patientPreviewPresciptions }: { patientPreviewPresciptions: PatientPrescriptionsReportType }) => {
      const report = await getPatientPrescriptionsPdfReport(props.patientSourceId, patientPreviewPresciptions);
      const attamentUrl = URL.createObjectURL(report);
      setPrescriptionsPreviewReportURL({ attamentUrl });
    },
    onError: () => {
      reduxDispatch(setToast({ message: 'Failed to get PDF Preview Report', code: 'error' }));
    },
  });

  const searchConfigMedicationWithBrandName = async (searchTerm: any) => {
    if (axiosSearchMedicationRef.current && axiosSearchMedicationRef.current?.token) {
      axiosSearchMedicationRef.current?.cancel();
    }

    axiosSearchMedicationRef.current = axios.CancelToken.source();

    try {
      const data = await getSystemConfigMedicationsWithBrandNames(30, 0, searchTerm, searchTerm, undefined, axiosSearchMedicationRef.current?.token);
      const medications = data.rows || [];
      return medications.map((item: SystemConfigMedicationWithBrandName) => ({ label: item.brandName, value: item.brandName, ...item }));
    } catch (err) {
      console.log(err);
      return [];
    }
  };

  const handleChangeTabs = (event: React.SyntheticEvent, newValue: PrescriptionGeneratorTabsType) => {
    props.handleChangeTabs(event, newValue);
    setTabValue(newValue);
  };

  const handleChangeComplaints = (payload: ObservationInterface[]) => {
    setComplaints(payload);
  };

  const handleChangeExaminations = (payload: ObservationInterface[]) => {
    setExaminations(payload);
  };

  const handleChangeDiagnosis = (payload: ObservationInterface[]) => {
    setDiagnosis(payload);
  };

  const handleChangeVitals = (value: any, vitalType: string) => {
    setVitals((prev) => ({ ...prev, [vitalType]: value }));
  };

  const handleChangeNotes = (payload: ObservationInterface[]) => {
    setPrescriptionNotes(payload.map((e) => ({ notes: e.mainText, id: e.id })));
  };

  const handleChangeReasonForChange = (payload: ObservationInterface[]) => {
    setReasonForChange(payload.map((e) => ({ notes: e.mainText, id: e.id })));
  };

  const handleNewLabInvestigationTest = (labInvestigatoinTest: InvestigationTestType) => {
    setLabInvestigationTest((prev) => prev.concat(labInvestigatoinTest));
  };

  const handleChangeLabInvestigationTest = (value: any, variable: string, rowIndex: number) => {
    setLabInvestigationTest((prev) => {
      return prev.map((item, index) => {
        if (index === rowIndex) {
          return { ...item, [variable]: value };
        }
        return item;
      });
    });
  };

  const handleRemoveLabInvestigationTest = (labInvestigationIndex: number) => {
    setLabInvestigationTest((prev) => prev.filter((item, index) => index !== labInvestigationIndex));
  };

  const handleNewImageInvestigationTest = (investigatoinTest: InvestigationTestType) => {
    setImageInvestigationTest((prev) => prev.concat(investigatoinTest));
  };

  const handleChangeImageInvestigationTest = (value: any, variable: string, rowIndex: number) => {
    setImageInvestigationTest((prev) => {
      return prev.map((item, index) => {
        if (index === rowIndex) {
          return { ...item, [variable]: value };
        }
        return item;
      });
    });
  };

  const handleRemoveImageInvestigationTest = (investigationIndex: number) => {
    setImageInvestigationTest((prev) => prev.filter((item, index) => index !== investigationIndex));
  };

  const handleNewProcedures = (procedures: ObservationInterface) => {
    setProcedures((prev) => prev.concat([procedures]));
  };

  const handleChangeProcedures = (payload: ObservationInterface[]) => {
    setProcedures(payload);
  };

  const handleRemoveProcedures = (procedureIndex: any) => {
    setProcedures((prev) => prev.filter((item, index) => index !== procedureIndex));
  };

  const handleChangeNextReviewDate = (nextReviewDate: string) => {
    setNextReviewDate(nextReviewDate);
  };

  const handleChangePrescriptionDate = (date: string) => {
    setPrescriptionDate(date);
  };

  const handleChangePatientAppointments = (appointmentValue: unknown, variable: keyof PrescriptionGeneratorPatientAppointmensType) => {
    setPatientAppointment((prev) => ({ ...prev, [variable]: appointmentValue }));
  };

  const handleNewPatientPrescriptionsMedications = (medications: PrescriptionsGeneratorMedicationsCommonItemType) => {
    setPatientPrescriptionMedications((prev) => prev.concat([medications]));
  };

  const handleRemovePatientPrescriptionsMedications = (medicationItem: number) => {
    setPatientPrescriptionMedicationsErrors((prev) => prev.filter((item, index: number) => index !== medicationItem));
    setPatientPrescriptionMedications((prev) => {
      props.handleRemovePrescriptionsMedications?.(prev.find((item, index) => index === medicationItem));
      return prev.filter((item, index) => index !== medicationItem);
    });
  };

  const handleChangePatientPrescriptionMedications = (value: any, variable: keyof PrescriptionsGeneratorMedicationsCommonItemType, rowIndex: number) => {
    setPatientPrescriptionMedications((prev) => {
      return prev.map((item, index) => {
        if (index === rowIndex) {
          item[variable] = value;
          return { ...item };
        }
        return item;
      });
    });
  };

  const handleNewPrescriptionsNotes = (medications: PrescriptionNotesType) => {
    setPrescriptionNotes((prev) => prev.concat([medications]));
  };

  const handleRemovePrescriptionNotes = (medicationsIndex: number) => {
    setPrescriptionNotes((prev) => {
      props.handleRemovePrescriptionsMedications?.(prev.find((item, index) => index === medicationsIndex) as any);
      return prev.filter((item, index) => index !== medicationsIndex);
    });
  };

  const togglePatientPreviewModal = () => {
    setIsOpenPreviewModal((prev) => {
      if (prev) {
        setPrescriptionsPreviewReportURL(null);
      }
      return !prev;
    });
  };

  const toggleReviewDateWarningModal = () => {
    setIsOpenReviewDateWarningModal((prev) => !prev);
  };

  const handleClickOnSubmitReviewDateWarning = () => {
    togglePatientPreviewModal();
    toggleReviewDateWarningModal();
  };

  const validateJoiArraySchema = (schema: Joi.Schema, data: any, optionsValidate: any = {}) => {
    const joiErrors = schema.validate(data, optionsValidate);

    console.log('ERRORDDDD validateJoiArraySchema', joiErrors);

    const index: any = joiErrors.error?.details?.[0]?.path?.[0];
    const variable = joiErrors.error?.details?.[0]?.path?.[1];
    let errors: any;
    if (index >= 0 && variable) {
      errors = new Array(data.length).fill(null);
      if (!errors[index]) {
        errors[index] = {};
      }
      errors[index][variable] = joiErrors.error?.details?.[0]?.message;
    }

    console.log('ERRORDDDD errorserrors', errors);

    return errors;
  };

  const validateJoiObjectSchema = (schema: Joi.Schema, data: any) => {
    const joiErrors = schema.validate(data);
    console.log('ERRORDDDD validateJoiObjectSchema', joiErrors, data);

    const path: Array<string | number> | undefined = joiErrors.error?.details?.[0]?.path;
    const errors = {};

    if (path && path?.length >= 0) {
      lodash.update(errors, path.join('.'), () => {
        return joiErrors.error?.details?.[0]?.message;
      });
    }

    console.log('ERRORDDDD errorserrors', errors);

    return errors;
  };

  const getObservationAssessmentError = () => {
    let isObservationAssessmentError = false;
    if (complaints && complaints.length > 0) {
      const errors = validateJoiArraySchema(ObservationGeneralSchema, complaints);
      if (!_.isEmpty(errors)) {
        setComplaintsErrors(errors);
        isObservationAssessmentError = true;
      } else {
        setComplaintsErrors([]);
      }
    }

    if (examinations && examinations.length > 0) {
      const errors = validateJoiArraySchema(ObservationGeneralSchema, examinations);
      if (!_.isEmpty(errors)) {
        setExaminationsErrors(errors);
        isObservationAssessmentError = true;
      } else {
        setExaminationsErrors([]);
      }
    }

    if (diagnosis && diagnosis.length > 0) {
      const errors = validateJoiArraySchema(ObservationGeneralSchema, diagnosis);
      if (!_.isEmpty(errors)) {
        setDiagnosisErrors(errors);
        isObservationAssessmentError = true;
      } else {
        setDiagnosisErrors([]);
      }
    }

    if (!_.isEmpty(vitals)) {
      const errors = validateJoiObjectSchema(vitalsSchema, vitals);
      if (!_.isEmpty(errors)) {
        setVitalsErrors(errors);
        isObservationAssessmentError = true;
      } else {
        setVitalsErrors({});
      }
    }

    return isObservationAssessmentError;
  };

  const getTreamentAdviceError = () => {
    let isTreamentAdviceError = false;

    if (patientPrescriptionMedications && patientPrescriptionMedications?.length > 0) {
      const errors = validateJoiArraySchema(patientPrescriptionMedicationSchema, patientPrescriptionMedications, { allowUnknown: true });
      if (!_.isEmpty(errors)) {
        setPatientPrescriptionMedicationsErrors(errors);
        isTreamentAdviceError = true;
      } else {
        setPatientPrescriptionMedicationsErrors([]);
      }
    }

    if (prescriptionNotes && prescriptionNotes?.length > 0) {
      const errors = validateJoiArraySchema(notesSchema, prescriptionNotes, { allowUnknown: true });
      if (!_.isEmpty(errors)) {
        setPrescriptionNotesErrors(errors);
        isTreamentAdviceError = true;
      } else {
        setPrescriptionNotesErrors([]);
      }
    }

    if (fluidIntake && fluidIntake?.warningHigh) {
      if (fluidIntake?.warningHigh < 100) {
        setFluidIntakeErrors('Fluid Intake should be greater than 100ml');
        isTreamentAdviceError = true;
      } else if (fluidIntake?.warningHigh > 8000) {
        setFluidIntakeErrors('Fluid Intake should be less than 8000ml');
        isTreamentAdviceError = true;
      } else {
        setFluidIntakeErrors(null);
      }
    }

    return isTreamentAdviceError;
  };

  const getInvestigationNextReviewError = () => {
    let formattedImageInvestigationTest: InvestigationTestType[] = [];
    let formattedLabInvestigationTest: InvestigationTestType[] = [];
    let isInvestigationNextReviewError = false;

    if (labInvestigationTest && labInvestigationTest?.length > 0) {
      formattedLabInvestigationTest = labInvestigationTest.map((item) => ({ ...item, investigationTests: item.investigationTests?.map((ele: any) => ele.value) || [] }));
      const sechame = investigationTestSchema(prescriptionDate, DateTime.local().plus({ months: 3 }).toISODate());
      const errors = validateJoiArraySchema(sechame, formattedLabInvestigationTest);
      if (!_.isEmpty(errors)) {
        setLabInvestigationTestErrors(errors);
        isInvestigationNextReviewError = true;
      } else {
        setLabInvestigationTestErrors([]);
      }
    }

    if (imageInvestigationTest && imageInvestigationTest?.length > 0) {
      formattedImageInvestigationTest = imageInvestigationTest.map((item) => ({ ...item, investigationTests: item.investigationTests?.map((ele: any) => ele.value) || [] }));
      const sechame = investigationTestSchema(prescriptionDate, DateTime.local().plus({ months: 3 }).toISODate());
      const errors = validateJoiArraySchema(sechame, formattedImageInvestigationTest);
      if (!_.isEmpty(errors)) {
        setImageInvestigationTestErrors(errors);
        isInvestigationNextReviewError = true;
      } else {
        setImageInvestigationTestErrors([]);
      }
    }

    if (procedures && procedures?.length > 0) {
      const errors = validateJoiArraySchema(ObservationGeneralSchema, procedures);
      if (!_.isEmpty(errors)) {
        setProceduresErrors(errors);
        isInvestigationNextReviewError = true;
      } else {
        setProceduresErrors([]);
      }
    }

    if (!_.isEmpty(patientAppointment) && patientAppointment.scheduleAppointment) {
      const errors = validateJoiObjectSchema(patientAppointmentSchema, patientAppointment);
      if (!_.isEmpty(errors)) {
        setPatientAppointmentErrors(errors as any);
        isInvestigationNextReviewError = true;
      } else {
        setPatientAppointmentErrors({} as any);
      }
    }

    if (_.isEmpty(nextReviewDate)) {
      setNextReviewDateErrors('Date is required');
      isInvestigationNextReviewError = true;
    } else if (new Date(nextReviewDate).getTime() < DateTime.fromJSDate(new Date(prescriptionDate)).endOf('day').toMillis()) {
      setNextReviewDateErrors('Date should be greater than Prescription Date');
      isInvestigationNextReviewError = true;
    } else if (new Date(nextReviewDate).getTime() > DateTime.local().plus({ months: 4 }).toMillis()!) {
      setNextReviewDateErrors('Date should be less than 4 months');
    } else {
      setNextReviewDateErrors(null);
    }

    return { isInvestigationNextReviewError, formattedImageInvestigationTest, formattedLabInvestigationTest };
  };

  React.useEffect(() => {
    if (isFormSubmitted) {
      getObservationAssessmentError();
    }
  }, [isFormSubmitted, complaints, examinations, diagnosis, vitals]);

  React.useEffect(() => {
    if (isFormSubmitted) {
      getTreamentAdviceError();
    }
  }, [isFormSubmitted, patientPrescriptionMedications, prescriptionNotes]);

  React.useEffect(() => {
    if (isFormSubmitted) {
      getInvestigationNextReviewError();
    }
  }, [isFormSubmitted, labInvestigationTest, imageInvestigationTest, procedures, patientAppointment, prescriptionDate, nextReviewDate]);

  const getFormattedPrescriptionPreview = () => {
    const formattedVitals = [];
    const timestamp = new Date().toISOString();

    const isObservationAssessmentError = getObservationAssessmentError();

    setIsFormSubmitted(true);

    if (isObservationAssessmentError) {
      setTabValue('OBSERVATION_ASSESSMENTS');
      return;
    }

    const isTreamentAdviceError = getTreamentAdviceError();

    if (isTreamentAdviceError) {
      setTabValue('TREATMENT_ADVICE');
      return;
    }

    const { isInvestigationNextReviewError, formattedLabInvestigationTest, formattedImageInvestigationTest } = getInvestigationNextReviewError();

    if (_.isEmpty(prescriptionDate)) {
      setPrescriptionDateErrors('Date is required');
      return;
    } else {
      setPrescriptionDateErrors('');
    }

    if (isInvestigationNextReviewError) {
      setTabValue('INVESTIGATIONS_NEXT_REVIEW');
      return;
    }

    if (!_.isEmpty(vitals.bloodPressure?.sbp) && !_.isEmpty(vitals.bloodPressure?.dbp)) {
      formattedVitals.push(
        formatPatientVitals(VITALS_BLOOD_PRESSURE.value, `${vitals.bloodPressure?.sbp!}/${vitals.bloodPressure?.dbp!}`, timestamp, VITALS_BLOOD_PRESSURE.unit, VITALS_BLOOD_PRESSURE.value),
      );
    }

    if (!_.isEmpty(vitals.pulseRate)) {
      formattedVitals.push(formatPatientVitals(VITALS_HEART_RATE.value, vitals.pulseRate as string, timestamp, VITALS_HEART_RATE.unit, VITALS_HEART_RATE.value));
    }

    if (!_.isEmpty(vitals.bodyTemperature)) {
      formattedVitals.push(formatPatientVitals(VITALS_BODY_TEMPERATURE.value, vitals.bodyTemperature as string, timestamp, VITALS_BODY_TEMPERATURE.unit, VITALS_BODY_TEMPERATURE.value));
    }

    if (!_.isEmpty(vitals.respirationRate)) {
      formattedVitals.push(formatPatientVitals(VITALS_RESPIRATION_RATE.value, vitals.respirationRate as string, timestamp, VITALS_RESPIRATION_RATE.unit, VITALS_RESPIRATION_RATE.value));
    }

    if (!_.isEmpty(vitals.sp02)) {
      formattedVitals.push(formatPatientVitals(VITALS_SP02.value, vitals.sp02 as string, timestamp, VITALS_SP02.unit, VITALS_SP02.value));
    }

    const prescriptionMedications = patientPrescriptionMedications.map((item) => {
      const medications: PrescriptionsGeneratorMedicationsCommonItemType = {
        brandName: item.brandName,
        medicationName: item.medicationName,
        medicationNameDisplayName: item.medicationNameDisplayName,

        saltComposition: item.saltComposition,

        dosage: item.dosage,
        dosageUnit: item.dosageUnit,
        dosageQuantity: item.dosageQuantity,

        duration: item.duration,
        durationUnits: item.durationUnits,

        frequencyRegime: item.frequencyRegime,

        isCustomMedications: item.isCustomMedications,

        directionOfUse: item.directionOfUse,
        reasonForChange: item.reasonForChange,
      };

      return medications;
    });

    const currentTime = DateTime.local();

    const patientPreviewPresciptions: PatientPrescriptionsReportType = {
      complaints: complaints.map((e) => {
        return {
          complaints: e.mainText,
          observedFrom: e.subText,
        };
      }),

      examinations: examinations.map((e) => ({ examinations: e.mainText })),
      diagnosis: diagnosis.map((e) => ({ diagnosis: e.mainText })),
      vitals: formattedVitals,

      procedures: procedures.map((e) => ({ procedures: e.mainText })),

      investigationTest: formattedLabInvestigationTest,
      imageInvestigationTest: formattedImageInvestigationTest,

      prescriptionDate: DateTime.fromJSDate(new Date(prescriptionDate)).set({ hour: currentTime.hour!, minute: currentTime.minute! }).toISO()!,
      nextReviewDate: DateTime.fromJSDate(new Date(nextReviewDate)).toISO()!,
      appointments: patientAppointment,

      notes: prescriptionNotes,

      reasonForChange,

      prescriptionMedications,

      version: '2.0',

      fluidIntake,
    };

    return patientPreviewPresciptions;
  };

  const handleClickOnPreviewBtn = () => {
    const patientPreviewPresciptions = getFormattedPrescriptionPreview();

    if (_.isEmpty(patientPreviewPresciptions)) {
      return;
    }

    let showWaringForReviewDate = false;

    patientPreviewPresciptions?.imageInvestigationTest?.forEach((item) => {
      const labDateMilli = item.date
        ? DateTime.fromJSDate(new Date(item.date)).startOf('day').toMillis()
        : DateTime.fromJSDate(new Date(patientPreviewPresciptions?.nextReviewDate))
          .minus({ day: item.dayBeforeReviw! as number })
          .startOf('day')
          .toMillis();
      if (DateTime.fromJSDate(new Date(patientPreviewPresciptions?.nextReviewDate)).startOf('day').toMillis() < labDateMilli) {
        showWaringForReviewDate = true;
      }
    });

    patientPreviewPresciptions?.investigationTest?.forEach((item) => {
      const labDateMilli = item.date
        ? DateTime.fromJSDate(new Date(item.date)).startOf('day').toMillis()
        : DateTime.fromJSDate(new Date(patientPreviewPresciptions?.nextReviewDate))
          .minus({ day: item.dayBeforeReviw! as number })
          .startOf('day')
          .toMillis();
      if (DateTime.fromJSDate(new Date(patientPreviewPresciptions?.nextReviewDate)).startOf('day').toMillis() < labDateMilli) {
        showWaringForReviewDate = true;
      }
      if (DateTime.fromJSDate(new Date(patientPreviewPresciptions?.prescriptionDate)).startOf('day').toMillis() > labDateMilli) {
        showWaringForReviewDate = true;
      }
    });

    setPatientPreviewPresciptions(patientPreviewPresciptions!);
    prescriptionsPreviewReportMutate.mutateAsync({ patientPreviewPresciptions: patientPreviewPresciptions! });

    if (showWaringForReviewDate) {
      toggleReviewDateWarningModal();
    } else {
      togglePatientPreviewModal();
    }
  };

  const handleReset = () => {
    setTabValue('OBSERVATION_ASSESSMENTS');
    setComplaints([]);
    setComplaintsErrors([]);
    setVitals({});
    setVitalsErrors({});
    setExaminations([]);
    setExaminationsErrors([]);
    setDiagnosis([]);
    setDiagnosisErrors([]);
    setLabInvestigationTest([]);
    setLabInvestigationTestErrors([]);
    setImageInvestigationTest([]);
    setImageInvestigationTestErrors([]);
    setProcedures([]);
    setProceduresErrors([]);
    setNextReviewDate('');
    setNextReviewDateErrors(null);
    setPrescriptionDate(DateTime.local().toISODate() || '');
    setPrescriptionDateErrors(null);
    setPatientAppointment({} as PrescriptionGeneratorPatientAppointmensType);
    setPatientAppointmentErrors({} as PrescriptionGeneratorPatientAppointmensType);
    setPatientPrescriptionMedications([]);
    setPatientPrescriptionMedicationsErrors([]);
    setIsOpenPreviewModal(false);
    setPatientPreviewPresciptions(null);
    setPrescriptionsPreviewReportURL(null);
    setPrescriptionNotes([]);
    setFluidIntake(null);
  };

  const handleSubmitPatientPrescription = async () => {
    if (patientPreviewPresciptions && prescriptionsPreviewReportURL) {
      await props.handleUpdatePatientPrescriptions(patientPreviewPresciptions);
      handleReset();
    }
  };

  const handleClickOnDraftBtn = () => {
    const patientPreviewPresciptions = getFormattedPrescriptionPreview();

    if (_.isEmpty(patientPreviewPresciptions)) {
      return;
    }

    props.handleSubmitDraftPrescriptions(patientPreviewPresciptions!);
  };

  const handleSubmitApprovalPrescriptions = async () => {
    if (patientPreviewPresciptions && prescriptionsPreviewReportURL) {
      await props.handleSubmitApprovalPrescriptions(patientPreviewPresciptions);
      handleReset();
    }
  };

  const handleClickRejectPrescriptions = async () => {
    if (props.patientReviewPrescriptions && props.patientReviewPrescriptions.reviewId) {
      await props.handleClickRejectPrescriptions(props.patientReviewPrescriptions);
    }
  };

  React.useEffect(() => {
    if (props.patientAlreadyPrescribedMedications && props.patientAlreadyPrescribedMedications.length > 0) {
      setPatientPrescriptionMedications((prev) => {
        props.patientAlreadyPrescribedMedications.forEach((item) => {
          if (item.addToNewPrescriptions) {
            const isMedicationAddedAlready = prev.find((ele) => ele.medicationName === item.medicationName);
            if (_.isEmpty(isMedicationAddedAlready)) {
              prev = prev.concat({ ...item, addedByPrevPrescriptions: true, medicationBrandNameObject: { value: item.brandName, label: item.brandName, ...item } });
            }
          } else if ('addToNewPrescriptions' in item && !item.addToNewPrescriptions) {
            const isMedicationAddedAlready = prev.find((ele) => ele.medicationName === item.medicationName);
            if (!_.isEmpty(isMedicationAddedAlready)) {
              prev = prev.filter((ele) => ele.medicationName !== item.medicationName);
            }
          }
        });
        return prev;
      });

      setPrescriptionNotes((prev) => {
        props.patientAlreadyPrescribedMedications.forEach((item) => {
          if (item.stopInNewPrescriptions) {
            const isMedicationAddedAlready = prev.find((ele) => ele.medicationName === item.medicationName);
            if (_.isEmpty(isMedicationAddedAlready)) {
              prev = prev.concat({
                medicationName: item.medicationName,
                dosage: item.dosage as string,
                notes: 'Stop ' + item.brandName,
                brandName: item.brandName,
                isCustomMedications: item.isCustomMedications,
              });
            }
          } else {
            const isMedicationAddedAlready = prev.find((ele) => ele.medicationName === item.medicationName);

            if (!_.isEmpty(isMedicationAddedAlready)) {
              prev = prev.filter((ele) => ele.medicationName !== item.medicationName);
            }
          }
        });
        return prev;
      });
    }
  }, [props.patientAlreadyPrescribedMedications]);

  React.useEffect(() => {
    if (props.patientReviewPrescriptions && props.patientReviewPrescriptions.prescriptions) {
      const reviewPrescriptions = props.patientReviewPrescriptions.prescriptions;

      if (!_.isEmpty(reviewPrescriptions?.complaints)) {
        setComplaints(
          reviewPrescriptions?.complaints!.map(
            (item, index) =>
              ({
                id: `${new Date().getTime()}-${index}`,
                mainText: item.complaints,
                subText: item.observedFrom,
              }) as ObservationInterface,
          ),
        );
      }

      if (reviewPrescriptions?.vitals) {
        const vitals: PrescriptionVitalsType = {};
        const bloodPressure = reviewPrescriptions?.vitals.find((item) => item.type === VITALS_BLOOD_PRESSURE.value);
        const sbp = bloodPressure?.measurements?.find((item) => item.name === SYSTOLIC)?.value || '';
        const dbp = bloodPressure?.measurements?.find((item) => item.name === DIASTOLIC)?.value || '';

        if (sbp && dbp) {
          vitals.bloodPressure = {
            sbp,
            dbp,
          };
        }

        const pulseRate = reviewPrescriptions?.vitals.find((item) => item.type === VITALS_HEART_RATE.value)?.measurements?.[0]?.value || '';

        if (pulseRate) {
          vitals.pulseRate = pulseRate;
        }

        const respirationRate = reviewPrescriptions?.vitals.find((item) => item.type === VITALS_RESPIRATION_RATE.value)?.measurements?.[0]?.value || '';

        if (respirationRate) {
          vitals.respirationRate = respirationRate;
        }

        const sp02 = reviewPrescriptions?.vitals.find((item) => item.type === VITALS_SP02.value)?.measurements?.[0]?.value || '';

        if (sp02) {
          vitals.sp02 = sp02;
        }

        const bodyTemperature = reviewPrescriptions?.vitals.find((item) => item.type === VITALS_BODY_TEMPERATURE.value)?.measurements?.[0]?.value || '';

        if (bodyTemperature) {
          vitals.bodyTemperature = bodyTemperature;
        }

        setVitals(vitals);
      }

      if (!_.isEmpty(reviewPrescriptions?.examinations)) {
        setExaminations(
          reviewPrescriptions?.examinations!.map(
            (item, index) =>
              ({
                id: `${new Date().getTime()}-${index}`,
                mainText: item.examinations,
              }) as ObservationInterface,
          ),
        );
      }

      if (!_.isEmpty(reviewPrescriptions?.diagnosis)) {
        setDiagnosis(
          reviewPrescriptions?.diagnosis!.map(
            (item, index) =>
              ({
                id: `${new Date().getTime()}-${index}`,
                mainText: item.diagnosis,
              }) as ObservationInterface,
          ),
        );
      }

      if (!_.isEmpty(reviewPrescriptions?.fluidIntake)) {
        setFluidIntake(reviewPrescriptions?.fluidIntake!);
      }

      if (!_.isEmpty(reviewPrescriptions?.investigationTest)) {
        setLabInvestigationTest(
          reviewPrescriptions?.investigationTest!.map((item) => ({
            ...item,
            investigationTests: item.investigationTests!.map((ele) => ({ label: ele, value: ele })) as any,
          })),
        );
      }

      if (!_.isEmpty(reviewPrescriptions?.imageInvestigationTest)) {
        setImageInvestigationTest(
          reviewPrescriptions?.imageInvestigationTest!.map((item) => ({
            ...item,
            investigationTests: item.investigationTests!.map((ele) => ({ label: ele, value: ele })) as any,
          })),
        );
      }

      if (!_.isEmpty(reviewPrescriptions?.procedures)) {
        setProcedures(
          reviewPrescriptions?.procedures!.map(
            (item, index) =>
              ({
                id: `${new Date().getTime()}-${index}`,
                mainText: item.procedures,
              }) as ObservationInterface,
          ),
        );
      }

      if (!_.isEmpty(reviewPrescriptions?.nextReviewDate)) {
        setNextReviewDate(reviewPrescriptions?.nextReviewDate!);
      }

      // if (!_.isEmpty(reviewPrescriptions?.prescriptionDate)) {
      //   setPrescriptionDate(DateTime.fromJSDate(new Date(reviewPrescriptions?.prescriptionDate!)).toISODate()!);
      // }

      if (!_.isEmpty(reviewPrescriptions?.appointments)) {
        setPatientAppointment(reviewPrescriptions?.appointments);
      }

      if (!_.isEmpty(reviewPrescriptions?.prescriptionMedications)) {
        console.log('patientPrescriptionMedications set state');
        setPatientPrescriptionMedications(
          reviewPrescriptions?.prescriptionMedications.map((item) => ({
            ...item,
            medicationBrandNameObject: { value: item.brandName, label: item.brandName, ...item },
          })),
        );
      }

      if (!_.isEmpty(reviewPrescriptions?.notes)) {
        setPrescriptionNotes(reviewPrescriptions?.notes!);
      }

      if (!_.isEmpty(reviewPrescriptions?.reasonForChange)) {
        setReasonForChange(reviewPrescriptions?.reasonForChange!);
      }
    }
  }, [props.patientReviewPrescriptions]);

  React.useEffect(() => {
    if (props.fluidDefaultValue) {
      setOriginalFluidIntake(props.fluidDefaultValue);
    }
  }, [props.fluidDefaultValue]);

  const [tabSwitchSaveObservations, setTabSwitchSaveObservation] = React.useState({
    complaints: '',
    examinations: '',
    diagnosis: '',

    notes: '',
    reasonForChange: '',

    procedures: '',
  });

  const handleTabSwitchAndSaveObservationToContext = (value: string, observationName: 'COMPLAINTS' | 'EXAMINATIONS' | 'DIAGNOSIS' | 'NOTES' | 'REASON_FOR_CHANGE' | 'PROCEDURES') => {
    if (props.tabValue === 'OBSERVATION_ASSESSMENTS') {
      setTabSwitchSaveObservation((prev) => {
        return {
          ...prev,
          complaints: observationName === 'COMPLAINTS' ? value : prev.complaints,
          examinations: observationName === 'EXAMINATIONS' ? value : prev.examinations,
          diagnosis: observationName === 'DIAGNOSIS' ? value : prev.diagnosis,
        };
      });
    } else if (props.tabValue === 'TREATMENT_ADVICE') {
      setTabSwitchSaveObservation((prev) => {
        return {
          ...prev,
          notes: observationName === 'NOTES' ? value : prev.notes,
          reasonForChange: observationName === 'REASON_FOR_CHANGE' ? value : prev.reasonForChange,
        };
      });
    } else if (props.tabValue === 'INVESTIGATIONS_NEXT_REVIEW') {
      setTabSwitchSaveObservation((prev) => {
        return {
          ...prev,
          procedures: observationName === 'PROCEDURES' ? value : prev.procedures,
        };
      });
    }
  };

  const saveToRespectiveState = () => {
    if (!_.isEmpty(tabSwitchSaveObservations.complaints)) {
      setComplaints((prev) => {
        return [...prev, { id: Math.random().toString(), mainText: tabSwitchSaveObservations.complaints, subText: undefined }];
      });

      setTabSwitchSaveObservation((prev) => {
        return {
          ...prev,
          complaints: '',
        };
      });
    }

    if (!_.isEmpty(tabSwitchSaveObservations.examinations)) {
      setExaminations((prev) => {
        return [...prev, { id: Math.random().toString(), mainText: tabSwitchSaveObservations.examinations, subText: undefined }];
      });

      setTabSwitchSaveObservation((prev) => {
        return {
          ...prev,
          examinations: '',
        };
      });
    }

    if (!_.isEmpty(tabSwitchSaveObservations.diagnosis)) {
      setDiagnosis((prev) => {
        return [...prev, { id: Math.random().toString(), mainText: tabSwitchSaveObservations.diagnosis, subText: undefined }];
      });

      setTabSwitchSaveObservation((prev) => {
        return {
          ...prev,
          diagnosis: '',
        };
      });
    }

    if (!_.isEmpty(tabSwitchSaveObservations.notes)) {
      setPrescriptionNotes((prev) => {
        return [...prev, { notes: tabSwitchSaveObservations.notes }];
      });

      setTabSwitchSaveObservation((prev) => {
        return {
          ...prev,
          notes: '',
        };
      });
    }

    if (!_.isEmpty(tabSwitchSaveObservations.reasonForChange)) {
      setReasonForChange((prev) => {
        return [...prev, { notes: tabSwitchSaveObservations.reasonForChange }];
      });

      setTabSwitchSaveObservation((prev) => {
        return {
          ...prev,
          reasonForChange: '',
        };
      });
    }

    if (!_.isEmpty(tabSwitchSaveObservations.procedures)) {
      setProcedures((prev) => {
        return [...prev, { id: Math.random().toString(), mainText: tabSwitchSaveObservations.procedures, subText: undefined }];
      });

      setTabSwitchSaveObservation((prev) => {
        return {
          ...prev,
          procedures: '',
        };
      });
    }
  };

  const handleChangeFluidIntake = (value: number, variable: keyof FluidIntakeType) => {
    setFluidIntake((prev) => ({ ...prev, [variable]: value }));
  };

  React.useEffect(() => {
    if (props.patientReviewPrescriptions?.status === 'SUBMITTED_APPROVAL') {
      console.log('entering inside', props.patientReviewPrescriptions);

      handleClickOnPreviewBtn();
    }
  }, [props.patientReviewPrescriptions]);

  React.useEffect(() => {
    if (props.tabValue) {
      saveToRespectiveState();

      setTabValue(props.tabValue);
    }
  }, [props.tabValue]);

  const providerValue: PrescriptionGeneratorContextProps = {
    containerHeight: props.containerHeight,

    tabValue,
    handleChangeTabs,

    complaints,
    handleChangeComplaints,
    complaintsErrors,

    vitals,
    vitalsErrors,
    handleChangeVitals,

    examinations,
    handleChangeExaminations,
    examinationsErrors,

    diagnosis,
    handleChangeDiagnosis,
    diagnosisErrors,

    labInvestigationTest,
    labInvestigationTestErrors,
    handleNewLabInvestigationTest,
    handleChangeLabInvestigationTest,
    handleRemoveLabInvestigationTest,

    imageInvestigationTest,
    imageInvestigationTestErrors,
    handleNewImageInvestigationTest,
    handleChangeImageInvestigationTest,
    handleRemoveImageInvestigationTest,

    procedures,
    proceduresErrors,
    handleNewProcedures,
    handleChangeProcedures,
    handleRemoveProcedures,

    prescriptionDate,
    prescriptionDateErrors,
    handleChangePrescriptionDate,

    nextReviewDate,
    nextReviewDateErrors,
    handleChangeNextReviewDate,

    patientAppointment,
    patientAppointmentErrors,
    handleChangePatientAppointments,

    patientPrescriptionMedications,
    patientPrescriptionMedicationsErrors,
    handleNewPatientPrescriptionsMedications,
    handleRemovePatientPrescriptionsMedications,
    handleChangePatientPrescriptionMedications,

    searchConfigMedicationWithBrandName,

    isOpenPreviewModal,
    patientPreviewPresciptions,
    prescriptionsPreviewReportURL,
    prescriptionsPreviewReportURLIsLoading: prescriptionsPreviewReportMutate.isPending,

    prescriptionNotes,
    prescriptionNotesErrors,
    handleNewPrescriptionsNotes,
    handleRemovePrescriptionNotes,
    handleChangeNotes,

    reasonForChange,
    handleChangeReasonForChange,

    handleClickOnPreviewBtn,

    isDraftPrescriptionUpdating: props.isDraftPrescriptionLoading,
    handleClickOnDraftBtn,

    togglePatientPreviewModal,

    isPatientPrescriptionUpdating: props.isPatientPrescriptionUpdating,
    handleSubmitPatientPrescription,

    isSubmitApprovalPrescriptionUpdating: props.isSubmitApprovalPrescriptionUpdating,
    handleSubmitApprovalPrescriptions,

    handleClickRejectPrescriptions,

    patientReviewPrescriptions: props.patientReviewPrescriptions,

    patientAlreadyPrescribedMedications: props.patientAlreadyPrescribedMedications,

    patientInfo: props.patientInfo,

    isOpenReviewDateWarningModal,
    handleClickOnSubmitReviewDateWarning,
    toggleReviewDateWarningModal,

    tabSwitchSaveObservations,
    handleTabSwitchAndSaveObservationToContext,

    fluidIntake,
    fluidIntakeError,
    originalFluidIntake,
    handleChangeFluidIntake,
  };

  return (
    <PrescriptionGeneratorContext.Provider value={providerValue}>
      <PrescriptionsGenerator />
    </PrescriptionGeneratorContext.Provider>
  );
};

export default PrescriptionGeneratorProvider;
