/** @format */

import { Button, List, ListItem, Typography } from '@mui/material';
import { PatientTimeLineType } from '../../../types/Timeline.types';
import { DateTime } from 'luxon';
import * as React from 'react';
import PDFViewer from '../../common/PDFViewer';
import { fetchPatientAttachmentsPresignedUrl } from '../../../service/patient-attachments';
import { SelectedPdfUrlType } from '../../../pages/patient-profile/PatientProfileContext';

interface TimelineLabReportItemProps {
  data: PatientTimeLineType;
  lastTimelineItem: boolean;

  organiationUsers: Array<{ label: string; value: string }>;
}

const TimelinePrescriptionItem: React.FunctionComponent<TimelineLabReportItemProps> = (props: any) => {
  const [isOpenPrescriptionModal, setIsOpenPrescriptionModal] = React.useState<boolean>(false);
  const [pdfUrl, setpdfUrl] = React.useState<SelectedPdfUrlType | null>(null);

  const handleOpenpdf = async (items: any) => {
    try {
      const res = await fetchPatientAttachmentsPresignedUrl(props.data?.patientSourceId, items.fileId);
      setpdfUrl(res);
    } catch (err) {
      console.error('errr', err);
    }
    setIsOpenPrescriptionModal((prev) => !prev);
  };

  const handleClosepdf = () => {
    setIsOpenPrescriptionModal((prev) => !prev);
  };

  return (
    <>
      {isOpenPrescriptionModal && <PDFViewer isOpen={isOpenPrescriptionModal} url={pdfUrl} handleClose={handleClosepdf} />}
      <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
        <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
          <Typography variant='fontSemiBold12' color='#3E4685'>
            Prescription
          </Typography>
        </ListItem>
        <ListItem>
          <Typography variant='fontSemiBold12' color='#3E4685' sx={{ mr: 1.5 }}>
            Name
          </Typography>
          <Typography variant='fontReg12' color='#3E4685'>
            {props.data.payload?.patientLabReports?.name}
          </Typography>
        </ListItem>
        {props.data.payload?.patientLabReports?.summary && (
          <ListItem>
            <Typography variant='fontSemiBold12' color='#3E4685' sx={{ mr: 1.5 }}>
              Notes
            </Typography>

            <Typography
              variant='fontReg12'
              color='#3E4685'
              sx={{
                wordBreak: 'break-word', // Ensures words break if they are too long for the container
                overflowWrap: 'break-word', // Adds compatibility for older browsers
                whiteSpace: 'pre-wrap', // Preserves spacing and allows wrapping
                maxWidth: '300px', // Set a maximum width for the text container
              }}>
              {props.data.payload.patientLabReports?.summary}
            </Typography>
          </ListItem>
        )}
        <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
          <Typography variant='fontSemiBold12' color='#3E4685' sx={{ mr: 1.5 }}>
            Date
          </Typography>

          <Typography variant='fontReg12' color='#3E4685'>
            {DateTime.fromISO(props.data.timestamp).toFormat('MMMM dd, yyyy')}
          </Typography>
        </ListItem>
      </List>

      {props.data.medicalEventPayload?.patientLabReports?.attachments?.map((items: any) => {
        return (
          <Button variant='text' sx={{ my: 1 }}>
            <Typography component='div' variant='fontSemiBold14' color='#3E4685' sx={{ textDecoration: 'underline' }} onClick={() => handleOpenpdf(items)}>
              {items?.fileName}
            </Typography>
          </Button>
        );
      })}
    </>
  );
};

export default TimelinePrescriptionItem;
