/** @format */

export const BLOOD_PRESSURE = 'BLOOD_PRESSURE';
export const HEIGHT = 'HEIGHT';
export const SYSTOLIC = 'SYSTOLIC';
export const DIASTOLIC = 'DIASTOLIC';
export const MANUAL_ENTRY = 'MANUAL_ENTRY';
export const HEART_RATE = 'HEART_RATE';
export const EGFR = 'EGFR';
export const POTASSIUM = 'POTASSIUM';
export const SCR = 'SCR';
export const WEIGHT = 'WEIGHT';

export const VITALS_SOURCE_TYPE_EXCEPT_APPLE_WATCH = 'EXCEPT_APPLE_WATCH';
export const VITALS_SOURCE_TYPE_APPLE_WATCH = 'APPLE_WATCH';
export const VITALS_SOURCE_TYPE_ALL = 'ALL';

export const VITALS_SOURCE_TYPE_MANUAL_ENTRY = 'MANUAL';

export const VITALS_BODY_TEMPERATURE = {
  label: 'Body Temperature',
  value: 'BODY_TEMPERATURE',
  unit: 'oF',
};

export const VITALS_RESPIRATION_RATE = {
  label: 'Respiration Rate',
  value: 'RESPIRATION_RATE',
  unit: 'Breaths per minute',
};

export const VITALS_HEART_RATE = {
  label: 'HR',
  value: 'HEART_RATE',
  unit: 'bpm',
};

export const VITALS_WEIGHT = {
  label: 'Weight',
  value: 'WEIGHT',
  unit: 'lbs',
};

export const VITALS_GLUCOSE = {
  label: 'Glucose',
  value: 'GLUCOSE',
  unit: 'mg/dL',
};

export const VITALS_CALCIUM = {
  label: 'Calcium',
  value: 'CALCIUM',
  unit: 'mg/dL',
};

export const VITALS_BLOOD_UREA_NITROGEN = {
  label: 'Blood Urea Nitrogen',
  value: 'BLOOD_UREA_NITROGEN',
  unit: 'mg/dL',
};

export const VITALS_SODIUM = {
  label: 'Sodium',
  value: 'SODIUM',
  unit: 'mEq/L',
};

export const VITALS_BICARBONATE = {
  label: 'Bicarbonate',
  value: 'BICARBONATE',
  unit: 'mEq/L',
};

export const VITALS_CHLORIDE = {
  label: 'Chloride',
  value: 'CHLORIDE',
  unit: 'mEq/L',
};

export const VITALS_POTASSIUM = {
  label: 'Potassium',
  value: 'POTASSIUM',
  unit: 'mEq/L',
};

export const VITALS_SCR = {
  label: 'sCr',
  value: 'SCR',
  unit: 'mg/dL',
};

export const VITALS_EGFR = {
  label: 'eGFR',
  value: 'EGFR',
  unit: 'mL/min/1.73 m2',
};

export const VITALS_NTPROBNP = {
  label: 'NTproBNP',
  value: 'NTPROBNP',
  unit: 'pg/mL',
};

export const VITALS_BLOOD_PRESSURE = {
  label: 'BP',
  value: 'BLOOD_PRESSURE',
  unit: 'mmHg',
};

export const VITALS_HEIGHT = {
  label: 'Height',
  value: 'HEIGHT',
  unit: 'feet inches',
};

export const VITALS_BNP = {
  label: 'BNP',
  value: 'BNP',
  unit: 'pg/mL',
};

export const VITALS_BMI = {
  label: 'BMI',
  value: 'BMI',
  unit: 'kg/m2',
};

export const VITALS_HBA1C = {
  label: 'HbA1c',
  value: 'HBA1c',
  unit: 'mmol/mol',
};

export const VITALS_HEMOGLOBIN = {
  label: 'Hemoglobin',
  value: 'HEMOGLOBIN',
  unit: 'mg/dL',
};

export const VITALS_HEMATOCRIT = {
  label: 'Hematocrit',
  value: 'HEMATOCRIT',
  unit: '%',
};

export const VITALS_SP02 = {
  label: 'Sp02',
  value: 'SP02',
  unit: '%',
};

export const VITALS_MAP: { [m: string]: string } = {
  BLOOD_UREA_NITROGEN: 'bloodUreaNitrogen',
  CALCIUM: 'calcium',
  CHLORIDE: 'chloride',
  EGFR: 'egfr',
  GLUCOSE: 'glucose',
  HEART_RATE: 'hr',
  NTPROBNP: 'ntprobnp',
  POTASSIUM: 'potassium',
  SCR: 'scr',
  SODIUM: 'sodium',
  WEIGHT: 'weight',
  BICARBONATE: 'bicarbonate',
  BNP: 'bnp',
  HEMOGLOBIN: 'hemoglobin',
  HEMATOCRIT: 'hematocrit',
  BMI: 'bmi',
  HBA1C: 'hba1c',
  HBA1c: 'hba1c',
};

export const VITALS_MAP_LABEL: { [m: string]: string } = {
  BLOOD_UREA_NITROGEN: 'Blood Urea Nitrogen',
  CALCIUM: 'Calcium',
  CHLORIDE: 'Chloride',
  EGFR: 'eGFR',
  GLUCOSE: 'Glucose',
  HEART_RATE: 'HR',
  NTPROBNP: 'NT-proBNP',
  POTASSIUM: 'K',
  SCR: 'sCr',
  SODIUM: 'Sodium',
  WEIGHT: 'Weight',
  BICARBONATE: 'Bi-Carbonate',
  HEMATOCRIT: 'Hematocrit',
  HEMOGLOBIN: 'Hemoglobin',
};

export const USER_VITALS_UNIT_MAPPING = {
  BMI: 'bmi',
  SCR: 'sCr',
  EGFR: 'eGFR',
  hba1c: 'hba1c',
  HEIGHT: 'height',
  SODIUM: 'sodium',
  WEIGHT: 'weight',
  CALCIUM: 'calcium',
  GLUCOSE: 'glucose',
  CHLORIDE: 'chloride',
  NTPROBNP: 'ntprobnp',
  HEART_RATE: 'heartRate',
  POTASSIUM: 'potassium',
  HEMATOCRIT: 'hematocrit',
  HEMOGLOBIN: 'hemoglobin',
  BICARBONATE: 'bicarbonate',
  BLOOD_PRESSURE: 'bloodPressure',
  BLOOD_UREA_NITROGEN: 'bloodUreaNitrogen',
};

export const TRAFFIC_LIGHTS_VITALS_MAPPING = {
  VITALS_BODY_TEMPERATURE,
  VITALS_RESPIRATION_RATE,
  VITALS_HEART_RATE,
  VITALS_WEIGHT,
  VITALS_GLUCOSE,
  VITALS_CALCIUM,
  VITALS_BLOOD_UREA_NITROGEN,
  VITALS_SODIUM,
  VITALS_BICARBONATE,
  VITALS_CHLORIDE,
  VITALS_POTASSIUM,
  VITALS_SCR,
  VITALS_EGFR,
  VITALS_NTPROBNP,
  VITALS_BLOOD_PRESSURE,
  VITALS_HEIGHT,
  VITALS_BNP,
  VITALS_BMI,
  VITALS_HBA1C,
  VITALS_HEMOGLOBIN,
  VITALS_HEMATOCRIT,
};
