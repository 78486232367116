/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import PDFViewer from '../../common/PDFViewer';

import { PatientTimeLineType } from '../../../types/Timeline.types';

import { SelectedPdfUrlType } from '../../../pages/patient-profile/PatientProfileContext';
import { V2PatientAttachmentReportsPayloadReportType } from '../../../types/PatientAttachments.types';

import { fetchPatientAttachmentsPresignedUrl } from '../../../service/patient-attachments';

import {
  VITALS_BICARBONATE,
  VITALS_BLOOD_UREA_NITROGEN,
  VITALS_CALCIUM,
  VITALS_CHLORIDE,
  VITALS_EGFR,
  VITALS_GLUCOSE,
  VITALS_MAP_LABEL,
  VITALS_NTPROBNP,
  VITALS_POTASSIUM,
  VITALS_SCR,
  VITALS_SODIUM,
} from '../../../constants/PatientVitals';

interface TimelineLabReportItemProps {
  data: PatientTimeLineType;
  lastTimelineItem: boolean;

  organiationUsers: Array<{ label: string; value: string }>;
}

const TimelineLabReportItem: React.FunctionComponent<TimelineLabReportItemProps> = (props) => {
  const [isOpenPrescriptionModal, setIsOpenPrescriptionModal] = React.useState<boolean>(false);
  const [pdfUrl, setpdfUrl] = React.useState<SelectedPdfUrlType | null>(null);

  const handleOpenpdf = async (items: any) => {
    try {
      const res = await fetchPatientAttachmentsPresignedUrl(props.data?.patientSourceId, items.fileId);
      setpdfUrl({ ...items, pdfUrl: res, fileType: items.type });
    } catch (err) {
      console.error('errr', err);
    }
    setIsOpenPrescriptionModal((prev) => !prev);
  };

  const handleClosepdf = () => {
    setIsOpenPrescriptionModal((prev) => !prev);
  };

  const labReprotTypes = [
    VITALS_GLUCOSE.value,
    VITALS_CALCIUM.value,
    VITALS_BLOOD_UREA_NITROGEN.value,
    VITALS_SODIUM.value,
    VITALS_BICARBONATE.value,
    VITALS_CHLORIDE.value,
    VITALS_POTASSIUM.value,
    VITALS_SCR.value,
    VITALS_EGFR.value,
    VITALS_NTPROBNP.value,
  ];

  if (!_.isEmpty(props?.data?.payload?.patientLabReports)) {
    return (
      <>
        {isOpenPrescriptionModal && <PDFViewer isOpen={isOpenPrescriptionModal} url={pdfUrl} handleClose={handleClosepdf} />}

        <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
          <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
            <Typography variant='fontSemiBold12' color='#3E4685'>
              Results
            </Typography>
          </ListItem>
          {props?.data?.payload?.patientLabReports?.reports?.map((item: V2PatientAttachmentReportsPayloadReportType, index: number) => {
            return (
              <ListItem key={index} sx={{ borderBottom: index !== props?.data?.payload?.patientLabReports?.reports?.length! - 1 ? '1px solid #E2E3E5' : '', p: 2 }}>
                <Grid container justifyContent='center'>
                  <Grid item xs={12} sx={{ mb: 1 }}>
                    <Typography component='div' variant='fontSemiBold14' color='#3E4685'>
                      {item.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={11} container>
                    {item.tests?.map((item) => {
                      return (
                        <Grid item container xs={12} gap={2} py={1} sx={{ borderBottom: '1px solid #D5D5D6' }} alignItems='center'>
                          <Grid item xs={7.2}>
                            <Typography variant='fontSemiBold12' color='#3E4685'>
                              {item.name}
                            </Typography>
                          </Grid>
                          <Grid item xs={4.2}>
                            <Typography variant='fontBold16' color='#3E4685'>
                              {item.value}
                            </Typography>
                            &nbsp;
                            <Typography fontSize='11px' variant='fontReg10' color='#3E4685'>
                              {item.units}
                            </Typography>
                          </Grid>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}
        </List>

        {props.data?.payload?.patientLabReports?.attachments?.length > 0 && (
          <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
            <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
              <Typography variant='fontSemiBold12' color='#3E4685'>
                Files
              </Typography>
            </ListItem>
            {props.data?.payload?.patientLabReports?.attachments?.map((items: any) => {
              return (
                <ListItem sx={{ borderBottom: '1px solid #D5D5D6', cursor: 'pointer' }}>
                  <Typography variant='fontSemiBold12' color='#3E4685' onClick={() => handleOpenpdf(items)} sx={{ textDecoration: 'underline' }}>
                    {items?.fileName}
                  </Typography>
                </ListItem>
              );
            })}
          </List>
        )}
      </>
    );
  }

  return (
    <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
      <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
        <Typography variant='fontSemiBold12' color='#3E4685'>
          Results
        </Typography>
      </ListItem>
      {props?.data?.payload?.vitals?.map((item, index) => {
        if (labReprotTypes.includes(item.type)) {
          return (
            <ListItem key={index} sx={{ borderBottom: index !== props?.data?.payload?.vitals.length - 1 ? '1px solid #D5D5D6' : '', p: 2 }}>
              <Typography variant='fontSemiBold14' color='#3E4685'>
                {VITALS_MAP_LABEL[item.type]} {'   '}({item.measurements?.[0].unit})
              </Typography>
              <Typography sx={{ ml: 'auto' }} variant='fontSemiBold16' color='#3E4685'>
                {item.measurements?.[0].value}
              </Typography>
            </ListItem>
          );
        }
        return null;
      })}
    </List>
  );
};

export default TimelineLabReportItem;
