/** @format */

import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../../app/store';

export interface ImageState {
  imageUrl: string;
  userName: string;
}

export const initialState: ImageState = {
  imageUrl: '',
  userName: '',
};

export const userImageReducer = createSlice({
  name: 'image reducer',
  initialState,
  reducers: {
    setImageUrl: (state, { payload }: PayloadAction<string>) => {
      state.imageUrl = payload;
    },

    setUserName: (state, { payload }: PayloadAction<string>) => {
      state.userName = payload;
    },

    clearImageStore: (state) => {
      state = {
        imageUrl: '',
        userName: '',
      };

      return state;
    },
  },
});

export const { setImageUrl, clearImageStore, setUserName } = userImageReducer.actions;

export const imageSelector: any = (state: RootState) => state.userImage;
