/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';

import Grid from '@mui/material/Grid';
import Timeline from '@mui/lab/Timeline';
import Collapse from '@mui/material/Collapse';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import SummarizeOutlinedIcon from '@mui/icons-material/SummarizeOutlined';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import BiotechIcon from '@mui/icons-material/Biotech';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

import FormFields from '../../common/form-fields/FormFields';

import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';

import { PatientTimeLineType } from '../../../types/Timeline.types';

import { addDatePrefixOriginal } from '../../../utils/date-utils';

import MedicationChangeTimeline from '../MedicationChangeTimeline';
import TimelineLabReportItem from './TimelineLabReportItem';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { Box, Button } from '@mui/material';
import TimelineHospitalisationItem from './TimelineHospitalisationItem';
import HotelOutlinedIcon from '@mui/icons-material/HotelOutlined';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'underscore';
import TimelinePrescriptionItem from './TimelinePrescriptionItem';
import TimelineOtherItem from './TimelineOtherItem';

interface TimelinesProps {}

interface MedicationChangeTimelineItemProps {
  data: PatientTimeLineType;
  lastTimelineItem: boolean;

  organiationUsers: Array<{ label: string; value: string }>;
}

const MedicationChangeTimelineItem: React.FunctionComponent<MedicationChangeTimelineItemProps> = (props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  const timestamp: DateTime = DateTime.fromJSDate(new Date(props.data?.timestamp));

  let icon = <ReceiptLongIcon htmlColor='#3E4685' />;

  if (props.data?.type === 'LAB_REPORTS') {
    icon = <BiotechIcon htmlColor='#3E4685' />;
  }

  if (props.data?.type === 'HOSPITALISATION') {
    icon = <HotelOutlinedIcon htmlColor='#3E4685' />;
  }

  if (props.data?.type === 'OTHERS') {
    icon = <SummarizeOutlinedIcon htmlColor='#3E4685' />;
  }

  return (
    <Box>
      <TimelineItem>
        <TimelineSeparator>
          <TimelineDot sx={{ background: 'transparent', boxShadow: 'none' }}>{icon}</TimelineDot>
          {!props.lastTimelineItem && <TimelineConnector sx={{ background: 'transparent', borderLeft: '2px dashed #D5D5D6' }} />}
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
          <Grid container alignItems='center' justifyContent='space-between' sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', pl: 1 }}>
            <Typography component='div' variant='fontSemiBold14' color='#3E4685'>
              {(props.data?.type === 'PREVIOUS_MEDICATIONS' && 'Medication Before Enrollment') || addDatePrefixOriginal(timestamp.day) + ' ' + timestamp.toFormat(' MMM yyyy')}
            </Typography>
            <IconButton onClick={() => setIsOpen((prev) => !prev)} sx={{ ml: 'auto' }}>
              {!isOpen && <AddIcon />}
              {isOpen && <RemoveIcon />}
            </IconButton>
          </Grid>
          <Collapse in={isOpen}>
            {props.data?.type === 'LAB_REPORTS' && <TimelineLabReportItem {...props} />}
            {props.data?.type === 'HOSPITALISATION' && <TimelineHospitalisationItem {...props} />}
            {props.data?.type === 'PRESCRIPTIONS' && <TimelinePrescriptionItem {...props} />}
            {props.data?.type === 'OTHERS' && <TimelineOtherItem {...props} />}

            {(props.data?.type === 'MEDICATION_CHANGE' || props.data?.type === 'PREVIOUS_MEDICATIONS') && (
              <MedicationChangeTimeline patientSourceId={props.data?.patientSourceId} prescriptions={props.data?.payload?.prescription} organiationUsers={props.organiationUsers} />
            )}
          </Collapse>
        </TimelineContent>
      </TimelineItem>
    </Box>
  );
};

const Timelines: React.FunctionComponent<TimelinesProps> = () => {
  const { patientInfo, patientVitalsHistoryMedicalEvent, noteUsersOptions } = React.useContext(PatientProfileContext);
  const navigate = useNavigate();
  const { patientSourceId }: any = useParams();
  // const hospitalisationDetails: any = [];

  const handleNavigateVitalHistory = () => {
    navigate(`/patients/${patientSourceId}`, { state: { redirectToVitalHistory: true } });
  };

  const [medicalEventsFilters, setMedicalEventsFilters] = React.useState({ type: 'ALL' });

  const medicalEventsTimelineData = React.useMemo(() => {
    const allEvents = [...patientVitalsHistoryMedicalEvent];

    if (patientInfo.enrolmentDetails?.previousMedications && !_.isEmpty(patientInfo.enrolmentDetails?.previousMedications?.payload?.prescription?.prescriptionMedications)) {
      allEvents.push(patientInfo.enrolmentDetails?.previousMedications);
    }
    if (patientInfo.enrolmentDetails?.hospitalization && !_.isEmpty(patientInfo.enrolmentDetails?.hospitalization?.payload)) {
      const hospitalizationEvent = patientInfo.enrolmentDetails.hospitalization.payload;

      const patientHospitalizationReport: any = {
        patientSourceId,
        type: 'HOSPITALISATION',
        timestamp: patientInfo.basicDetails!.createdAt,
        medicalEventPayload: {
          patientLabReports: {
            payload: {
              ...hospitalizationEvent,
            },
            summary: patientInfo.enrolmentDetails.hospitalization.summary,
            attachments: patientInfo.enrolmentDetails.hospitalization.attachments,
          },
        },
      };

      allEvents.push(patientHospitalizationReport);
    }

    const formattedTimeline = allEvents.sort((a, b) => new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime());
    const medicalEventTimeline = formattedTimeline.filter((item) => {
      if (medicalEventsFilters.type !== 'ALL') {
        return item.type === medicalEventsFilters.type;
      }
      // Otherwise, filter based on the selected types
      return ['MEDICATION_CHANGE', 'LAB_REPORTS', 'PREVIOUS_MEDICATIONS', 'HOSPITALISATION', 'PRESCRIPTIONS', 'OTHERS'];
    }, []);

    return medicalEventTimeline.sort((a, b) => new Date(b.timestamp!).getTime() - new Date(a.timestamp!).getTime());
  }, [patientVitalsHistoryMedicalEvent, medicalEventsFilters.type]);

  return (
    <Grid container>
      <Grid item xs={12} container>
        <Grid item xs={1} pt={'5px'}>
          <Typography variant='fontReg14' color='#5C6A90'>
            View
          </Typography>
        </Grid>
        <Grid item xs={10} justifyContent='space-between' display={'flex'}>
          <FormFields
            label=''
            id='filter-type'
            componentType='TEXT_SELECT_COMPONENT'
            height='33px'
            width='138px'
            MenuItemStyle={{
              border: '1px solid #9BB4CC',
              // paddingBottom: 0;
            }}
            MenuListStyle={{
              padding: 0,
            }}
            style={{
              borderColor: '#9BB4CC',
              '&:hover': {
                borderColor: '#9BB4CC',
              },
              '&.Mui-focused': {
                borderColor: '#9BB4CC',
              },
            }}
            input={{
              options: [
                { label: 'All', value: 'ALL' },
                { label: 'Prescriptions', value: 'MEDICATION_CHANGE' },
                { label: 'Lab Results', value: 'LAB_REPORTS' },
                {
                  label: 'Hospitalisation',
                  value: 'HOSPITALISATION',
                },
                {
                  label: 'Others',
                  value: 'OTHERS',
                },
              ],
              value: medicalEventsFilters.type,

              onChange: (event) => setMedicalEventsFilters((prev) => ({ ...prev, type: event.target.value })),
            }}
          />
          <Grid />
          <Grid item>
            <Button variant='outlined' sx={{ pr: 1, pl: 1, display: 'flex', gap: '8px', alignItems: 'end' }} onClick={handleNavigateVitalHistory}>
              <LeaderboardIcon fontSize='small' sx={{ color: '#3E4685' }} />
              <Typography variant='fontSemiBold14' color='#3E4685' sx={{ textWrap: 'nowrap' }}>
                Vital History
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} container>
        {medicalEventsTimelineData?.length <= 0 && (
          <Typography component='div' variant='fontSemiBold16' color='#3E4685' textAlign='center' sx={{ width: '100%', mt: 2 }}>
            No Timeline information to display
          </Typography>
        )}
      </Grid>
      <Grid item xs={12} container>
        {medicalEventsTimelineData?.length > 0 && (
          <Timeline
            sx={{
              p: 0,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}>
            {medicalEventsTimelineData.map((item, index) => {
              if (['MEDICATION_CHANGE', 'LAB_REPORTS', 'PREVIOUS_MEDICATIONS', 'HOSPITALISATION', 'PRESCRIPTIONS', 'OTHERS'].includes(item.type)) {
                return <MedicationChangeTimelineItem data={item as any} organiationUsers={noteUsersOptions} lastTimelineItem={index === medicalEventsTimelineData.length - 1} />;
              }
              return null;
            })}
          </Timeline>
        )}
      </Grid>
    </Grid>
  );
};

export default Timelines;
