/** @format */

import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Slider, { SliderProps } from '@mui/material/Slider';
import InputBase from '@mui/material/InputBase';
import ButtonGroup from '@mui/material/ButtonGroup';
import ClickAwayListener from '@mui/material/ClickAwayListener';

import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

import { PatientRuleVitalsOptionsMarksType } from '../../types/PatientRule.types';
import { Box } from '@mui/material';

interface CustomSliderProps {
  minRange: number;
  maxRange: number;

  tooltipEditable?: boolean;

  marks?: PatientRuleVitalsOptionsMarksType;

  step?: number | null;

  defaultRange?: [number, number];

  value: number | number[];
  handleChangeValue: (value: number | number[]) => void;

  disabled?: boolean;
}

const CustomSlider: React.FunctionComponent<CustomSliderProps> = (props) => {
  const appTheme = useTheme();

  const [value, setValue] = React.useState<number | number[]>(props.value);

  const [isTooltipEditable, setIsToolTipEditable] = React.useState<{ [key: string]: boolean }>({});
  const [isTooltipInputable, setIsTooltipInputable] = React.useState<{ [key: string]: boolean }>({});

  const disableSliderThumbRef = React.useRef<boolean>(false);
  const isValueEditableTimeoutRef = React.useRef<any>(null);
  const isParenetTimerRef = React.useRef<any>(null);

  React.useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const updateParentState = (value: number | number[]) => {
    if (isParenetTimerRef.current) {
      clearTimeout(isParenetTimerRef.current);
    }

    isParenetTimerRef.current = setTimeout(() => {
      props.handleChangeValue(value);
    }, 500);
  };

  const toggleEditData = (key: string | number) => {
    setIsToolTipEditable((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const handleChangeRangeValue = (event: Event, value: number | number[]) => {
    if (props.disabled) {
      return;
    }
    if (!disableSliderThumbRef.current) {
      setValue(value);
      updateParentState(value);
    }
  };

  const handleClickTooltipInputEdit = (key: string | number) => {
    setIsTooltipInputable((prev) => ({ ...prev, [key]: prev[key] ? !prev[key] : true }));
  };

  const handleChangeInput = (newValue: number, index: any, param: any = 0) => {
    let propsValue = value;
    if (Array.isArray(propsValue)) {
      propsValue[index] = Number(newValue) + param;
    } else {
      propsValue = Number(newValue) + param;
    }
    setValue(propsValue);
    updateParentState(propsValue);
  };

  React.useEffect(() => {
    Object.keys(isTooltipEditable).forEach((key) => {
      if (isTooltipEditable[key]) {
        if (isValueEditableTimeoutRef.current) {
          clearTimeout(isValueEditableTimeoutRef.current);
        }
        isValueEditableTimeoutRef.current = setTimeout(() => {
          toggleEditData(key);
        }, 5000);
      } else {
        if (isValueEditableTimeoutRef.current) {
          clearTimeout(isValueEditableTimeoutRef.current);
        }
      }
    });
  }, [isTooltipEditable, value, props.tooltipEditable]);

  const _renderSliderToolTop = (value: number, index: number) => {
    const handleClose = () => {
      if (props.tooltipEditable) {
        toggleEditData(index);
      }
    };

    if (!isTooltipEditable[index]) {
      return (
        <Box onClick={handleClose} sx={{ width: 'min-content', backgroundColor: '#f5f9fe', padding: '2px 4px', color: 'GrayText' }}>
          {props.step ? value.toFixed(2) : value}
        </Box>
      );
    }

    let inputComponent = (
      <InputBase readOnly type='number' onDoubleClick={() => handleClickTooltipInputEdit(index)} sx={{ width: '80px', background: 'inherit' }} value={props.step ? value.toFixed(2) : value} />
    );

    if (isTooltipInputable[index]) {
      inputComponent = (
        <ClickAwayListener onClickAway={() => handleClickTooltipInputEdit(index)}>
          <InputBase
            autoFocus
            type='number'
            sx={{ width: '80px', background: 'inherit' }}
            defaultValue={value.toFixed(2)}
            onChange={(event) => handleChangeInput(Number(event.target?.value), index, 0)}
          />
        </ClickAwayListener>
      );
    }

    return (
      <ClickAwayListener onClickAway={handleClose}>
        <ButtonGroup onMouseEnter={() => (disableSliderThumbRef.current = true)} onMouseLeave={() => (disableSliderThumbRef.current = false)} variant='text' color='primary'>
          <Button
            onClick={() => handleChangeInput(value, index, -props.step! || -1)}
            sx={{
              border: 'none !important',
            }}>
            <RemoveIcon />
          </Button>
          {inputComponent}
          <Button onClick={() => handleChangeInput(value, index, props.step || 1)}>
            <AddIcon />
          </Button>
        </ButtonGroup>
      </ClickAwayListener>
    );
  };

  const sliderProps: SliderProps = {
    id: 'tract',
    valueLabelDisplay: 'on',
    value,
    onChange: handleChangeRangeValue,
    valueLabelFormat: _renderSliderToolTop,
    sx: {
      // backgroundColor: 'red',
      cursor: props.disabled ? 'not-allowed' : 'pointer',
      color: props.disabled ? 'GrayText' : 'primary',
      marginTop: 5,
      '& .MuiSlider-valueLabel': {
        background: appTheme.palette.customColor.menuBar,
        p: 0,
      },
    },
    min: props.minRange,
    max: props.maxRange,
    marks: props.marks,
    step: props.step,
  };

  if (Array.isArray(value)) {
    return <Slider {...sliderProps} />;
  }
  return <Slider {...sliderProps} />;
};

CustomSlider.defaultProps = {
  tooltipEditable: true,
};

export default CustomSlider;
