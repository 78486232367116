/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';
import Grid, { RegularBreakpoints } from '@mui/material/Grid';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';

import CircleIcon from '@mui/icons-material/Circle';
import HikingIcon from '@mui/icons-material/Hiking';
import EcgWaveIcon from '../../../assets/EcgWaveIcon';
import EditNoteIcon from '@mui/icons-material/EditNote';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import CalendarMonthSharpIcon from '@mui/icons-material/CalendarMonthSharp';
import KeyboardArrowDownSharpIcon from '@mui/icons-material/KeyboardArrowDownSharp';
import MedicalServicesOutlinedIcon from '@mui/icons-material/MedicalServicesOutlined';

import EditLastEDVisit from './EditLastEDVisit';
import CustomPopper from '../../common/custom-popper';
import EditLastConsultation from './EditLastConsulation';
import EditLastHospitalization from './EditLastHospitalization';
import EditOtherMedicalHistory from './EditOtherMedicalHistory';
import EditMedicalHistoryDialog from './EditMedicalHistoryModal';
import ViewPatientVitalReported from './ViewPatientVitalReported';
import CustomTooltip from '../../common/custom-tooltip/CustomTooltip';
import ViewPatientReportedSymptoms from './ViewPatientReportedSymptom';
import ViewPatientVitalWeightReported from './ViewPatientVitalsWeightReported';
import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';

import { PatientTasksItemType } from '../../../types/PatientTasks.type';

import { getDateFormat, getRelativeDate } from '../../../utils/date-utils';
import { PatientMedicalHistoryPayloadType } from '../../../types/PatientMedicalHistory.types';

import {
  CARDIOVASULCAR_DISEASES,
  CARDIOVASULCAR_DISEASES_KEY_MAP,
  HF_TYPES_OPTIONS,
  NON_CARDIOVASCULAR_DISEASES,
  NON_CARDIOVASULCAR_DISEASES_KEY_MAP,
  PATIENT_ACTIVE_STATUS,
} from '../../../constants/PatientEnrollment';
import { TASKS_TYPE_MEDICATION_CRITICAL_ALERTS, TASKS_TYPE_MEDICATION_WARNING_ALERTS } from '../../../constants/workspace-tasks';
import ConfirmationDialogWithNotesComponent from '../../common/confirmation-dialog/ConfirmationDialogWithNotes';

interface BasicMedicalHistoryFieldsType {
  id: string;
  label?: string | JSX.Element;
  noLabel?: boolean;
  value: string | JSX.Element;
  grid: RegularBreakpoints;

  showHistoryToolTip?: boolean;
  historyToolTipTitle?: string;
  historyToolTipValues?: Array<{ date: string; value: string }>;
}

interface SummaryProps {}

const Summary: React.FunctionComponent<SummaryProps> = () => {
  const appTheme = useTheme();

  const {
    patientInfo,
    isPatientMedicalHistoryUpdatingApiCalling,
    handleSubmitPatientMedicalHistory,
    patientMedicationTask,

    patientLatestFluidIntake,

    patientRuleThreshold,
    isUpdatingPatientRuleThreshold,
    handleSubmitUpdatePatientRuleThreshold,
  } = React.useContext(PatientProfileContext);

  const [customHistoryToolTipData, setCustomHistoryToolTipData] = React.useState<{ anchorEl: HTMLButtonElement; title: string; data: Array<{ date: string; value: string }> } | null>(null);

  const [isOpenLastConsultationModal, setIsOpenLastConsultationModal] = React.useState<boolean>(false);
  const [isOpenLastHospitalizationModal, setIsOpenLastHospitalizationModal] = React.useState<boolean>(false);
  const [isOpenLastEDVisitModal, setIsOpenLastEDVisitModal] = React.useState<boolean>(false);
  const [isOpenEditMedicalHistoryModal, setIsOpenEditMedicalHistoryModal] = React.useState<boolean>(false);
  const [isOpenAlertsTooltip, setIsOpenAlertsTooltip] = React.useState(false);
  const [alerts, setAlerts] = React.useState<PatientTasksItemType<any, any>[]>([]);

  const [isOpenFluidIntake, setIsOpenFluidIntake] = React.useState(false);

  const getFluidIntakeValue = () => {
    const fluidIntakeRuleThrehold = patientRuleThreshold?.find((item) => item.vitalsType === 'fluidIntake');
    return {
      criticalHigh: fluidIntakeRuleThrehold?.criticalHigh || 2,
      warningHigh: fluidIntakeRuleThrehold?.warningHigh || 1.5,
    };
  };

  React.useEffect(() => {
    if (patientMedicationTask && patientMedicationTask.length > 0) {
      setAlerts(patientMedicationTask.filter((e) => [TASKS_TYPE_MEDICATION_CRITICAL_ALERTS, TASKS_TYPE_MEDICATION_WARNING_ALERTS].includes(e.type)).sort((a, b) => b.severity! - a.severity!));
    }
  }, [patientMedicationTask]);

  const handleCloseAlertsTooltip = () => {
    setIsOpenAlertsTooltip(false);
  };

  const handleOpenAlertsTooltip = () => {
    if (alerts.length === 0) {
      return;
    }
    setIsOpenAlertsTooltip(true);
  };

  const [isOpenEditOtherHistoryLifeStyleModal, setIsOpenEditOtherHistoryLifeStyleModal] = React.useState<boolean>(false);

  const toggleHistoryToolTip = (event?: any) => {
    setCustomHistoryToolTipData(event);
  };

  const toggleEditMedicalHistoryModal = () => {
    setIsOpenEditMedicalHistoryModal((prev) => !prev);
  };

  const toggleLastConsultationModal = () => {
    setIsOpenLastConsultationModal((prev) => !prev);
  };

  const toggleLastHospitalizationModal = () => {
    setIsOpenLastHospitalizationModal((prev) => !prev);
  };

  const toggleLastEDVisitModal = () => {
    setIsOpenLastEDVisitModal((prev) => !prev);
  };

  const toggleEditOtherHistoryLifeStyleModal = () => {
    setIsOpenEditOtherHistoryLifeStyleModal((prev) => !prev);
  };

  const _renderBasicMedicalHistoryFields = (item: BasicMedicalHistoryFieldsType, index: number) => {
    return (
      <Grid key={index} item {...item.grid}>
        {!item.noLabel && (
          <Typography sx={{ pb: 1 }} component='div' color='#3E4685' variant='fontReg14'>
            <Grid container alignItems='center'>
              <Grid item xs>
                {item.label}
              </Grid>
              <Grid item xs='auto'>
                {item.showHistoryToolTip && (
                  <IconButton
                    sx={{ ml: 0.3 }}
                    size='small'
                    onClick={(event) => {
                      const data = { anchorEl: event.currentTarget, data: item.historyToolTipValues!, title: item.historyToolTipTitle! };
                      setTimeout(() => {
                        setCustomHistoryToolTipData(data);
                      }, 10);
                    }}>
                    <ListAltOutlinedIcon fontSize='small' htmlColor={'#3E4685'} sx={{ height: '14px', width: '14px' }} />
                  </IconButton>
                )}
              </Grid>
            </Grid>
          </Typography>
        )}

        <Typography component='div' color='#646464' variant='fontSemiBold14' sx={{ whiteSpace: 'normal', wordBreak: 'break-word' }}>
          {item.value}
        </Typography>
      </Grid>
    );
  };

  const _renderAlertsSection = () => {
    if (alerts && alerts.length !== 0) {
      const timestamp = alerts?.[0]?.timestampISO || alerts?.[0]?.timestamp;

      console.log('timestamptimestamp', timestamp);

      return (
        <CustomTooltip
          open={isOpenAlertsTooltip}
          onClose={handleCloseAlertsTooltip}
          onOpen={handleOpenAlertsTooltip}
          tooltipTitle={'Alerts'}
          timestamp={timestamp ? DateTime.fromISO(timestamp).toFormat('d LLL') : ''}
          customFormatTimestamp={<>{timestamp ? DateTime.fromISO(timestamp).toFormat('d LLL') : ''}</>}
          title={alerts.length !== 0 && alerts[0]?.payload?.inputPayload?.reason ? alerts[0]?.payload?.inputPayload?.reason : 'NA'}
          data={alerts}
          tooltipIcon={<WarningAmberIcon fontSize='small' sx={{ fontSize: '15px', color: '#33425B' }} />}
          showAddButton={false}
          totalCount={alerts.length}>
          <>
            {alerts.length !== 0
              ? alerts.map((e) => {
                const timestamp = e.timestampISO || e.timestamp;
                return (
                    <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
                      <CircleIcon sx={{ fontSize: '10px', marginRight: '5px', fill: '#33425B' }} />

                      <Typography sx={{ marginRight: '5px' }}>{DateTime.fromISO(timestamp).toFormat('d LLL yy')}':</Typography>

                      <Typography
                        sx={{
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          marginRight: '5px',
                          maxWidth: '500px',
                          color: '#646464',
                          marginLeft: '5px',
                        }}
                        color='InfoText'>
                        {e.payload?.inputPayload?.reason}
                      </Typography>
                    </Box>
                );
              })
              : 'NA'}
          </>
        </CustomTooltip>
      );
    } else {
      return null;
    }
  };

  const getCardiovasulcarDiseasesStatements = (cardiovascularDiseasesHistory?: string[]) => {
    return (
      cardiovascularDiseasesHistory
        ?.map((item) => {
          if (CARDIOVASULCAR_DISEASES_KEY_MAP[item]) {
            return CARDIOVASULCAR_DISEASES[CARDIOVASULCAR_DISEASES_KEY_MAP[item]].title;
          }
          return item;
        })
        .join(', ') || 'NA'
    );
  };

  const getnonCardiovasulcarDiseasesStatements = (nonCardiovascularDiseases?: string[]) => {
    return (
      nonCardiovascularDiseases
        ?.map((item) => {
          if (NON_CARDIOVASULCAR_DISEASES_KEY_MAP[item]) {
            return NON_CARDIOVASCULAR_DISEASES[NON_CARDIOVASULCAR_DISEASES_KEY_MAP[item]].title;
          } else {
            return item;
          }
        })
        ?.join(', ') || 'NA'
    );
  };

  const handleClickChangeFluidIntake = () => {
    setIsOpenFluidIntake(true);
  };

  const handleClickCloseFluidIntake = () => {
    setIsOpenFluidIntake(false);
  };

  const handleConfirmFluidIntake = async (rule: any) => {
    if (rule > 0 && rule < 8000) {
      patientRuleThreshold.forEach((item) => {
        if (item.vitalsType === 'fluidIntake') {
          item.warningHigh = rule;
        }
      });

      await handleSubmitUpdatePatientRuleThreshold(patientRuleThreshold);
      handleClickCloseFluidIntake();
    }
  };

  const latestMedicalAdherence = patientInfo.medicalAdherence;

  const medicalHistory = patientInfo?.medicalHistory || ({} as PatientMedicalHistoryPayloadType);

  let lastHospitalizationDate = patientInfo?.hospitalisation?.sort((a, b) => new Date(b.payload?.hospitalizationDate).getTime() - new Date(a.payload?.hospitalizationDate).getTime());

  if (_.isEmpty(lastHospitalizationDate) && patientInfo.enrolmentDetails?.hospitalization) {
    lastHospitalizationDate = [patientInfo.enrolmentDetails?.hospitalization];
  }

  const lastConsultationHistory = React.useMemo(() => {
    return medicalHistory?.lastConsultationHistory?.sort((a, b) => new Date(b.consultationDate).getTime() - new Date(a.consultationDate).getTime());
  }, [medicalHistory?.lastConsultationHistory]);

  const lastEDVisitHistory = React.useMemo(() => {
    const lastEDVisitHistory = medicalHistory?.lastEDVisitHistory || [];
    return JSON.parse(JSON.stringify(lastEDVisitHistory))?.sort((a: any, b: any) => new Date(b.edVisitDate).getTime() - new Date(a.edVisitDate).getTime());
  }, [medicalHistory?.lastEDVisitHistory]);

  const totalFluidIntake = patientLatestFluidIntake?.measurements?.reduce((acc: number, item) => {
    let value = item.value;

    if (item.unit?.toLowerCase() === 'l') {
      value = value * 1000;
    }

    if (item.type === 'DECREMENT' && acc > 0) {
      acc -= value;
    } else {
      acc += value;
    }

    return acc;
  }, 0);

  const basicMedicalHistoryFields: BasicMedicalHistoryFieldsType[] = [
    {
      id: 'symptoms',
      noLabel: true,
      value: <ViewPatientReportedSymptoms showTitle title='Symptoms' showDateAlongWithTitle alignContent='start' symptoms={patientInfo.titrationCycleSymptoms || []} />,
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'medication-adherence',
      label: 'Medication Adherence',
      value: latestMedicalAdherence ? (
        <Box>
          <Box sx={{ border: '0.5px solid #C2D3EF', background: '#ECEFF4', p: 0.5 }}>
            <Typography variant='fontReg12' component='div'>
              Wrong Dosage:
              <Typography variant='fontSemiBold12'>{latestMedicalAdherence?.payload?.isWrongDosage ? 'Yes' : 'No'}</Typography>
            </Typography>
            <Typography variant='fontReg12' component='div' sx={{ pt: 1 }}>
              Medications taken on time: <Typography variant='fontSemiBold12'>{latestMedicalAdherence?.payload?.questionnaireResponse}</Typography>
            </Typography>
          </Box>
          <Typography variant='fontReg12' component='div' sx={{ mt: 1.5, color: appTheme.palette.customColor.secondary, textAlign: 'center', textTransform: 'capitalize' }}>
            {getRelativeDate(latestMedicalAdherence?.payload?.endDate, undefined, undefined, true) || ''}
          </Typography>
        </Box>
      ) : (
        'NA'
      ),
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'pulse-rate',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={patientInfo.prescriptions?.prescriptions!}
          patientBasicDetails={patientInfo.basicDetails!}
          latestVitals={patientInfo.latestVitals || []}
          type='HEART_RATE'
          showTitle
          title='Pulse Rate'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
          ignoreSourceType='apple'
        />
      ),

      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'bp',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={patientInfo.prescriptions?.prescriptions!}
          patientBasicDetails={patientInfo.basicDetails!}
          latestVitals={patientInfo.latestVitals || []}
          type='BLOOD_PRESSURE'
          showTitle
          title='BP'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
        />
      ),
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'weight',
      label: 'Weight (lbs)',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={patientInfo.prescriptions?.prescriptions!}
          patientBasicDetails={patientInfo.basicDetails!}
          latestVitals={patientInfo.latestVitals || []}
          type='WEIGHT'
          showTitle
          title='Weight'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
        />
      ),
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'weight-change',
      label: 'Weight Change (lbs)',
      noLabel: true,
      value: (
        <ViewPatientVitalWeightReported
          prescriptions={patientInfo.prescriptions?.prescriptions!}
          patientBasicDetails={patientInfo.basicDetails!}
          latestVitals={patientInfo.latestVitals || []}
          vitalsReference={medicalHistory?.vitalsReference || []}
          weightChange={patientInfo?.weightChange}
          type='WEIGHT'
          showTitle
          title='Weight Change'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
        />
      ),
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'k',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={patientInfo.prescriptions?.prescriptions!}
          patientBasicDetails={patientInfo.basicDetails!}
          latestVitals={patientInfo.latestVitals || []}
          type='POTASSIUM'
          showTitle
          title='K'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
        />
      ),
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'scr',
      label: 'sCr (mEq/l)',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={patientInfo.prescriptions?.prescriptions!}
          patientBasicDetails={patientInfo.basicDetails!}
          latestVitals={patientInfo.latestVitals || []}
          type='SCR'
          showTitle
          title='sCr'
          showUnitWithTitle
          showDateBelowValue
          alignContent='start'
        />
      ),
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'egfr',
      label: 'eGFR (mEq/l)',
      noLabel: true,
      value: (
        <ViewPatientVitalReported
          prescriptions={patientInfo.prescriptions?.prescriptions!}
          patientBasicDetails={patientInfo.basicDetails!}
          latestVitals={patientInfo.latestVitals || []}
          type='EGFR'
          showTitle
          title='eGFR'
          showUnitWithTitle
          showDateBelowValue
          tooltipPlacement='auto'
          alignContent='start'
        />
      ),
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'fluidIntake',
      label: (
        <Box>
          Fluid Intake{' '}
          <Box sx={{ color: '#9B9A9A' }} component='span'>
            (ml)
          </Box>
        </Box>
      ),
      value: (
        <>
          <Grid container alignItems='center' gap={0.5}>
            <Typography component='div' color='#33425B' variant='fontSemiBold14'>
              {totalFluidIntake || 'NA'} {totalFluidIntake ? `/ ${getFluidIntakeValue()?.warningHigh}` : ''}
            </Typography>
            <Button onClick={handleClickChangeFluidIntake}>
              <Typography color='#4587F0' variant='fontReg12'>
                Change Limit
              </Typography>
            </Button>
          </Grid>
          <Typography component='div' color='#98A1B9' variant='fontReg12' marginTop={1.2}>
            {patientLatestFluidIntake?.date && getRelativeDate(patientLatestFluidIntake?.date, undefined, undefined, true)}
          </Typography>
        </>
      ),
      grid: {
        md: 4,
        xl: 5,
      },
    },
  ];

  const primaryDiagnosis = React.useMemo(() => {
    const hfType = medicalHistory?.heartFailureMedicalHistory?.hfType || 'NA';
    return HF_TYPES_OPTIONS?.find((item) => item.value === hfType)?.label || 'NA';
  }, [medicalHistory]);

  const primaryDiagnosisToolTipValues = React.useMemo(() => {
    return medicalHistory?.heartFailureMedicalHistory?.hfTypeHistory?.map((item) => {
      const label = HF_TYPES_OPTIONS?.find((ele) => ele.value === item.hfType)?.label || 'NA';
      return { date: item.date, value: label };
    });
  }, [medicalHistory]);

  const medicalConditions: BasicMedicalHistoryFieldsType[] = [
    {
      id: 'hy-type',
      label: 'Primary diagnosis',
      value: primaryDiagnosis,
      showHistoryToolTip: medicalHistory?.heartFailureMedicalHistory?.hfTypeHistory && medicalHistory?.heartFailureMedicalHistory?.hfTypeHistory?.length > 0,
      historyToolTipTitle: 'Primary diagnosis',
      historyToolTipValues: primaryDiagnosisToolTipValues,
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'nyha',
      label: 'NYHA',
      value: medicalHistory?.heartFailureMedicalHistory?.nyhaClass || 'NA',
      showHistoryToolTip: medicalHistory?.heartFailureMedicalHistory?.nyhaClassHistory && medicalHistory?.heartFailureMedicalHistory?.nyhaClassHistory?.length > 0,
      historyToolTipTitle: 'NYHA',
      historyToolTipValues: medicalHistory?.heartFailureMedicalHistory?.nyhaClassHistory?.map((item) => ({ date: item.date, value: item.nyhaClass })),
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'lvef',
      label: 'LVEF %',
      value: medicalHistory?.heartFailureMedicalHistory?.lvef || 'NA',
      showHistoryToolTip: medicalHistory?.heartFailureMedicalHistory?.lvefHistory && medicalHistory?.heartFailureMedicalHistory?.lvefHistory?.length > 0,
      historyToolTipTitle: 'LVEF %',
      historyToolTipValues: medicalHistory?.heartFailureMedicalHistory?.lvefHistory?.map((item) => ({ date: item.date, value: item.lvef })),
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'cardiovascular-diseases',
      label: 'Cardiovascular Diseases',
      value: getCardiovasulcarDiseasesStatements(medicalHistory?.cardiovascularDiseases),
      showHistoryToolTip: medicalHistory?.cardiovascularDiseasesHistory && medicalHistory?.cardiovascularDiseasesHistory?.length > 0,
      historyToolTipTitle: 'Cardiovascular Diseases',
      historyToolTipValues: medicalHistory?.cardiovascularDiseasesHistory?.map((item) => ({ date: item.date, value: getCardiovasulcarDiseasesStatements(item?.cardiovascularDiseases) })),
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'allergies',
      label: 'Allergies',
      value: patientInfo.medicalHistory?.allergies || 'NA',
      showHistoryToolTip: false,
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'non-cardiovascular-diseases',
      label: 'Non-Cardiovascular Diseases',
      value: getnonCardiovasulcarDiseasesStatements(medicalHistory?.nonCardiovascularDiseases),
      showHistoryToolTip: medicalHistory?.nonCardiovascularDiseasesHistory && medicalHistory?.nonCardiovascularDiseasesHistory?.length > 0,
      historyToolTipTitle: 'Non-Cardiovascular Diseases',
      historyToolTipValues: medicalHistory?.nonCardiovascularDiseasesHistory?.map((item) => ({ date: item.date, value: getnonCardiovasulcarDiseasesStatements(item?.nonCardiovascularDiseases) })),
      grid: {
        md: 4,
        xl: 5,
      },
    },
  ];

  const othersIllinessConditions: BasicMedicalHistoryFieldsType[] = [
    {
      id: 'Other illnesses',
      label: 'Other illnesses',
      value: medicalHistory?.otherHistoryLifeStyle?.otherIllnesses || 'NA',

      historyToolTipTitle: 'Other illnesses',
      showHistoryToolTip: medicalHistory?.otherHistoryLifeStyle?.otherIllnessesHistory && medicalHistory?.otherHistoryLifeStyle?.otherIllnessesHistory?.length > 0,
      historyToolTipValues: medicalHistory?.otherHistoryLifeStyle?.otherIllnessesHistory?.map((item) => ({ date: item.date, value: item.otherIllnesses })),

      grid: {
        xs: 3.5,
      },
    },
    {
      id: 'Family History',
      label: 'Family History',
      value: medicalHistory?.otherHistoryLifeStyle?.familyHistory || 'NA',

      historyToolTipTitle: 'Family History',
      showHistoryToolTip: medicalHistory?.otherHistoryLifeStyle?.familyHistoryHistory && medicalHistory?.otherHistoryLifeStyle?.familyHistoryHistory?.length > 0,
      historyToolTipValues: medicalHistory?.otherHistoryLifeStyle?.familyHistoryHistory?.map((item) => ({ date: item.date, value: item.familyHistory })),

      grid: {
        xs: 3.5,
      },
    },
    {
      id: 'Food Allergies',
      label: 'Food Allergies',
      value: medicalHistory?.otherHistoryLifeStyle?.foodAllergies || 'NA',

      historyToolTipTitle: 'Food Allergies',
      showHistoryToolTip: medicalHistory?.otherHistoryLifeStyle?.foodAllergiesHistory && medicalHistory?.otherHistoryLifeStyle?.foodAllergiesHistory?.length > 0,
      historyToolTipValues: medicalHistory?.otherHistoryLifeStyle?.foodAllergiesHistory?.map((item) => ({ date: item.date, value: item.foodAllergies })),

      grid: {
        xs: 3.5,
      },
    },
    {
      id: 'Drug Allergies',
      label: 'Drug Allergies',
      value: medicalHistory?.otherHistoryLifeStyle?.drugAllergiesContraindications || 'NA',

      historyToolTipTitle: 'Drug Allergies',
      showHistoryToolTip: medicalHistory?.otherHistoryLifeStyle?.drugAllergiesContraindicationsHistory && medicalHistory?.otherHistoryLifeStyle?.drugAllergiesContraindicationsHistory?.length > 0,
      historyToolTipValues: medicalHistory?.otherHistoryLifeStyle?.drugAllergiesContraindicationsHistory?.map((item) => ({ date: item.date, value: item.drugAllergiesContraindications })),

      grid: {
        xs: 3.5,
      },
    },
    {
      id: 'Diet',
      label: 'Diet',
      value: medicalHistory?.otherHistoryLifeStyle?.diet || 'NA',

      historyToolTipTitle: 'Diet',
      showHistoryToolTip: medicalHistory?.otherHistoryLifeStyle?.dietHistory && medicalHistory?.otherHistoryLifeStyle?.dietHistory?.length > 0,
      historyToolTipValues: medicalHistory?.otherHistoryLifeStyle?.dietHistory?.map((item) => ({ date: item.date, value: item.diet })),

      grid: {
        xs: 3.5,
      },
    },
    {
      id: 'Smoking',
      label: 'Smoking',
      value: medicalHistory?.otherHistoryLifeStyle?.smoking || 'NA',

      historyToolTipTitle: 'Smoking',
      showHistoryToolTip: medicalHistory?.otherHistoryLifeStyle?.smokingHistory && medicalHistory?.otherHistoryLifeStyle?.smokingHistory?.length > 0,
      historyToolTipValues: medicalHistory?.otherHistoryLifeStyle?.smokingHistory?.map((item) => ({ date: item.date, value: item.smoking })),

      grid: {
        xs: 3.5,
      },
    },
    {
      id: 'Alcohol',
      label: 'Alcohol',
      value: medicalHistory?.otherHistoryLifeStyle?.alcohol || 'NA',

      historyToolTipTitle: 'Alcohol',
      showHistoryToolTip: medicalHistory?.otherHistoryLifeStyle?.alcoholHistory && medicalHistory?.otherHistoryLifeStyle?.alcoholHistory?.length > 0,
      historyToolTipValues: medicalHistory?.otherHistoryLifeStyle?.alcoholHistory?.map((item) => ({ date: item.date, value: item.alcohol })),

      grid: {
        xs: 3.5,
      },
    },
    {
      id: 'Sleep',
      label: 'Sleep',
      value: medicalHistory?.otherHistoryLifeStyle?.sleep || 'NA',

      historyToolTipTitle: 'Sleep',
      showHistoryToolTip: medicalHistory?.otherHistoryLifeStyle?.sleepHistory && medicalHistory?.otherHistoryLifeStyle?.sleepHistory?.length > 0,
      historyToolTipValues: medicalHistory?.otherHistoryLifeStyle?.sleepHistory?.map((item) => ({ date: item.date, value: item.sleep })),

      grid: {
        xs: 3.5,
      },
    },
    {
      id: 'Physical Activity',
      label: 'Physical Activity',
      value: medicalHistory?.otherHistoryLifeStyle?.physicalActivity || 'NA',

      historyToolTipTitle: 'Physical Activity',
      showHistoryToolTip: medicalHistory?.otherHistoryLifeStyle?.physicalActivityHistory && medicalHistory?.otherHistoryLifeStyle?.physicalActivityHistory?.length > 0,
      historyToolTipValues: medicalHistory?.otherHistoryLifeStyle?.physicalActivityHistory?.map((item) => ({ date: item.date, value: item.physicalActivity })),

      grid: {
        xs: 3.5,
      },
    },
  ];

  const medicalHistoryDates: BasicMedicalHistoryFieldsType[] = [
    {
      id: 'patient-enrolment-date',
      label: <span>Patient Enrolment Date</span>,
      value: getDateFormat(patientInfo?.basicDetails?.enrollmentDate ? DateTime.fromJSDate(new Date(patientInfo?.basicDetails?.enrollmentDate)).toFormat('yyyy-MM-dd') : '', 'dd LLL yyyy') || 'NA',
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'last-review-date',
      label: <span>Last Review</span>,
      value:
        getDateFormat(
          patientInfo.prescriptions?.prescriptions?.prescriptionDate ? DateTime.fromJSDate(new Date(patientInfo.prescriptions?.prescriptions?.prescriptionDate)).toFormat('yyyy-MM-dd') : '',
          'dd LLL yyyy',
        ) || 'NA',
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'next-plan-date',
      label: <span>Next Planned Review</span>,
      value:
        getDateFormat(
          patientInfo.prescriptions?.prescriptions?.nextReviewDate ? DateTime.fromJSDate(new Date(patientInfo.prescriptions?.prescriptions?.nextReviewDate)).toFormat('yyyy-MM-dd') : '',
          'dd LLL yyyy',
        ) || 'NA',
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'last-hospitalization-date',
      label: <>Last Hospitalization</>,
      value:
        getDateFormat(
          lastHospitalizationDate?.[0]?.payload?.hospitalizationDate ? (DateTime.fromISO(lastHospitalizationDate?.[0]?.payload?.hospitalizationDate as string).toISO() as string) : '',
          'dd LLL yyyy',
        ) || 'NA',
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'last-consultation-date',
      label: (
        <>
          Last Consultation
          {patientInfo?.basicDetails?.patientStatus === PATIENT_ACTIVE_STATUS && (
            <IconButton sx={{ ml: 0.5, width: '20px', height: '20px' }} size='small' onClick={toggleLastConsultationModal}>
              <EditNoteIcon sx={{ width: '18px', height: '18px' }} />
            </IconButton>
          )}
        </>
      ),
      value: getDateFormat(lastConsultationHistory?.[0]?.consultationDate ? (DateTime.fromISO(lastConsultationHistory?.[0]?.consultationDate as string).toISO() as string) : '', 'dd LLL yyyy') || 'NA',
      grid: {
        md: 4,
        xl: 5,
      },
    },
    {
      id: 'last-ed-visit',
      label: (
        <>
          Last ED Visit
          {patientInfo?.basicDetails?.patientStatus === PATIENT_ACTIVE_STATUS && (
            <IconButton sx={{ ml: 0.5, width: '20px', height: '20px' }} size='small' onClick={toggleLastEDVisitModal}>
              <EditNoteIcon sx={{ width: '18px', height: '18px' }} />
            </IconButton>
          )}
        </>
      ),
      value: getDateFormat(lastEDVisitHistory?.[0]?.edVisitDate ? (DateTime.fromISO(lastEDVisitHistory?.[0]?.edVisitDate as string).toISO() as string) : '', 'dd LLL yyyy') || 'NA',
      grid: {
        md: 4,
        xl: 5,
      },
    },
  ];

  return (
    <Grid item xs={12} container sx={{ paddingRight: '10px' }}>
      <Accordion elevation={0} defaultExpanded sx={{ border: '1px solid #D7E1EB', borderRadius: '0px', '&.Mui-expanded': { m: '0px !important' } }}>
        <AccordionSummary expandIcon={<KeyboardArrowDownSharpIcon />} aria-controls='panel1-content' id='panel1-header'>
          <Grid item xs={12} container alignItems='center' gap={3}>
            <EcgWaveIcon />
            <Typography variant='fontSemiBold14' color='#33425B'>
              Latest Updates
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} container gap={2} justifyContent='space-between' pl={1}>
            {basicMedicalHistoryFields.map(_renderBasicMedicalHistoryFields)}
          </Grid>
          <Grid
            item
            xs={12}
            justifyItems='center'
            sx={{
              mt: 1,
              '& .custom-tooltop-container': {
                p: '8px 12px',
                borderRadius: '24px',
                '& .custom-tooltip-timestamp': {
                  color: '#5C6A90',
                  fontSize: '14px',
                  fontWeight: 400,
                },
                '& .custom-tooltip-title': {
                  fontSize: '14px',
                  fontWeight: 600,
                  color: '#33425b',
                },
                '& .custom-tooltip-count': {
                  background: '#f0f1ff',
                  fontSize: '12px',
                  fontWeight: 500,
                },
              },
            }}>
            {_renderAlertsSection()}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: '1px solid #D7E1EB', borderRadius: '0px', '&.Mui-expanded': { m: '0px !important' } }}>
        <AccordionSummary expandIcon={<KeyboardArrowDownSharpIcon />} aria-controls='panel1-content' id='panel1-header'>
          <Grid item xs={12} container alignItems='center' gap={3}>
            <MedicalServicesOutlinedIcon htmlColor='#33425B' />
            <Typography variant='fontSemiBold14' color='#33425B'>
              Medical Conditions
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} container gap={2} justifyContent='space-between' pl={1}>
            {medicalConditions.map(_renderBasicMedicalHistoryFields)}
          </Grid>
          <Grid item xs={12} container gap={2} justifyContent='space-between' pl={1} mt={2}>
            <Button variant='outlined' onClick={toggleEditMedicalHistoryModal}>
              <Typography variant='fontSemiBold14' color='#646464'>
                Update Details
              </Typography>
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: '1px solid #D7E1EB', borderRadius: '0px', '&.Mui-expanded': { m: '0px !important' } }}>
        <AccordionSummary expandIcon={<KeyboardArrowDownSharpIcon />} aria-controls='panel1-content' id='panel1-header'>
          <Grid item xs={12} container alignItems='center' gap={3}>
            <CalendarMonthSharpIcon htmlColor='#33425B' />
            <Typography variant='fontSemiBold14' color='#33425B'>
              Key Timeline Dates
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} container gap={2} justifyContent='space-between' pl={1}>
            {medicalHistoryDates.map(_renderBasicMedicalHistoryFields)}
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion elevation={0} sx={{ border: '1px solid #D7E1EB', borderRadius: '0px', '&.Mui-expanded': { m: '0px !important' } }}>
        <AccordionSummary expandIcon={<KeyboardArrowDownSharpIcon />} aria-controls='panel1-content' id='panel1-header'>
          <Grid item xs={12} container alignItems='center' gap={3}>
            <HikingIcon htmlColor='#33425B' />
            <Typography variant='fontSemiBold14' color='#33425B'>
              Other History and Lifestyle
            </Typography>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid item xs={12} container gap={2} justifyContent='space-between' pl={1}>
            {othersIllinessConditions.map(_renderBasicMedicalHistoryFields)}
          </Grid>
          <Grid item xs={12} container gap={2} justifyContent='space-between' pl={1} mt={2}>
            <Button variant='outlined' onClick={toggleEditOtherHistoryLifeStyleModal}>
              <Typography variant='fontSemiBold14' color='#646464'>
                Update Details
              </Typography>
            </Button>
          </Grid>
        </AccordionDetails>
      </Accordion>

      {customHistoryToolTipData?.anchorEl && customHistoryToolTipData?.data && (
        <CustomPopper
          popperProps={{
            open: Boolean(customHistoryToolTipData?.anchorEl),
            anchorEl: customHistoryToolTipData?.anchorEl,
            placement: 'bottom-end',
          }}
          paperProps={{
            elevation: 5,
            sx: {
              minWidth: '22rem',
              maxWidth: '30rem',
              p: 1.5,
            },
          }}
          onClose={toggleHistoryToolTip}>
          <Typography variant='fontSemiBold14' sx={{ color: appTheme.palette.customColor.text }}>
            {customHistoryToolTipData?.title}
          </Typography>
          <Box
            component='div'
            sx={{
              background: '#ECEFF4',
              border: '0.5px solid #C2D3EF',
              borderRadius: '5px',
              overflow: 'auto',
              maxHeight: '10rem',
              p: 1,
            }}>
            {customHistoryToolTipData?.data?.map((item: { date: string; value: string }, index: number) => {
              const relativeDate = item?.date && DateTime.fromJSDate(new Date(item.date)).toFormat('dd MMM yy');
              return (
                <Grid key={index} mt={1} item xs={12} container alignItems='baseline' gap={0.5}>
                  <Grid item xs='auto'>
                    <CircleIcon sx={{ fill: 'black', width: '8px !important', height: '8px !important' }} />
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant='fontReg14' color={appTheme.palette.customColor.text} component='div'>
                      {relativeDate}
                    </Typography>
                  </Grid>

                  <Grid item xs>
                    <Typography variant='fontReg14' color={appTheme.palette.customColor.text} component='div' sx={{ wordBreak: 'break-word', whiteSpace: 'normal' }}>
                      {item.value}
                    </Typography>
                  </Grid>
                </Grid>
              );
            })}
          </Box>
        </CustomPopper>
      )}

      {isOpenEditMedicalHistoryModal && (
        <EditMedicalHistoryDialog
          isOpen={isOpenEditMedicalHistoryModal}
          isUpdatingAPICalling={isPatientMedicalHistoryUpdatingApiCalling}
          patientInfo={patientInfo}
          handleClose={toggleEditMedicalHistoryModal}
          handleSubmit={async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
            await handleSubmitPatientMedicalHistory(payload);
            toggleEditMedicalHistoryModal();
          }}
        />
      )}

      {isOpenLastHospitalizationModal && (
        <EditLastHospitalization
          isOpen={isOpenLastHospitalizationModal}
          isUpdatingAPICalling={isPatientMedicalHistoryUpdatingApiCalling}
          patientInfo={patientInfo}
          handleClose={toggleLastHospitalizationModal}
          handleSubmit={async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
            await handleSubmitPatientMedicalHistory(payload);
            toggleLastHospitalizationModal();
          }}
        />
      )}

      {isOpenLastConsultationModal && (
        <EditLastConsultation
          isOpen={isOpenLastConsultationModal}
          isUpdatingAPICalling={isPatientMedicalHistoryUpdatingApiCalling}
          patientInfo={patientInfo}
          handleClose={toggleLastConsultationModal}
          handleSubmit={async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
            await handleSubmitPatientMedicalHistory(payload);
            toggleLastConsultationModal();
          }}
        />
      )}

      {isOpenLastEDVisitModal && (
        <EditLastEDVisit
          isOpen={isOpenLastEDVisitModal}
          isUpdatingAPICalling={isPatientMedicalHistoryUpdatingApiCalling}
          patientInfo={patientInfo}
          handleClose={toggleLastEDVisitModal}
          handleSubmit={async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
            await handleSubmitPatientMedicalHistory(payload);
            toggleLastEDVisitModal();
          }}
        />
      )}

      {isOpenEditOtherHistoryLifeStyleModal && (
        <EditOtherMedicalHistory
          isOpen={isOpenEditOtherHistoryLifeStyleModal}
          isUpdatingAPICalling={isPatientMedicalHistoryUpdatingApiCalling}
          patientInfo={patientInfo}
          handleClose={toggleEditOtherHistoryLifeStyleModal}
          handleSubmit={async (payload: Partial<PatientMedicalHistoryPayloadType>) => {
            await handleSubmitPatientMedicalHistory(payload);
            toggleEditOtherHistoryLifeStyleModal();
          }}
        />
      )}

      {isOpenFluidIntake && (
        <ConfirmationDialogWithNotesComponent
          title='Change Fluid Intake Limit'
          description=''
          isOpen={isOpenFluidIntake}
          onClose={handleClickCloseFluidIntake}
          onClickConfirm={handleConfirmFluidIntake}
          isLoading={isUpdatingPatientRuleThreshold}
          customValidate={(value: any) => {
            if (!value) {
              return 'Fluid intake is required';
            }

            if (value < 100) {
              return 'Fluid intake should be greater than 100 ml';
            }

            if (value > 8000) {
              return 'Fluid intake should be less than 8000 ml';
            }
            return null;
          }}
          inputLabel=''
          maxWidthDialog='xs'
          additionalInputProps={{
            endAdornment: <Typography>ml</Typography>,
            type: 'number',
          }}
        />
      )}
    </Grid>
  );
};

export default Summary;
