/** @format */

import { DateTime } from 'luxon';
import { OptionsOrGroups } from 'react-select';
import { BasicDetailsType } from '../types/Patients.types';
import { DropdownOptionType } from '../types/CustomForm.types';

export const DEMOGRAPHIC_DETAIL = 'Demographic Detail';
export const HEART_FAILURE_MEDICAL_HISTORY = 'Heart Failure Medical History';
export const SCHEDULE_VISIT = 'Schedule Visit';
export const CARE_PLAN = 'Care Plan';
export const PREVIEW = 'Preview';
export const Medications = 'Medications';

export const TEXT_INPUT_COMPONENT = 'TEXT_INPUT_COMPONENT';
export const TEXT_SELECT_COMPONENT = 'TEXT_SELECT_COMPONENT';
export const TEXT_SELECT_CHECKBOX_MULTI_COMPONENT = 'TEXT_SELECT_CHECKBOX_MULTI_COMPONENT';
export const DROPDOWN_INPUT_COMPONENT = 'DROPDOWN_INPUT_COMPONENT';
export const TEXT_AREA_INPUT_COMPONENT = 'TEXT_AREA_INPUT_COMPONENT';
export const RADIO_GROUP_INPUT_COMPONENT = 'RADIO_GROUP_INPUT_COMPONENT';
export const CHECKBOX_GROUP_INPUT_COMPONENT = 'CHECKBOX_GROUP_INPUT_COMPONENT';
export const PHONE_NUMBER_INPUT_COMPONENT = 'PHONE_NUMBER_INPUT_COMPONENT';
export const CUSTOM_INPUT_COMPONENT = 'CUSTOM_INPUT_COMPONENT';
export const OTP_INPUT_COMPONENT = 'OTP_INPUT_COMPONENT';
export const TEXT_DOUBLE_INPUT_COMPONENT = 'TEXT_DOUBLE_INPUT_COMPONENT';
export const REMOVE_BUTTON = 'REMOVE_BUTTON';

export const VITALS_HEART_RATE_UNIT = 'bpm';
export const VITALS_BLOOD_PRESSURE_UNIT = 'mm Hg';
export const VITALS_WEIGHT_UNIT = 'lbs';

export const SESSION_OPTIONS: OptionsOrGroups<DropdownOptionType, any> = [
  {
    label: 'M',
    value: 'M',
  },
  {
    label: 'A',
    value: 'A',
  },
  {
    label: 'E',
    value: 'E',
  },
  {
    label: 'N',
    value: 'N',
  },
];

export const IGNORE_ETHNICITY_OPTIONS = ['AFRICAN_AMERICAN', 'WHITE', 'HISPANIC', 'ASIAN', 'AMERICAN_INDIAN', 'NATIVE_HAWAIIAN'];

export const ETHNICITY_OPTIONS: OptionsOrGroups<DropdownOptionType, any> = [
  {
    label: 'Indian',
    value: 'Indian',
  },
  {
    label: 'African',
    value: 'African',
  },
  {
    label: 'Asian',
    value: 'Asian',
  },
  {
    label: 'White',
    value: 'White',
  },
  {
    label: 'Hispanics',
    value: 'Hispanics',
  },

  {
    label: 'Other',
    value: 'OTHER',
  },

  {
    label: 'White',
    value: 'WHITE',
  },
  {
    label: 'Black or African American',
    value: 'AFRICAN_AMERICAN',
  },
  {
    label: 'Hispanic',
    value: 'HISPANIC',
  },
  {
    label: 'Asian',
    value: 'ASIAN',
  },
  {
    label: 'American Indian or Alaska Native',
    value: 'AMERICAN_INDIAN',
  },
  {
    label: 'Native Hawaiian or Other Pacific Islander',
    value: 'NATIVE_HAWAIIAN',
  },
];

export const DROPDOWN_MAPPING: { [m: string]: string } = {
  WHITE: 'White',
  AFRICAN_AMERICAN: 'African American',
  HISPANIC: 'Hispanic',
  ASIAN: 'Asian',
  AMERICAN_INDIAN: 'American Indian or Alaska Native',
  NATIVE_HAWAIIAN: 'Native Hawaiian or Other Pacific Islander',
  OTHER: 'Other',

  MALE: 'Male',
  FEMALE: 'Female',
  HFrEF: 'HFrEF',
  HFpEF: 'HFpEF',
  HFmrEF: 'HFmrEF',
  HFimpEF: 'HFimpEF',
  I: 'I',
  II: 'II',
  IV: 'IV',
  III: 'III',

  PREFER_NOT_TO_SAY: 'Prefer not to say',
};

export const GENDER_OPTIONS: OptionsOrGroups<DropdownOptionType, any> = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Other',
    value: 'OTHER',
  },
  {
    label: 'Prefer not to say',
    value: 'PREFER_NOT_TO_SAY',
  },
];

export const HF_TYPES_OPTIONS: OptionsOrGroups<DropdownOptionType, any> = [
  {
    label: 'HFrEF',
    value: 'HFrEF',
  },
  {
    label: 'HFpEF',
    value: 'HFpEF',
  },
  {
    label: 'HFmrEF',
    value: 'HFmrEF',
  },

  {
    label: 'HFimpEF',
    value: 'HFimpEF',
  },

  {
    label: 'Hypertension',
    value: 'HYPERTENSION',
  },

  {
    label: 'Coronary Artery Disease',
    value: 'CORONARY_ARTERY_DISEASE',
  },

  {
    label: 'Type 2 Diabetes Mellitus',
    value: 'TYPE_2_DIABETES_MELLITUS',
  },
];

export const NYHA_OPTIONS: OptionsOrGroups<DropdownOptionType, any> = [
  {
    label: 'I',
    value: 'I',
  },
  {
    label: 'II',
    value: 'II',
  },
  {
    label: 'III',
    value: 'III',
  },
  {
    label: 'IV',
    value: 'IV',
  },
];

export const RELATIONSHIP_OF_ALT_CONTACT_OPTIONS: DropdownOptionType[] = [
  {
    label: 'Spouse',
    value: 'SPOUSE',
  },

  {
    label: 'Son',
    value: 'SON',
  },

  {
    label: 'Daughter',
    value: 'DAUGHTER',
  },

  {
    label: 'Siblings',
    value: 'SIBLINGS',
  },

  {
    label: 'Other Relatives',
    value: 'OTHER_RELATIVES',
  },

  {
    label: 'Friend',
    value: 'FRIEND',
  },

  {
    label: 'Caretaker / Guardian',
    value: 'CARETAKER',
  },
];

export const HF_MEDICAL_HISTORY_INITIAL_STATE = {
  dateOfHFDiagnosis: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
  hfType: '',
  nyhaClass: '',
  lastHospitalizationDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
  lvef: '',
};

export const HF_MEDICAL_HISTORY_INITIAL_ERROR_STATE = {
  dateOfHFDiagnosis: '',
  hfType: '',
  nyhaClass: '',
  lastHospitalizationDate: '',
  lvef: '',
};

export const PATIENT_BASIC_DETAILS_INITIAL_STATE: BasicDetailsType = {
  address: '',
  patientId: '',
  firstName: '',
  lastName: '',
  age: '',
  gender: '',
  ethnicity: '',
  email: '',
  phoneNumber: '',
  phoneNumberAlt: '',
  relationshipAltContact: '',
  relationshipAltContactName: '',
  cardiologistId: '',
  enrollmentDate: DateTime.now().toFormat('yyyy-MM-dd'),
};

export const PATIENT_ERROR_BASIC_DETAILS_INITIAL_STATE = {
  address: '',
  patientId: '',
  firstName: '',
  lastName: '',
  age: '',
  gender: '',
  ethnicity: '',
  email: '',
  phoneNumber: '',
  relationshipAltContact: '',
  enrollmentDate: '',
};

export const EDIT_BASIC_DETAILS_INITIAL_STATE = {
  physician: false,
  basicDetails: false,
  hfMedicalHistory: false,
  medicalHistory: false,
  vitalsReading: false,
  appointments: false,
  tcpMedications: false,
  patientRules: false,
  prevMedications: false,
  hospitalisation: false,
};

export const PATIENT_BASIC_DETAILS_KEY_MAPPING: { [m: string]: string } = {
  address: 'Address',
  patientId: 'Patient Details',
  firstName: 'First Name',
  lastName: 'Last Name',
  age: 'Age',
  gender: 'Gender',
  ethnicity: 'Ethnicity',
  email: 'Email',
  phoneNumber: 'Phone Number',
  // phoneNumberAlt: 'Alternate Phone Number',
  relationshipAltContact: 'Relationship of Alt Contact',
  relationshipAltContactName: 'Relationship of Alt Contact Name',
  enrollmentDate: 'Enrollment date',
};

export const PATIENT_HF_MEDICAL_HISTORY_KEY_MAPPING: { [m: string]: string } = {
  dateOfHFDiagnosis: 'date of Heart Failure Diagnosis',
  hfType: 'Heart Failure Type',
  nyhaClass: 'NYHA Class',
  lastHospitalizationDate: 'Last Hospitalization Date',
  lvef: 'LVEF',
};

export const COMORBIDITIES_MAP: { [m: string]: string } = {
  CHRONIC_KIDNEY_DISEASE: 'chronicKidneyDisease',
  AFIB: 'afib',
  OBESITY: 'obesity',
  HYPER_TENSION: 'hyperTension',
  SLEEP_DYSPNEA: 'sleepDyspnea',
  ANAEMIA: 'anaemia',
  DIABETES_TYPE_I: 'diabetesTypeI',
  DIABETES_TYPE_II: 'diabetesTypeII',
  OTHERS: 'others',
};

export const COMORBIDITIES_REVERSE_MAP: { [m: string]: string } = {
  chronicKidneyDisease: 'CHRONIC_KIDNEY_DISEASE',
  afib: 'AFIB',
  obesity: 'OBESITY',
  hyperTension: 'HYPER_TENSION',
  sleepDyspnea: 'SLEEP_DYSPNEA',
  anaemia: 'ANAEMIA',
  diabetesTypeI: 'DIABETES_TYPE_I',
  diabetesTypeII: 'DIABETES_TYPE_II',
  others: 'OTHERS',
};

export const CARDIOVASULCAR_DISEASES_REVERSE_MAP: { [m: string]: string } = {
  coronaryHeartDisease: 'CORONARY_HEART_DISEASE',
  valvularHeartDisease: 'VALVULAR_HEART_DISEASE',
  typeIDiabetesMellitus: 'TYPE_I_DIABETES_MELLITUS',
  typeIIDiabetesMellitus: 'TYPE_II_DIABETES_MELLITUS',
  chronicKidneyDisease: 'CHRONIC_KIDNEY_DISEASE',
  atrialFibrillation: 'ATRIAL_FIBRILLATION',
  hypertension: 'HYPERTENSION',
  peripheralArteryDisease: 'PERIPHERAL_ARTERY_DISEASE',
  priorMyocardialInfarction: 'PRIOR_MYOCARDIAL_INFARCTION',
  aorticPlaque: 'AORTIC_PLAQUE',
  stroke: 'STROKE',
  others: 'OTHERS',
};

export const NON_CARDIOVASULCAR_DISEASES_REVERSE_MAP: { [m: string]: string } = {
  copd: 'COPD',
  hypothyroidism: 'HYPOTHYROIDISM',
  anaemia: 'ANAEMIA',
  ironDeficiency: 'IRON_DEFICIENCY',
  obesity: 'OBESITY',
  alcohol: 'ALCOHOL',
  dementia: 'DEMENTIA',
  smoking: 'SMOKING',
  others: 'OTHERS',
};

export const PATIENT_DRAFT_STATUS = 'DRAFT';
export const PATIENT_APPROVAL_STATUS = 'APPROVE';
export const PATIENT_ACTIVE_STATUS = 'ACTIVE';
export const PATIENT_DEACTIVE_STATUS = 'DEACTIVE';

export const CARDIOVASULCAR_DISEASES: { [key: string]: any } = {
  hypertension: { title: 'Hypertension', type: 'boolean', default: false },
  coronaryHeartDisease: { title: 'Coronary Heart Disease', type: 'boolean', default: false },
  priorMyocardialInfarction: { title: 'Prior Myocardial Infarction (MI)', type: 'boolean', default: false },
  valvularHeartDisease: { title: 'Valvular Heart Disease', type: 'boolean', default: false },
  atrialFibrillation: { title: 'Atrial Fibrillation / Flutter', type: 'boolean', default: false },
  stroke: { title: 'Stroke / Transient Ischemic Attack (TIA) / Thromboembolism', type: 'boolean', default: false },
  aorticPlaque: { title: 'Aortic Plaque', type: 'boolean', default: false },
  peripheralArteryDisease: { title: 'Peripheral Artery Disease ', type: 'boolean', default: false },
  typeIDiabetesMellitus: { title: 'Type I Diabetes Mellitus', type: 'boolean', default: false },
  typeIIDiabetesMellitus: { title: 'Type II Diabetes Mellitus', type: 'boolean', default: false },
  chronicKidneyDisease: { title: 'Chronic Kidney Disease', type: 'boolean', default: false },
  others: { title: 'Others', type: 'boolean', default: false },
};

export const CARDIOVASULCAR_DISEASES_KEY_MAP: { [m: string]: string } = {
  CORONARY_HEART_DISEASE: 'coronaryHeartDisease',
  VALVULAR_HEART_DISEASE: 'valvularHeartDisease',
  TYPE_I_DIABETES_MELLITUS: 'typeIDiabetesMellitus',
  TYPE_II_DIABETES_MELLITUS: 'typeIIDiabetesMellitus',
  CHRONIC_KIDNEY_DISEASE: 'chronicKidneyDisease',
  ATRIAL_FIBRILLATION: 'atrialFibrillation',
  HYPERTENSION: 'hypertension',
  PERIPHERAL_ARTERY_DISEASE: 'peripheralArteryDisease',
  PRIOR_MYOCARDIAL_INFARCTION: 'priorMyocardialInfarction',
  AORTIC_PLAQUE: 'aorticPlaque',
  STROKE: 'stroke',
  OTHERS: 'others',
};

export const CARDIOVASULCAR_DISEASES_CONSTANTS = [
  'CORONARY_HEART_DISEASE',
  'VALVULAR_HEART_DISEASE',
  'TYPE_I_DIABETES_MELLITUS',
  'TYPE_II_DIABETES_MELLITUS',
  'CHRONIC_KIDNEY_DISEASE',
  'ATRIAL_FIBRILLATION',
  'HYPERTENSION',
  'PERIPHERAL_ARTERY_DISEASE',
  'PRIOR_MYOCARDIAL_INFARCTION',
  'AORTIC_PLAQUE',
  'STROKE',
  'OTHERS',
];

export const NON_CARDIOVASCULAR_DISEASES_CONSTANTS = ['COPD', 'HYPOTHYROIDISM', 'ANAEMIA', 'IRON_DEFICIENCY', 'OBESITY', 'SMOKING', 'ALCOHOL', 'DEMENTIA', 'OTHERS']; //

export const NON_CARDIOVASULCAR_DISEASES_KEY_MAP: { [m: string]: string } = {
  COPD: 'copd',
  HYPOTHYROIDISM: 'hypothyroidism',
  ANAEMIA: 'anaemia',
  IRON_DEFICIENCY: 'ironDeficiency',
  OBESITY: 'obesity',
  SMOKING: 'smoking',
  ALCOHOL: 'alcohol',
  DEMENTIA: 'dementia',
  OTHERS: 'others',
};

export const NON_CARDIOVASCULAR_DISEASES: { [key: string]: any } = {
  copd: { title: 'Chronic Obstructive Pulmonary Disease (COPD)', type: 'boolean', default: false },
  hypothyroidism: { title: 'Hypothyroidism', type: 'boolean', default: false },
  anaemia: { title: 'Anaemia', type: 'boolean', default: false },
  ironDeficiency: { title: 'Iron Deficiency', type: 'boolean', default: false },
  obesity: { title: 'Obesity', type: 'boolean', default: false },
  smoking: { title: 'Smoking History (Vaping, Chewing Tobacco, etc )', type: 'boolean', default: false },
  alcohol: { title: 'Alcohol Consumption History', type: 'boolean', default: false },
  dementia: { title: 'Dementia', type: 'boolean', default: false },
  others: { title: 'Others', type: 'boolean', default: false },
};

export const SYMPTOMS_KEYS_MAP: { [key: string]: string } = {
  NONE: 'noSymptoms',
  BREATH_DAILY_ACT: 'shortnessOfBreath',
  BREATH_ON_EXERTION: 'dyspnea',
  BREATH_SLEEP: 'orthopnea',
  PND: 'paraoxysmalNocturnalDyspnea',
  TIGHT_CHEST: 'chestTightness',
  DIZZINESS: 'dizziness',
  DRY_COUGH: 'dryCough',
  FATIGUE: 'fatigue',
  SWELL_FACE: 'swellingLipsEyes',
  SWELL_HAND_LEG: 'swellingHandsFeet',
  WHEEZING: 'wheezing',
  OTHERS: 'others',
  SHORTNESS: 'shortness',
  DYSPNEABREATHLESSON: 'dyspneaBreathlesson',
  ORTHOPNEABREATHLESS: 'orthopneaBreathless',
  PARAOXYSMAL: 'paraoxysmal',
  SWELLINGHANDSLEGSFEET: 'swellingHandsLegsFeet',
  PALPITATIONS: 'palpitation',
  SYNCOPE: 'syncope',
  BREATH_REST: 'breathRest',
  SWELL_PERI: 'swellPeri',
  SWELL_LOWER: 'swellLower',
  SWELL_ABD: 'swellAbd',
  DRY_COUGH_2: 'dryCoughTwo',
  COPD: 'copd',
  SWELL_CHEST_1: 'swellChestOne',
  SWELL_CHEST_2: 'swellChestTwo',
  NAUSEA: 'nausea',
};

export const SYMPTOMS_REVERSE_KEY_MAP: { [key: string]: string } = {
  noSymptoms: 'NONE',
  shortnessOfBreath: 'BREATH_DAILY_ACT',
  dyspnea: 'BREATH_ON_EXERTION',
  orthopnea: 'BREATH_SLEEP',
  paraoxysmalNocturnalDyspnea: 'PND',
  chestTightness: 'TIGHT_CHEST',
  dizziness: 'DIZZINESS',
  dryCough: 'DRY_COUGH',
  fatigue: 'FATIGUE',
  palpitations: 'PALPITATIONS',
  swellingLipsEyes: 'SWELL_FACE',
  swellingHandsFeet: 'SWELL_HAND_LEG',
  wheezing: 'WHEEZING',
  others: 'OTHERS',
  shortness: 'SHORTNESS',
  dyspneabreathlesson: 'DYSPNEABREATHLESSON',
  orthopneabreathless: 'ORTHOPNEABREATHLESS',
  paraoxysmal: 'PARAOXYSMAL',
  swellinghandslegsfeet: 'SWELLINGHANDSLEGSFEET',
  palpitation: 'PALPITATIONS',
  syncope: 'SYNCOPE',
  breathRest: 'BREATH_REST',
  swellPeri: 'SWELL_PERI',
  swellLower: 'SWELL_LOWER',
  swellAbd: 'SWELL_ABD',
  dryCoughTwo: 'DRY_COUGH_2',
  copd: 'COPD',
  swellChestOne: 'SWELL_CHEST_1',
  swellChestTwo: 'SWELL_CHEST_2',
  nausea: 'NAUSEA',
};
export const IGNORE_SYMPTOMS = ['dyspnea', 'orthopnea', 'shortness', 'dyspneaBreathlesson', 'orthopneaBreathless', 'paraoxysmal', 'wheezing', 'swellingHandsFeet', 'swellingHandsLegsFeet'];

export const SYMPTOMS: { [key: string]: any } = {
  noSymptoms: { title: 'No symptoms', type: 'boolean', default: false },
  shortnessOfBreath: { title: 'Worsening shortness of breath with activity', type: 'boolean', default: false }, // added
  breathRest: { title: 'Shortness of breath at rest ', type: 'boolean', default: false }, // added
  swellPeri: { title: 'Increased swelling of legs, ankles and feet', type: 'boolean', default: false }, // added
  swellLower: { title: 'Increased discomfort or swelling in the lower body', type: 'boolean', default: false }, // added
  swellAbd: { title: 'Discomfort or swelling in the abdomen', type: 'boolean', default: false }, // added,
  chestTightness: { title: 'Chest pain / Chest tightness on exertion', type: 'boolean', default: false },
  dryCough: { title: 'Dry hacking cough', type: 'boolean', default: false },
  dryCoughTwo: { title: 'Frequent dry, hacking cough', type: 'boolean', default: false }, // added
  copd: { title: 'New or worsening wheezing / Audible sounds in the chest during exhalation', type: 'boolean', default: false }, // added
  swellChestOne: { title: ' Trouble sleeping', type: 'boolean', default: false }, // added
  swellChestTwo: { title: 'Increased trouble sleeping. Cannot lie flat due to shortness of breath', type: 'boolean', default: false }, // added
  paraoxysmalNocturnalDyspnea: { title: 'Waking up with shortness of breath', type: 'boolean', default: false }, // Dyspnea
  nausea: { title: 'Loss of appetite', type: 'boolean', default: false }, // added
  swellingLipsEyes: { title: 'Swelling of lips and eyes', type: 'boolean', default: false },
  palpitation: { title: 'Noticeably rapid, strong, or irregular heartbeat', type: 'boolean', default: false }, // new
  fatigue: { title: 'Increased Fatigue', type: 'boolean', default: false }, // added
  syncope: { title: 'Fainting', type: 'boolean', default: false }, // added
  others: { title: 'Others', default: false, type: 'boolean' },
};

export const SHOW_SYMPTOMS_DATA: { [Key: string]: any } = {
  // swellingHandsFeet: { readOnly: true },
  // // palpitations: { readOnly: true },
  // // newFatigue: { readOnly: true },
  // syncope: { readOnly: true },
  // // breathdDailyAct: { readOnly: true },
  // breathRest: { readOnly: true },
  // swellPeri: { readOnly: true },
  // swellLower: { readOnly: true },
  // swellAbd: { readOnly: true },
  // dryCoughOne: { readOnly: true },
  // dryCoughTwo: { readOnly: true },
  // copd: { readOnly: true },
  // newDizziness: { readOnly: true },
  // swellChestOne: { readOnly: true },
  // swellChestTwo: { readOnly: true },
  // nausea: { readOnly: true },
  // palpitation: { readOnly: true },
  // swellingHandsLegsFeet: { readOnly: true },
  // shortness: { readOnly: true },
  // paraoxysmal: { readOnly: true },
  // dyspneaBreathlesson: { readOnly: true },
  // orthopneaBreathless: { readOnly: true },
  // fatigue: { readOnly: true },
  // shortnessOfBreath: { readOnly: true },
  // dyspnea: { readOnly: true },
  // orthopnea: { readOnly: true },
  // paraoxysmalNocturnalDyspnea: { readOnly: true },
  // wheezing: { readOnly: true },
  // dizziness: { readOnly: true },
  // swellingLipsEyes: { readOnly: true },
  // dryCough: { readOnly: true },
  // chestTightness: { readOnly: true },
  // others: { readOnly: true },

  shortnessOfBreath: { readOnly: true },
  breathRest: { readOnly: true },
  swellPeri: { readOnly: true },
  swellLower: { readOnly: true },
  swellAbd: { readOnly: true },
  chestTightness: { readOnly: true },
  dryCough: { readOnly: true },
  dryCoughTwo: { readOnly: true },
  copd: { readOnly: true },
  swellChestOne: { readOnly: true },
  swellChestTwo: { readOnly: true },
  paraoxysmalNocturnalDyspnea: { readOnly: true },
  nausea: { readOnly: true },
  swellingLipsEyes: { readOnly: true },
  palpitation: { readOnly: true },
  fatigue: { readOnly: true },
  syncope: { readOnly: true },
  others: { readOnly: true },
};

export const PATIENT_BASIC_FORM_FIELDS: { [key: string]: any } = {
  enrollmentDate: {
    type: 'string',
    title: 'Enrollment Date',
    default: DateTime.now().toFormat('yyyy-MM-dd'),
  },
  patientId: {
    type: 'string',
    title: 'Patient ID',
  },
  firstName: { type: 'string', title: 'Given Name / First Name' },
  lastName: { type: 'string', title: 'Surname / Last Name' },
  ethnicity: {
    type: 'string',
    title: 'Ethnicity',
    default: 'Indian',
  },
  age: { type: 'number', title: 'Age (Years)' },
  gender: { type: 'string', title: 'Gender' },
  phoneNumber: { type: 'string', title: 'Phone Number' },
  email: { type: 'string', title: 'Email' },
  address: { type: 'string', title: 'Address' },
  phoneNumberAlt: { type: 'string', title: 'Alternate Phone Number' },
  // relationshipAltContact: { type: 'string', title: 'Relationship of Alt Contact' },
  // relationshipAltContactName: { type: 'string', title: 'Alt Contact Name' },
};

export const VITALS_FORM_FIELDS: { [key: string]: any } = {
  bp: { type: 'object', title: 'Blood Pressure', properties: { sbp: { type: 'number' }, dbp: { type: 'number' } } },
  hr: { type: 'number', title: 'Pulse Rate' },
  weight: { type: 'number', title: 'Weight' },
  height: { type: 'object', title: 'Height', properties: { feet: { type: 'number' }, inches: { type: 'number' } } },
  bmi: { type: 'number', title: 'BMI', readOnly: true },
  chloride: { type: 'number', title: 'Chloride' },
  bnp: { type: 'number', title: 'BNP' },
  ntprobnp: { type: 'number', title: 'NTproBNP' },
  scr: { type: 'number', title: 'sCr' },
  egfr: { type: 'number', title: 'eGFR' },
  potassium: { type: 'number', title: 'Potassium' },
  calcium: { type: 'number', title: 'Calcium' },
  sodium: { type: 'number', title: 'Sodium' },
  glucose: { type: 'number', title: 'Glucose' },
  bloodUreaNitrogen: { type: 'number', title: 'Blood Urea Nitrogen' },
  hemoglobin: { type: 'number', title: 'Hemoglobin' },
  hematocrit: { type: 'number', title: 'Hematocrit' },
  hba1c: { type: 'number', title: 'HbA1c' },
  bicarbonate: { type: 'number', title: 'Bicarbonate' },
};

export const TEMP_FORM_DATA = {
  patientBasic: {
    enrollmentDate: DateTime.now().toFormat('yyyy-MM-dd'),
    patientId: '121212',
    age: 67,
    gender: 'MALE',
    ethnicity: 'WHITE',
    firstName: 'hello',
    lastName: 'world',
    address: '121212',
    phoneNumber: '989898989',
    email: 'a@a.com',
  },
  medicalHistory: {
    lvef: 33,
    nyhaClass: 'III',

    diseasesAndSymptoms: {
      cardiovascularDiseases: {
        coronaryHeartDisease: true,
        valvularHeartDisease: true,
        typeIDiabetesMellitus: true,
        typeIIDiabetesMellitus: true,
        chronicKidneyDisease: true,
        atrialFibrillation: true,
        hypertension: true,
        peripheralArteryDisease: true,
        priorMyocardialInfarction: true,
        aorticPlaque: true,
        stroke: true,
      },

      nonCardiovascularDiseases: {
        copd: true,
        hypothyroidism: true,
        anaemia: true,
        ironDeficiency: true,
        obesity: true,
        sleepDyspnea: true,
        smoking: true,
      },

      // symptoms: {
      //   // noSymptoms: true,
      //   increasedFatigue: true,
      //   increasedBreathlessness: true,
      //   swellingHandsFeet: true,
      //   swellingLipsEyes: true,
      //   dryCough: true,
      //   chestTightness: true,
      // },
    },

    allergies: '121212',

    vitalsReference: {
      // vitalsReferenceDate:
      vitals: {
        bloodUreaNitrogen: 123,
        bp: { sbp: 133, dbp: 122 },
        hr: 123,
        weight: 123,
        bnp: 123,
        glucose: 123,
        ntprobnp: 123,
        scr: 12,
        hemoglobin: 123,
        height: { feet: 3, inches: 11 },
        egfr: 123,
        hematocrit: 23,
        bmi: 123,
        potassium: 3,
        sodium: 123,
        chloride: 123,
        calcium: 13,
        bicarbonate: 11,
        hba1c: 123,
      },
    },
  },
  physician: {},
};
