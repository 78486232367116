/** @format */

import { Button, TableRow } from '@mui/material';
import React from 'react';
import { CustomTableColumnType } from '../../../types/CustomTable.types';
import { _renderTableBodyColumn } from './CustomTable';
// import Popover from '@mui/material/Popover';

interface CustomTableRowProps {
  getSelectedRow?: (rowData: any) => boolean;
  rowData: any;
  dataRowHover?: boolean;
  index: number;
  columns: CustomTableColumnType[];
  handleClickDataRow?: (rowData: any, index: number) => void;
  children?: JSX.Element;
}

export const CustomTableRow: React.FC<CustomTableRowProps> = (props) => {
  const updatedCols = props.columns.filter((e) => e.id !== 'action');

  const actions = props.columns.find((e) => e.id === 'action')?.cellFormatter;

  //   let actionItem: (columnValue: any, index: number) => Element | null;

  //   if (actions && actions.) {
  //     actionItem = actions.cellFormatter
  //   }

  return (
    <>
      <TableRow
        selected={props.getSelectedRow?.(props.rowData)}
        sx={{
          position: 'relative',
          '&:hover': {
            '.table-actions': {
              visibility: 'visible',
            },
          },
        }}
        key={props.index}
        className='table-body-row'
        onClick={(e) => {
          props.handleClickDataRow?.(props.rowData, props.index);
        }}>
        {updatedCols.map((column: any) => _renderTableBodyColumn(props.rowData, column, props.index))}

        {actions && (
          <Button className='table-actions' sx={{ visibility: 'hidden', '&:hover': { backgroundColor: '#f5f5f5' }, position: 'absolute', right: 0, top: '5%', backgroundColor: '#ffffff' }}>
            {actions(props.rowData, props.index)}
          </Button>
        )}
      </TableRow>
    </>
  );
};
