/** @format */

import { PatientRuleVitalsOptionsType } from '../types/PatientRule.types';

export const PATIENT_RULE_ACTION_KIND_MEDICATION_CHANGE = 'MEDICATION_CHANGE';
export const PATIENT_RULE_ACTION_KIND_ALERTS = 'ALERTS';
export const PATIENT_RULE_ACTION_KIND_THRESHOLD = 'THRESHOLD';

export const PATIENT_RULE_DEFAULT_RULE_ID = 'NEW_RULE';

export const PATIENT_RULE_SUGESSTION_TYPE_IMMEDIATE = 'IMMEDIATE';
export const PATIENT_RULE_SUGESSTION_TYPE_ON_REVIEW = 'ON_REVIEW';

export const PatientRuleSuggestionTypeOptions = [
  {
    label: 'Immediate',
    value: PATIENT_RULE_SUGESSTION_TYPE_IMMEDIATE,
  },
  {
    label: 'On review',
    value: PATIENT_RULE_SUGESSTION_TYPE_ON_REVIEW,
  },
];

export const PatientRuleConditionRelationOptions = [
  {
    label: 'AND',
    value: 'all',
  },
  {
    label: 'OR',
    value: 'any',
  },
];

export const PatientRuleConditionOperatorOptions = [
  {
    label: '<=',
    value: 'lessThanInclusive',
  },
  {
    label: '<',
    value: 'lessThan',
  },
  {
    label: '>=',
    value: 'greaterThanInclusive',
  },
  {
    label: '>',
    value: 'greaterThan',
  },
  // {
  //   label: '>= any',
  //   value: 'anyGreaterThanInclusive',
  // },
  // {
  //   label: 'any <',
  //   value: 'anyLessThan',
  // },
  // {
  //   label: 'all <',
  //   value: 'allLessThan',
  // },
  // {
  //   label: 'all >',
  //   value: 'allGreaterThan',
  // },
  // {
  //   label: 'any >',
  //   value: 'anyGreaterThan',
  // },
  {
    label: 'Between',
    value: 'between',
  },
];

export const PatientRuleConditionSymptomsOperatorOptions = [
  {
    label: 'Include',
    value: 'contains',
  },
  {
    label: 'Do not include',
    value: 'doesNotContain',
  },
];

export const PatientRuleConditionVitalsOptions: PatientRuleVitalsOptionsType[] = [
  {
    label: 'SBP',
    value: 'systolicBloodPressure',
    minRange: 50,
    maxRange: 300,
    defaultRange: [90, 180],
    defaultValue: 90,
    unit: 'mmHg',
  },
  {
    label: 'Pulse Rate',
    value: 'heartRate',
    unit: 'bpm',
    minRange: 30,
    maxRange: 200,
    defaultRange: [55, 140],
    defaultValue: 55,
  },
  {
    label: 'eGFR',
    value: 'eGFR',
    unit: 'mL/min/1.73 m^2',
    minRange: 10,
    maxRange: 250,
    defaultRange: [45, 250],
    defaultValue: 45,
  },
  {
    label: 'K',
    value: 'serumPotassium',
    unit: 'mEq/L',
    minRange: 1,
    maxRange: 10,
    defaultRange: [3, 5.5],
    defaultValue: 3,
    step: 0.1,
  },
  {
    label: 'sCr',
    value: 'serumCreatinine',
    minRange: 0,
    unit: 'mg/dL',
    maxRange: 4,
    defaultRange: [1, 3],
    defaultValue: 1,
    step: 0.1,
  },
  // {
  //   label: 'Weight Change',
  //   value: 'deltaPercent_weight',
  //   minRange: 0,
  //   maxRange: 10,
  //   defaultRange: [2, 5],
  //   defaultValue: 2,
  //   step: 0.1,
  // },
  {
    label: 'Delta sCr',
    value: 'deltaPercent_serumCreatinine',
    unit: '%',
    minRange: 10,
    maxRange: 100,
    defaultRange: [40, 80],
    defaultValue: 50,
  },
  {
    label: 'Delta eGFR',
    value: 'deltaPercent_eGFR',
    unit: '%',
    minRange: -100,
    maxRange: 100,
    defaultRange: [40, 80],
    defaultValue: -50,
  },
  {
    label: 'Symptoms',
    value: 'symptoms',
  },
  {
    label: 'Fluid Intake Limit',
    value: 'fluidIntake',
    unit: 'ml',
    minRange: 0,
    maxRange: 5,
    defaultValue: 1.5,
    step: 0.25,
  },
];
