/** @format */

import React from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';

import { Box, Breakpoint, Button, CircularProgress, DialogActions, DialogContent, Grid, IconButton, ListItem, Typography } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

import FormFields from '../form-fields/FormFields';
import { TEXT_INPUT_COMPONENT } from '../../../constants/PatientEnrollment';
import _ from 'underscore';

// Component props.
interface ConfirmationDialogProps {
  title: string;
  description: string | string[];
  isOpen: boolean;
  onClose: () => void;
  onClickConfirm?: (notes: string) => void;
  isLoading?: boolean;
  disabled?: boolean;

  inputLabel?: string;

  additionalInputProps?: any;
  maxWidthDialog?: Breakpoint | false;

  customValidate?: (value: any) => string | null;
}

const ConfirmationDialogWithNotesComponent: React.FC<ConfirmationDialogProps> = ({
  title,
  description,
  isOpen,
  onClose,
  onClickConfirm,
  isLoading,
  disabled,
  inputLabel = 'Notes',
  additionalInputProps = {},
  maxWidthDialog = 'sm',
  customValidate = () => null,
}) => {
  const [notes, setNotes] = React.useState<string>('');
  const [notesError, setNotesError] = React.useState<string>('');

  const [isFormSubmitted, setIsFormSubmitted] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (isFormSubmitted) {
      getNotesError();
    }
  }, [isFormSubmitted, notes]);

  const getNotesError = () => {
    if (_.isEmpty(notes)) {
      setNotesError('Notes cannot be empty');
      return false;
    }

    if (!_.isEmpty(notes) && notes?.length > 200) {
      setNotesError('Notes should not exceed 200 char');
      return false;
    }

    const errorValue = customValidate(notes);

    if (errorValue) {
      setNotesError(errorValue);
      return false;
    }

    setNotesError('');
    return true;
  };

  const handleClickSubmit = () => {
    setIsFormSubmitted(true);
    const isNoError = getNotesError();

    if (!isNoError) {
      return;
    }

    onClickConfirm?.(notes);
  };

  const handleChange = (event: any) => {
    const value = event.target.value;
    setNotes(value);
    const errorValue = customValidate(value);

    if (errorValue) {
      setNotesError(errorValue);
    } else {
      setNotesError('');
    }
  };

  return (
    <Dialog maxWidth={maxWidthDialog} fullWidth onClose={onClose} open={isOpen}>
      <DialogTitle>
        {title && (
          <Grid container alignItems='center' justifyContent='space-between'>
            <Typography variant='fontSemiBold18' color='#33425B'>
              {title}
            </Typography>
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
        )}
      </DialogTitle>
      <DialogContent>
        {Array.isArray(description) &&
          description?.map((e) => (
            <Box key={e} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ListItem>{e}</ListItem>
            </Box>
          ))}
        {!Array.isArray(description) && <Typography sx={{ marginBottom: '20px' }}>{description}</Typography>}

        <Box component='div' mt={2}>
          <FormFields
            id='notes'
            componentType={TEXT_INPUT_COMPONENT}
            label={inputLabel}
            fullWidth
            input={{
              value: notes,
              onChange: handleChange,
              required: true,
              errorMessage: notesError,

              ...additionalInputProps,
            }}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color='primary' variant='contained' type='reset' onClick={handleClickSubmit} disabled={isLoading || disabled}>
          {isLoading && <CircularProgress sx={{ mr: 1 }} />}
          Confirm
        </Button>
        <Button color='error' variant='outlined' onClick={onClose} sx={{ marginRight: '10px' }}>
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationDialogWithNotesComponent.defaultProps = {
  inputLabel: 'Notes',
};

export default ConfirmationDialogWithNotesComponent;
