/** @format */

import * as React from 'react';
import validator from '@rjsf/validator-ajv8';
// Components.
import Form from '@rjsf/mui';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';

import type { RJSFSchema } from '@rjsf/utils';

import { GridInputField, TitleFieldTemplate, FieldErrorTemplate, GridSelectInputField } from '../common/react-json-schema';
import { Typography } from '@mui/material';
import { PatientDeactivationReasonType } from '../../types/Patients.types';

interface PatientDeactivationReasonModalProps {
  isOpen: boolean;
  onClose: () => void;
  onClickConfirm: (data: PatientDeactivationReasonType) => void;
  isLoading?: boolean;
  disabled?: boolean;
}

const PatientDeactivationReasonModal: React.FunctionComponent<PatientDeactivationReasonModalProps> = (props) => {
  const formDataRef = React.useRef({});

  const [formData, setFormData] = React.useState({});

  const onSubmit = (event: any, data: any) => {
    const formData = data.formData;
    setFormData(formData);
    props.onClickConfirm(formData);
  };

  const customValidate = (formData: any, errors: any) => {
    return errors;
  };

  const transformErrors = (errors: any[]) => {
    return errors.map((error) => {
      const path = error.property.split('.');
      const fieldName = path[path?.length - 1] || '';

      const formatFieldName: string = fieldName.replace(/([A-Z])/g, ' $1').replace(/^./, (str: string) => str.toUpperCase());

      if (error.name === 'type') {
        error.message = `${formatFieldName} cannot be empty`;
      }

      if (error.name === 'pattern') {
        error.message = `Please enter valid ${formatFieldName}`;
      }

      if (error.name === 'minLength') {
        error.message = `${formatFieldName} should be minimum ${error?.params?.limit} char`;
      }

      if (error.name === 'maxLength') {
        error.message = `${formatFieldName} should not exceed ${error?.params?.limit} char`;
      }

      if (error.name === 'maximum') {
        error.message = `${formatFieldName} should be less or equal to ${error?.params?.limit}`;
      }

      if (error.name === 'minimum') {
        error.message = `${formatFieldName} should be  greater or equal to ${error?.params?.limit}`;
      }

      return error;
    });
  };

  const uiSchema = {
    'ui:maxErrors': 0,

    reasonForDeactivation: {
      'ui:widget': GridSelectInputField,
      'ui:grid': {
        xs: 12,
      },
    },
    notes: {
      'ui:widget': GridInputField,
      'ui:grid': {
        xs: 12,
      },
    },

    cardiacArrest: {
      'ui:widget': GridSelectInputField,
      'ui:grid': {
        xs: 12,
      },
      // 'ui:required': true,
    },
  };

  const schema: RJSFSchema = {
    title: '',
    type: 'object',
    required: ['reasonForDeactivation'],
    properties: {
      reasonForDeactivation: {
        type: 'string',
        title: 'Reason for Deactivation',
        enum: ['Subscription/Trial Ended', 'Patient deceased', 'Patient Dropped-out', 'Others'],
        default: 'Subscription/Trial Ended',
      },
    },
    dependencies: {
      reasonForDeactivation: {
        oneOf: [
          {
            properties: {
              reasonForDeactivation: { enum: ['Subscription/Trial Ended'] },
              notes: { type: 'string', title: 'Notes', maxLength: 50, minLength: 2 },
            },
          },
          {
            properties: {
              reasonForDeactivation: { enum: ['Patient Dropped-out'] },
              notes: { type: 'string', title: 'Notes', maxLength: 50, minLength: 2 },
            },
          },
          {
            properties: {
              reasonForDeactivation: { enum: ['Patient deceased'] },
              cardiacArrest: { type: 'string', title: 'Cardiac Death', enum: ['Yes', 'No'], default: 'Yes' },
              notes: { type: 'string', title: 'Notes', maxLength: 50, minLength: 2 },
            },
            required: ['cardiacArrest'],
            dependencies: {
              cardiacArrest: {
                oneOf: [
                  {
                    properties: {
                      cardiacArrest: { enum: ['Yes'] },
                    },
                  },
                  {
                    properties: {
                      cardiacArrest: { enum: ['No'] },
                    },
                  },
                ],
              },
            },
          },
          {
            properties: {
              reasonForDeactivation: { enum: ['Others'] },
              notes: { type: 'string', title: 'Notes', maxLength: 50, minLength: 2 },
            },
            required: ['notes'],
          },
        ],
      },
    },
  };

  return (
    <Dialog fullWidth maxWidth='sm' onClose={props.onClose} open={props.isOpen}>
      <DialogTitle variant='fontSemiBold18'>Are you sure you want to deactivate this patient?</DialogTitle>

      <DialogContent sx={{ '& .form-group': { '& .form-error-field': { minHeight: '10px' } } }}>
        <Typography component='p' sx={{ pt: 2, pb: 3 }}>
          <Typography component='p' sx={{ p: 1.5 }}>
            Once deactivated, the patient profile cannot be reactivated - in such a situation, the patient can be re-enrolled if required.
          </Typography>
          <Typography component='p' sx={{ p: 1.5 }}>
            Any tasks associated with the patient will be removed.
          </Typography>
          <Typography component='p' sx={{ p: 1.5 }}>
            Any future appointments will be cancelled.
          </Typography>
          <Typography component='p' sx={{ p: 1.5 }}>
            The patient will be automatically logged out from the Mobile App and will no longer have access to it.
          </Typography>
        </Typography>

        <Form
          formData={formData}
          transformErrors={transformErrors}
          validator={validator}
          customValidate={customValidate}
          onSubmit={(data: any, event: any) => onSubmit(event, data)}
          templates={{ TitleFieldTemplate, FieldErrorTemplate }}
          uiSchema={uiSchema}
          schema={schema}
          disabled={props.disabled || props.isLoading}
          showErrorList={false}
          onChange={(data: any) => {
            formDataRef.current = data.formData;
          }}>
          <DialogActions sx={{ pt: 2 }}>
            <Button color='primary' variant='contained' type='submit' disabled={props.isLoading || props.disabled}>
              Confirm
            </Button>
            <Button color='error' type='reset' variant='outlined' onClick={props.onClose} sx={{ marginRight: '10px' }}>
              Cancel
            </Button>
          </DialogActions>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default PatientDeactivationReasonModal;
