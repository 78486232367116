/** @format */

import React from 'react';
import PhoneInput from 'react-phone-input-2';

import { useTheme } from '@mui/material/styles';
import FormLabel from '@mui/material/FormLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';

import { useAppSelector } from '../../../app/hooks';

// SCSS.
import './PhoneNumberField.scss';

export const PhoneNumberInputField = (props: any) => {
  const appTheme = useTheme();
  const userRegion = useAppSelector((state) => state.userRegion);

  const required = props.uiSchema['ui:required'] || false;

  return (
    <FormControl id={props.id} required={props.required} disabled={props.disabled}>
      <FormLabel sx={{ pb: '8px', display: 'flex', alignItems: 'center' }}>
        <Typography variant='fontSemiBold14' color={appTheme.palette.customColor.pageTitle} component='div' sx={{ marginLeft: '5px', display: 'flex', alignItems: 'center' }}>
          {props.label}
        </Typography>

        {required && (
          <Typography color='error' sx={{ marginLeft: '3px' }}>
            *
          </Typography>
        )}
      </FormLabel>

      <PhoneInput
        country={userRegion.countryCode?.toLowerCase()}
        value={props.value}
        onChange={(phone) => {
          props.onChange(phone ? `${phone}` : '');
        }}
        disabled={props.disabled}
        inputProps={{
          tabIndex: props.uiSchema?.['ui:tabIndex'],
        }}
        inputClass='phone-input-input'
        containerClass='phone-input-container'
        inputStyle={{
          width: '100%',
          padding: '28.5px 28.5px 28.5px 45px',
          border: 'none',
          borderRadius: '5px',
          backgroundColor: props.disabled ? '#F4F4F4' : '#FFFF',
          color: props.disabled ? '#9b9c9a' : '#344560',
        }}
      />
    </FormControl>
  );
};
