/** @format */

import * as React from 'react';
import * as _ from 'underscore';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Complaints from './Complaints';
import CustomTable from '../common/custom-table/CustomTable';
import { CustomObservations } from '../common/custom-observations/CustomObservations';

import { CustomTableInputBaseCell } from './CustomTableComponent';
import { CustomTableColumnType } from '../../types/CustomTable.types';

import { PrescriptionGeneratorContext } from './PrescriptionGeneratorContext';

interface ObservationsAssessmentsProps {}

const ObservationsAssessments: React.FunctionComponent<ObservationsAssessmentsProps> = () => {
  const {
    vitals,
    vitalsErrors,
    complaints,
    diagnosis,
    examinations,
    handleChangeVitals,
    handleChangeComplaints,
    handleChangeExaminations,
    handleChangeDiagnosis,

    handleTabSwitchAndSaveObservationToContext,
  } = React.useContext(PrescriptionGeneratorContext);

  const vitalsColumnDefs: CustomTableColumnType[] = [
    {
      id: 'temperature',
      label: (
        <Typography variant='fontReg14' sx={{ color: '#5C6A90' }}>
          Temperature (&deg;F)
        </Typography>
      ),
      minWidth: 150,
      width: '23%',
      cellFormatter: (data: any, rowIndex: number) => {
        return (
          <CustomTableInputBaseCell
            placeholder='Enter values'
            key={rowIndex}
            type='number'
            value={data?.bodyTemperature}
            onChange={(event: any) => handleChangeVitals(event.target.value, 'bodyTemperature')}
            error={!_.isEmpty(vitalsErrors?.bodyTemperature)}
          />
        );
      },
    },
    {
      id: 'pulse-rate',
      label: (
        <Typography variant='fontReg14' sx={{ color: '#5C6A90' }}>
          Pulse Rate (bpm)
        </Typography>
      ),
      minWidth: 150,
      width: '23%',
      cellFormatter: (data: any, rowIndex: number) => {
        return (
          <CustomTableInputBaseCell
            placeholder='Enter values'
            key={rowIndex}
            type='number'
            value={data?.pulseRate}
            onChange={(event: any) => handleChangeVitals(event.target.value, 'pulseRate')}
            error={!_.isEmpty(vitalsErrors?.pulseRate)}
          />
        );
      },
    },
    {
      id: 'blood-pressure',
      label: (
        <Typography variant='fontReg14' sx={{ color: '#5C6A90' }}>
          Blood Pressure (mmHg)
        </Typography>
      ),
      minWidth: 150,
      width: '23%',
      cellFormatter: (data: any, rowIndex: number) => {
        const handleChangeVitalsBloodPressure = (value: any, variable: string) => {
          const rowNodeValue = data;

          if (!rowNodeValue.bloodPressure) {
            rowNodeValue.bloodPressure = {};
          }

          rowNodeValue.bloodPressure[variable] = value;
          handleChangeVitals(data.bloodPressure, 'bloodPressure');
        };

        return (
          <Grid container alignItems='center'>
            <Grid item xs>
              <CustomTableInputBaseCell
                placeholder='Enter SBP'
                key={rowIndex}
                type='number'
                value={data?.bloodPressure?.sbp}
                onChange={(event: any) => handleChangeVitalsBloodPressure(event.target.value, 'sbp')}
                error={!_.isEmpty(vitalsErrors?.bloodPressure?.sbp)}
              />
            </Grid>
            <Grid item xs='auto'>
              /
            </Grid>
            <Grid item xs>
              <CustomTableInputBaseCell
                placeholder='Enter DBP'
                key={rowIndex}
                type='number'
                value={data?.bloodPressure?.dbp}
                onChange={(event: any) => handleChangeVitalsBloodPressure(event.target.value, 'dbp')}
                error={!_.isEmpty(vitalsErrors?.bloodPressure?.dbp)}
              />
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'respiration-rate',
      label: (
        <Typography variant='fontReg14' sx={{ color: '#5C6A90' }}>
          Respiration Rate (Breaths per minute)
        </Typography>
      ),
      minWidth: 200,
      width: '30%',
      cellFormatter: (data: any, rowIndex: number) => {
        return (
          <CustomTableInputBaseCell
            placeholder='Enter values'
            key={rowIndex}
            type='number'
            value={data?.respirationRate}
            onChange={(event: any) => handleChangeVitals(event.target.value, 'respirationRate')}
            error={!_.isEmpty(vitalsErrors?.respirationRate)}
          />
        );
      },
    },
    {
      id: 'respiration-rate',
      label: (
        <Typography variant='fontReg14' sx={{ color: '#5C6A90' }}>
          Sp02 (%)
        </Typography>
      ),
      minWidth: 200,
      width: '30%',
      cellFormatter: (data: any, rowIndex: number) => {
        return (
          <CustomTableInputBaseCell
            placeholder='Enter values'
            key={rowIndex}
            type='number'
            value={data?.sp02}
            onChange={(event: any) => handleChangeVitals(event.target.value, 'sp02')}
            error={!_.isEmpty(vitalsErrors?.sp02)}
          />
        );
      },
    },
  ];

  return (
    <Grid container gap={3}>
      <Complaints complaints={complaints} contextStateName='COMPLAINTS' handleChangeComplaints={handleChangeComplaints} saveToContext={handleTabSwitchAndSaveObservationToContext} />

      <Box
        sx={{
          width: '100%',
          '& .MuiGrid-root': {
            '& #vitals-list': {
              '& #custom-ag-table-container': {
                height: `${48 + 40 + 2.5}px !important`,
              },
            },
          },
        }}>
        <Typography variant='fontSemiBold14' color='#3E4685' sx={{ p: 3 / 8 }}>
          Vitals
        </Typography>
        <CustomTable count={[vitals].length} columns={vitalsColumnDefs} data={[vitals]} noDataMessage='No Vitals' dataRowHover={false} stickyHeader tableSize='small' />
      </Box>

      <CustomObservations
        observationActionTitle='Add Examinations'
        observationTitle='Examinations'
        options={[
          { label: 'Electrocardiogram', value: 'ECG' },
          { label: 'Cardiac CT', value: 'CT' },
          { label: 'Cardiac MRI', value: 'MRI' },
          { label: 'Stress Test', value: 'STRESS_TEST' },
        ]}
        handleChangeObservations={handleChangeExaminations}
        inputs={[]}
        existingObservations={examinations}
        contextStateName={'EXAMINATIONS'}
        saveToContext={handleTabSwitchAndSaveObservationToContext}
        showHoverEffectBorderOnInputField
      />

      <CustomObservations
        observationActionTitle='Add Diagnosis'
        observationTitle='Diagnosis'
        options={[]}
        handleChangeObservations={handleChangeDiagnosis}
        inputs={[]}
        existingObservations={diagnosis}
        contextStateName='DIAGNOSIS'
        saveToContext={handleTabSwitchAndSaveObservationToContext}
        showHoverEffectBorderOnInputField
      />
    </Grid>
  );
};

export default ObservationsAssessments;
