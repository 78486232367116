/** @format */

import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import { SelectedPdfUrlType } from '../../pages/patient-profile/PatientProfileContext';
import { DialogTitle, IconButton, Typography, Grid } from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

interface PDFViewerProps {
  handleClose: () => void;
  url: SelectedPdfUrlType | null;

  isOpen: boolean;
}

const PDFViewer: React.FunctionComponent<PDFViewerProps> = (props) => {
  const isPDF = typeof props?.url === 'string' || props?.url?.fileType?.toLowerCase()?.includes('application/pdf');
  const isJPEG = ['image/png', 'image/jpeg']?.includes(props?.url?.fileType?.toLowerCase()!) || props?.url?.fileType?.toLowerCase()?.endsWith('.image/jpeg');

  const pdfUrl = typeof props?.url === 'string' ? props?.url : props?.url?.pdfUrl;

  console.log('asdckjnadsckjdsnsdkc', props?.url);

  return (
    <Dialog maxWidth='md' fullWidth open={props.isOpen} onClose={props.handleClose}>
      <DialogTitle>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Typography variant='fontSemiBold16' color='#3e4685'>
            View Attachment
          </Typography>
          <IconButton onClick={props.handleClose}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      {isPDF ? (
        <iframe width='100%' height='800px' src={pdfUrl} title={pdfUrl}></iframe>
      ) : isJPEG ? (
        <img
          src={pdfUrl}
          alt='JPEG Viewer'
          style={{
            maxWidth: '100%',
            maxHeight: '800px',
            objectFit: 'contain',
            overflow: 'hidden',
          }}
        />
      ) : null}
    </Dialog>
  );
};

export default PDFViewer;
