/** @format */

import { List, ListItem, Typography } from '@mui/material';
import { PatientTimeLineType } from '../../../types/Timeline.types';
import { DateTime } from 'luxon';
import * as React from 'react';
import PDFViewer from '../../common/PDFViewer';
import { fetchPatientAttachmentsPresignedUrl } from '../../../service/patient-attachments';
import { SelectedPdfUrlType } from '../../../pages/patient-profile/PatientProfileContext';

interface TimelineLabReportItemProps {
  data: PatientTimeLineType;
  lastTimelineItem: boolean;

  organiationUsers: Array<{ label: string; value: string }>;
}

const TimelineHospitalisationItem: React.FunctionComponent<TimelineLabReportItemProps> = (props: any) => {
  console.log('props==========>', props);
  const [isOpenPrescriptionModal, setIsOpenPrescriptionModal] = React.useState<boolean>(false);
  const [pdfUrl, setpdfUrl] = React.useState<SelectedPdfUrlType | null>(null);

  const hospitalisationDate = props.data.medicalEventPayload?.patientLabReports?.payload?.hospitalizationDate;
  const disChargDate = props.data.medicalEventPayload?.patientLabReports?.payload?.dischargeDate;
  const cardiacAdmit = props.data.medicalEventPayload?.patientLabReports?.payload?.cardiacAdmit;

  const handleOpenpdf = async (items: any) => {
    try {
      const res = await fetchPatientAttachmentsPresignedUrl(props.data?.patientSourceId, items.fileId);
      setpdfUrl(res);
    } catch (err) {
      console.error('errr', err);
    }
    setIsOpenPrescriptionModal((prev) => !prev);
  };

  const handleClosepdf = () => {
    setIsOpenPrescriptionModal((prev) => !prev);
  };

  const checkcardiacAdmit = () => {
    if (cardiacAdmit) {
      return (
        <>
          <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
            <Typography variant='fontSemiBold12' color='#3E4685'>
              Cardiac admission
            </Typography>
          </ListItem>
          <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
            <Typography variant='fontSemiBold12' color='#3E4685'>
              Yes
            </Typography>
          </ListItem>
        </>
      );
    }
    return (
      <>
        <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
          <Typography variant='fontSemiBold12' color='#3E4685'>
            Cardiac admission
          </Typography>
        </ListItem>
        <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
          <Typography variant='fontSemiBold12' color='#3E4685'>
            No
          </Typography>
        </ListItem>
      </>
    );
  };

  return (
    <>
      {isOpenPrescriptionModal && <PDFViewer isOpen={isOpenPrescriptionModal} url={pdfUrl} handleClose={handleClosepdf} />}
      {hospitalisationDate && (
        <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
          <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
            <Typography variant='fontSemiBold12' color='#3E4685'>
              Hospitalized on
            </Typography>
          </ListItem>

          <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
            <Typography variant='fontReg12' color='#3E4685'>
              {DateTime.fromISO(hospitalisationDate).toFormat('MMMM dd, yyyy')}
            </Typography>
          </ListItem>
        </List>
      )}
      {disChargDate && (
        <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
          <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
            <Typography variant='fontSemiBold12' color='#3E4685'>
              Discharged on
            </Typography>
          </ListItem>
          {disChargDate && (
            <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
              <Typography variant='fontReg12' color='#3E4685'>
                {DateTime.fromISO(disChargDate).toFormat('MMMM dd, yyyy')}
              </Typography>
            </ListItem>
          )}
        </List>
      )}

      <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
        {checkcardiacAdmit()}
      </List>

      {props.data.medicalEventPayload?.patientLabReports?.summary && (
        <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
          <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
            <Typography variant='fontSemiBold12' color='#3E4685'>
              Notes
            </Typography>
          </ListItem>
          <ListItem sx={{ borderBottom: '1px solid #D5D5D6' }}>
            <Typography variant='fontReg12' color='#3E4685'>
              {props.data.medicalEventPayload?.patientLabReports?.summary}
            </Typography>
          </ListItem>
        </List>
      )}

      <List disablePadding sx={{ background: '#F5F8FC', border: '1px solid #D5D5D6', borderRadius: '5px', mt: 1 }}>
        {props.data.medicalEventPayload?.patientLabReports?.attachments?.map((items: any) => {
          return (
            <ListItem sx={{ borderBottom: '1px solid #D5D5D6', cursor: 'pointer' }}>
              <Typography variant='fontSemiBold12' color='#3E4685' onClick={() => handleOpenpdf(items)}>
                {items?.fileName}
              </Typography>
            </ListItem>
          );
        })}
      </List>
    </>
  );
};

export default TimelineHospitalisationItem;
