/** @format */

import * as React from 'react';
import { DateTime } from 'luxon';
import { Box, CardContent, Checkbox, FormControlLabel, Grid, Input, Stack, Typography, Chip } from '@mui/material';

import AddIcon from '@mui/icons-material/Add';

import PDFViewer from '../PDFViewer';
import CustomCard from '../custom-card';

import { FieldTemplateProps } from '@rjsf/utils';

import { V2PatientAttachmentPayloadAttachmnetType } from '../../../types/PatientAttachments.types';

import './CustomTemplateHospitalizationDischarge.scss';
import FormFields from '../form-fields/FormFields';
import { TEXT_INPUT_COMPONENT } from '../../../constants/PatientEnrollment';
import { SelectedPdfUrlType } from '../../../pages/patient-profile/PatientProfileContext';

type CustomTemplatePrevMedicationsProps = FieldTemplateProps;

const CustomTemplateHospitalizationDischarge: React.FC<CustomTemplatePrevMedicationsProps> = (props) => {
  const [selectedPdfUrl, setSelectedPdfUrl] = React.useState<SelectedPdfUrlType | null>(null);

  const [inputFile, setInputFiles] = React.useState<any>(null);

  const [errorMessage, setErrorMessage] = React.useState<string>('');

  const isEditMode: boolean = props.uiSchema?.['ui:fields']?.disabled;

  const handleClickEditCard = props.uiSchema?.['ui:fields']?.handleClick;

  const onChange = props.onChange;

  React.useEffect(() => {
    if (
      props.formData?.payload?.dischargeDate &&
      props.formData?.payload?.hospitalizationDate &&
      new Date(props.formData?.payload?.dischargeDate).getTime() <= new Date(props.formData?.payload?.hospitalizationDate).getTime()
    ) {
      setErrorMessage('Hospitalisation Date should less than Discharge date');
    } else {
      setErrorMessage('');
    }
  }, [props.formData?.payload]);

  const handleFileInputChange = React.useCallback(
    async (event: any) => {
      const file = event.target.files[0];

      if ((props.formData?.attachments?.length || 0) < 15) {
        const attachmentId: any = await props.uiSchema?.['ui:fields']?.handleSubmitAttachments(file, 'HOSTPITALISATION');

        const newFiles = {
          fileName: file.name,
          fileId: attachmentId.attachmentId,
          type: file.type,
          file,
        };

        props.onChange({ ...props.formData, attachments: (props.formData?.attachments || []).concat(newFiles) });
      }

      setInputFiles('');
      setTimeout(() => {
        setInputFiles(null);
      }, 100);
    },
    [onChange, props.formData],
  );

  const handleChangeDateforDischarge = React.useCallback(
    (event: any) => {
      const updatedDate = DateTime.fromJSDate(new Date(event.target.value)).toISO() || '';
      props.onChange({ ...props.formData, payload: { ...props.formData.payload, dischargeDate: updatedDate } });
    },
    [onChange, props.formData],
  );

  const handleChangeDateforHospitalisation = React.useCallback(
    (event: any) => {
      const updatedDate = DateTime.fromJSDate(new Date(event.target.value)).toISO() || '';
      props.onChange({ ...props.formData, payload: { ...props.formData.payload, hospitalizationDate: updatedDate } });
    },
    [onChange, props.formData],
  );

  const handleChangeCardicAdmin = React.useCallback(
    (event: any) => {
      props.onChange({ ...props.formData, payload: { ...props.formData.payload, cardiacAdmit: event.target.checked } });
    },
    [onChange, props.formData],
  );

  const handleDeleteSelectedPdf = React.useCallback(
    (e: any) => {
      const updatedPdfList = props.formData.attachments.filter((file: any) => file !== e);
      props.onChange({ ...props.formData, attachments: updatedPdfList });
    },
    [onChange, props.formData],
  );

  const handleReportSummary = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      props.onChange({ ...props.formData, summary: event.target.value });
    },
    [onChange, props.formData],
  );

  const handleClosePdfReportPreview = () => {
    setSelectedPdfUrl(null); // Close the PDF viewer
  };

  const handleOpenPdf = (file: any) => {
    if (file.fileUrl) {
      setSelectedPdfUrl({ pdfUrl: file?.fileUrl, fileType: file.type || file.file.type });
    } else {
      const pdfDataUrl = URL.createObjectURL(file.file);
      setSelectedPdfUrl({ pdfUrl: pdfDataUrl, fileType: file.file.type });
    }
  };

  const renderAttachmentCalender = () => {
    return (
      <Stack spacing={2} direction='row' marginTop='20px'>
        <Box>
          <FormFields
            id='Hospitalisation-date'
            componentType={TEXT_INPUT_COMPONENT}
            label='Hospitalization date'
            width='220px'
            input={{
              value: DateTime.fromISO(props.formData?.payload?.hospitalizationDate).toFormat('yyyy-MM-dd') || '',
              onChange: handleChangeDateforHospitalisation,
              type: 'date',
              disabled: isEditMode,
              errorMessage,
            }}
          />
        </Box>
        <Box>
          <FormFields
            id='currentDate'
            componentType={TEXT_INPUT_COMPONENT}
            label='Discharge date'
            width='220px'
            input={{
              value: DateTime.fromISO(props.formData?.payload?.dischargeDate).toFormat('yyyy-MM-dd') || '',
              onChange: handleChangeDateforDischarge,
              type: 'date',
              disabled: isEditMode,
              errorMessage,
            }}
          />
        </Box>
      </Stack>
    );
  };

  return (
    <Box>
      <CustomCard title='Recent Hospitalization / Discharge data' editMode={isEditMode} handleClickEditCard={handleClickEditCard}>
        <CardContent>
          <Grid container gap='11px'>
            <Grid item>
              <FormControlLabel control={<Checkbox />} label='Cardiac Admit' disabled={isEditMode} checked={props.formData?.payload?.cardiacAdmit} onChange={handleChangeCardicAdmin} />
            </Grid>
            <Grid item>
              <Input
                placeholder={'Enter notes if any'}
                disableUnderline={true}
                onChange={handleReportSummary}
                value={props.formData?.summary}
                disabled={isEditMode}
                sx={{
                  border: '1px solid #9BB4CC',
                  width: '422px',
                  borderRadius: '5px',
                  height: '32px',
                  padding: '0 10px',
                  '.MuiInput-input': {
                    padding: 0,
                  },
                  '::placeholder': {
                    color: '#3E4685',
                  },
                  marginTop: '4px',
                }}
              />
            </Grid>
            <Grid item pt='3px'>
              <Box
                sx={{
                  border: '1px solid #9BB4CC',
                  borderRadius: '5px',
                  height: '32px',
                  padding: '0 10px',
                  paddingTop: '3px',
                }}>
                <label htmlFor='file-upload' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                  <AddIcon sx={{ color: 'grey', marginRight: '5px' }} />
                  <Typography color='#33425B'>Add Files</Typography>
                </label>
                <input value={inputFile} id='file-upload' disabled={isEditMode} type='file' accept='.pdf, .jpeg, .jpg' style={{ display: 'none' }} onChange={handleFileInputChange} />
              </Box>
            </Grid>
            <Grid item>
              <Stack spacing={2} direction='row' gap={0.2} pt={0.5}>
                {props.formData?.attachments &&
                  props.formData?.attachments?.map((item: V2PatientAttachmentPayloadAttachmnetType) => {
                    let additionalInfo = {};

                    if (!isEditMode) {
                      additionalInfo = {
                        onDelete: () => {
                          if (!isEditMode) {
                            handleDeleteSelectedPdf(item);
                          }
                        },
                      };
                    }

                    return <Chip label={item?.fileName} size='medium' {...additionalInfo} onClick={() => handleOpenPdf(item)} />;
                  })}
              </Stack>
            </Grid>
            <Grid container item>
              {renderAttachmentCalender()}
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              {selectedPdfUrl && <PDFViewer isOpen={Boolean(selectedPdfUrl)} handleClose={handleClosePdfReportPreview} url={selectedPdfUrl} />}
            </Box>
          </Grid>
        </CardContent>
      </CustomCard>
    </Box>
  );
};

export default CustomTemplateHospitalizationDischarge;
