/** @format */

import * as React from 'react';
import * as _ from 'underscore';
import { DateTime } from 'luxon';

import Select from 'react-select';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import { SelectChangeEvent } from '@mui/material/Select';
import CircularProgress from '@mui/material/CircularProgress';

import ZoomDeleteIcon from './CloseIcon';
import AddIcon from '@mui/icons-material/Add';

import LabReportItem from './LabReportItem';
import DateRangePicker from '../../common/date-range-picker/DateRangePicker';
import { ConfirmationDialog } from '../../common/confirmation-dialog/ConfirmationDialog';

import { V2PatientAttachmentPayloadAttachmnetType, V2PatientAttachmentReportsPayloadReportType, V2PatientAttachmentReportType } from '../../../types/PatientAttachments.types';

import { LAB_REPORT_TEST } from '../../common/charts/rca-resources/constants';
import { NEW_ATTACHMENT_REPORT_ID } from '../../../constants/PatientAttachments';

import { PatientProfileContext } from '../../../pages/patient-profile/PatientProfileContext';

import './AddReport.scss';
import { Checkbox, FormControlLabel, FormHelperText, Stack } from '@mui/material';
import { setToast } from '../../../reducers/toast-reducer/ToastReducer';
import { useAppDispatch } from '../../../app/hooks';

interface AddReportProps {
  attachmentReport: V2PatientAttachmentReportType;
  handleClickDeleteAttachmentReport: (item: V2PatientAttachmentReportType) => void;
  key: number;
  setAttachmnetReports: (item: any) => void;
  attachmentReports?: V2PatientAttachmentReportType | any;
  handleClickCancelAttachmentReport: (item: any) => void;
}

const AddReport: React.FunctionComponent<AddReportProps> = (props) => {
  const { patientAttachmentsReportsData, handleSubmitPatientAttachment, handleSubmitUpdateAttachment, handleSubmitPatientAttachments, isPatientAttachmentUploading, setSelectedPdfUrl } =
    React.useContext(PatientProfileContext);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);

  const [reportName, setReportName] = React.useState<string>(props.attachmentReports?.name || '');
  const [reportNameError, setReportNameError] = React.useState<string>(props.attachmentReports?.name || '');
  const [summaryError, setSummaryError] = React.useState<string>('');
  const [reportSummary, setReportSummary] = React.useState<string>(props.attachmentReports?.summary || '');
  const [checkAttachmentCount, setCheckAttachmentCount] = React.useState<boolean>(false);

  const [isOpenDeleteReport, setIsOpenDeleteReportModal] = React.useState<boolean>(false);

  const [addTestReactSelect, setAddTestReactSelect] = React.useState<{ label: string; value: string }[] | null>(null);

  const [selectedAttachmentReportTest, setSelectedAttachmentReportTest] = React.useState<string | null>(null);
  const [attachmentReportTests, setAttachmentReportTests] = React.useState<V2PatientAttachmentReportsPayloadReportType[]>(props.attachmentReports?.reports || []);

  const [attachmentReportFiles, setAttachmentReportFiles] = React.useState<V2PatientAttachmentPayloadAttachmnetType[]>(props.attachmentReports?.attachments || []);

  const [reportsDate, setReportsDate] = React.useState<string>(!_.isEmpty(props.attachmentReports?.reportDate) ? props.attachmentReports?.reportDate : DateTime.now().toISO());

  const [dischargeDate, setdischargeDate] = React.useState<any>('');
  const [hospitalizationDate, setHospitalisationDate] = React.useState<any>('');
  const [checkDateHospitalisationDisChargeDate, setcheckDateHospitalisationDisChargeDate] = React.useState<string | null>(null);
  const [cardiacAdmit, setCardiacAdmit] = React.useState<boolean>(false);

  const [inputFiles, setInputFiles] = React.useState<any>(null);

  const reduxDispatch = useAppDispatch();
  React.useEffect(() => {
    if (props.attachmentReport.attachmentReportId === NEW_ATTACHMENT_REPORT_ID) {
      setReportName('');
      setReportSummary('');
      setAddTestReactSelect(null);
      setAttachmentReportTests([]);
      setAttachmentReportFiles([]);
    }
  }, [props.attachmentReport]);
  const toggleEditBtn = () => {
    setIsEdit((prev) => !prev);
  };
  const handleClickCancelEdit = () => {
    props.handleClickCancelAttachmentReport(props.attachmentReport);

    setIsEdit(false);
  };
  const handleChangeDate = (date: string) => {
    const updatedDate = DateTime.fromJSDate(new Date(date)).toISO() || '';
    setReportsDate(updatedDate);
  };
  const handleChangeDateforHospitalisation = (date: string) => {
    const updatedDate = DateTime.fromJSDate(new Date(date)).toISO() || '';
    setHospitalisationDate(updatedDate);
  };
  const handleChangeDateforDischarge = (date: string) => {
    const updatedDate = DateTime.fromJSDate(new Date(date)).toISO() || '';
    setdischargeDate(updatedDate);
  };

  React.useEffect(() => {
    if (dischargeDate && hospitalizationDate && new Date(dischargeDate).getTime() <= new Date(hospitalizationDate).getTime()) {
      setcheckDateHospitalisationDisChargeDate('Hospitalisation Date should less than Discharge date');
    } else {
      setcheckDateHospitalisationDisChargeDate(null);
    }
  }, [hospitalizationDate, dischargeDate]);

  const handleClickActiveChip = (chipName: string) => {
    setSelectedAttachmentReportTest(chipName);
  };

  const handleCloseReportActive = () => {
    setSelectedAttachmentReportTest(null);
  };

  const handleSubmit = async () => {
    const checkId: any = patientAttachmentsReportsData.find((e: V2PatientAttachmentReportType) => e.id === props.attachmentReport.id);

    if (props.attachmentReport.type === 'HOSTPITALISATION') {
      setReportNameError('');
      if (!hospitalizationDate) {
        setcheckDateHospitalisationDisChargeDate('Hospitalisation date is required');
        return;
      }
      if (dischargeDate && hospitalizationDate && new Date(dischargeDate).getTime() <= new Date(hospitalizationDate).getTime()) {
        setcheckDateHospitalisationDisChargeDate('Hospitalisation Date should less than Discharge date');
        return;
      }
      setcheckDateHospitalisationDisChargeDate(null);
    } else if (_.isEmpty(reportName)) {
      setReportNameError('Report Name Cannot be Empty');
      return;
    } else {
      setReportNameError('');
    }
    if (checkId) {
      const transformedData = attachmentReportFiles.map((item) => {
        if (item.file) {
          return {
            fileName: item.file.name,
            fileId: item.attachmentId,
            type: item.file.type,
          };
        } else {
          return {
            fileName: item.fileName,
            fileId: item.fileId,
            type: item.type,
          };
        }
      });

      const payload: V2PatientAttachmentReportType = {
        reportId: checkId.reportId,
        name: reportName,
        summary: reportSummary,
        payload: {
          cardiacAdmit,
          dischargeDate,
          hospitalizationDate,
        },
        reports: attachmentReportTests,
        attachments: transformedData,
        type: props.attachmentReport.type,
        reportDate: reportsDate,
      };

      await handleSubmitUpdateAttachment(payload);
    } else {
      const transformedData = attachmentReportFiles.map((item) => ({
        fileName: item.file.name,
        fileId: item.attachmentId,
        type: item.file.type,
      }));

      const payload: V2PatientAttachmentReportType = {
        name: reportName,
        summary: reportSummary,
        reports: attachmentReportTests,
        payload: {
          cardiacAdmit,
          dischargeDate,
          hospitalizationDate,
        },
        attachments: transformedData,
        type: props.attachmentReport.type,
        reportDate: reportsDate,
      };

      const res: any = await handleSubmitPatientAttachment(payload);
      if (res?.message) {
        const filterItems = props.attachmentReports.filter((e: V2PatientAttachmentReportType) => e.id !== props.attachmentReport.id);
        props.setAttachmnetReports(filterItems);
      }
    }

    handleClickCancelEdit();
  };

  const handleChangeAddTest = (event: SelectChangeEvent | any) => {
    if (event !== null) {
      let chipName: string = event.value;

      if (event.key === 'Enter') {
        event.preventDefault();
        chipName = event.target.value;
      }

      if (_.isEmpty(chipName)) {
        return;
      }

      // setting active chip
      handleClickActiveChip(chipName);

      setAttachmentReportTests((prev: any) => {
        const duplicateChip = prev.find((ele: V2PatientAttachmentReportsPayloadReportType) => ele.name === chipName);

        if (duplicateChip) {
          return prev;
        }

        const testItems = LAB_REPORT_TEST.find((item) => item.profileName === chipName);

        const tests = (testItems?.tests || []).map((item: string, index: number) => ({ id: index, name: item, value: '' }));

        return [...prev, { name: chipName, tests }];
      });
    } else {
      // Setting null value for react-select as data is cleared
      setAddTestReactSelect(null);
    }
  };

  const handleOpenPdf = (file: any) => {
    if (file.fileId) {
      setSelectedPdfUrl({ pdfUrl: file?.fileUrl, fileType: file.type });
    } else if (file.fileUrl) {
      setSelectedPdfUrl({ pdfUrl: file?.fileUrl, fileType: file.file.type });
    } else {
      const pdfDataUrl = URL.createObjectURL(file.file);
      setSelectedPdfUrl({ pdfUrl: pdfDataUrl, fileType: file.file.type });
    }
  };

  const handleAddCipTableColumn = () => {
    setAttachmentReportTests((prev: any) => {
      const updatedChip = prev.find((ele: V2PatientAttachmentReportsPayloadReportType) => ele.name === selectedAttachmentReportTest);

      if (updatedChip) {
        return prev.map((chip: V2PatientAttachmentReportsPayloadReportType) => {
          if (chip.name === selectedAttachmentReportTest) {
            return {
              name: chip.name,
              tests: [...chip.tests, { id: chip.tests.length + 1, name: '', value: '' }],
            };
          }

          return chip;
        });
      }

      return prev;
    });
  };

  const handleChangeInputReports = (value: any, dataIndex: number, variable: string) => {
    setAttachmentReportTests((prev: V2PatientAttachmentReportsPayloadReportType[]) => {
      return prev.map((item) => {
        if (item.name === selectedAttachmentReportTest) {
          const updatedItem = item.tests?.map((ele, index) => {
            if (index === dataIndex) {
              return { ...ele, [variable]: value };
            }

            return ele;
          });

          return {
            ...item,
            tests: updatedItem,
          };
        }

        return item;
      });
    });
  };

  const handleClickDelete = () => {
    setIsOpenDeleteReportModal(true);
  };

  const handleSubmitDeleteReport = () => {
    props.handleClickDeleteAttachmentReport(props.attachmentReport);
    handleCloseDeleteReport();
  };

  const handleCloseDeleteReport = () => {
    setIsOpenDeleteReportModal(false);
  };

  const handleReportValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    if (value.length > 100) {
      setReportNameError('Report name must be less than 100 characters');
    } else {
      setReportNameError('');
    }

    setReportName(value);
  };

  const handleReportSummary = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length > 300) {
      setSummaryError('Summary must be less than 300 characters');
    } else {
      setSummaryError('');
    }
    setReportSummary(value);
  };

  const handleFileInputChange = async (event: any) => {
    const file = event.target.files[0];
    if (file && (file.type === 'application/pdf' || file.type === 'image/jpeg')) {
      if (attachmentReportFiles.length < 5) {
        const attachmentId: any = await handleSubmitPatientAttachments(file, props.attachmentReport.type);
        setAttachmentReportFiles((prev: any) => [...prev, { attachmentId: attachmentId.attachmentId, file }]);
        setCheckAttachmentCount(false);
      } else {
        reduxDispatch(setToast({ message: 'Upload limit exceeded: Only 5 files are allowed.', code: 'error' }));
        setCheckAttachmentCount(true);
      }
    }

    setInputFiles('');
    setTimeout(() => {
      setInputFiles('');
    }, 100);
  };

  const handleDeleteSelectedPdf = (e: any) => {
    setCheckAttachmentCount(false);
    const updatedPdfList = attachmentReportFiles.filter((file: any) => file !== e);

    setAttachmentReportFiles(updatedPdfList);
    setSelectedPdfUrl(null);
  };
  const handleDeleteSelectedValues = (chipName: string) => {
    const updatedSelectedValues = attachmentReportTests.filter((values: any) => values.name !== chipName);
    setAttachmentReportTests(updatedSelectedValues);
  };

  const renderAttachmentData = () => {
    if (props.attachmentReport.type === 'HOSTPITALISATION') {
      return (
        <Stack direction='row' justifyContent='space-between'>
          <Typography pt='10px'>Hospitalisation / Discharge data</Typography>

          <Box>
            <FormControlLabel control={<Checkbox />} label='Cardiac Admit' checked={cardiacAdmit} onChange={(e: any) => setCardiacAdmit(e.target.checked)} disabled={!isEdit} />
          </Box>
        </Stack>
      );
    } else if (!isEdit) {
      return (
        <Typography noWrap sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
          {reportName}
        </Typography>
      );
    } else {
      return (
        <Box>
          <Input
            placeholder='Report*'
            disableUnderline={true}
            onChange={handleReportValue}
            value={reportName}
            error={!_.isEmpty(reportNameError)}
            sx={{
              border: '1px solid #9BB4CC',
              borderRadius: '5px',
              width: '100%',
              height: '32px',
              padding: '0 10px',
              '.MuiInput-input': {
                padding: 0,
              },
              '::placeholder': {
                color: '#3E4685',
              },
            }}
          />
          {!_.isEmpty(reportNameError) && <FormHelperText error>{reportNameError}</FormHelperText>}
        </Box>
      );
    }
  };
  const renderAttachmentCalender = () => {
    if (props.attachmentReport.type === 'HOSTPITALISATION') {
      return (
        <Box>
          <Grid item xs={12} container spacing={2} gap={2}>
            <Grid item xs={5.6}>
              <Typography variant='fontReg12'>
                Hospitalisation date{' '}
                <Typography color='error' component='span'>
                  *
                </Typography>
              </Typography>
              <DateRangePicker
                maxDate={new Date()}
                handleChangeStartDate={console.info}
                placement={'bottom-end'}
                handleChangeEndDate={console.info}
                showCalendarIcon={false}
                showIcon={false}
                preSelectedDate={hospitalizationDate}
                selectsRange={false}
                dateFormat={'MMM d, yyyy'}
                disabled={!isEdit}
                label='Select'
                handleChangeDate={handleChangeDateforHospitalisation}
              />
            </Grid>
            <Grid item xs={5.6}>
              <Typography variant='fontReg12'>Discharge date</Typography>
              <DateRangePicker
                disabled={!isEdit}
                maxDate={new Date()}
                handleChangeStartDate={console.info}
                placement={'bottom-end'}
                handleChangeEndDate={console.info}
                startDate={dischargeDate}
                endDate={dischargeDate}
                preSelectedDate={dischargeDate}
                showCalendarIcon={false}
                showIcon={false}
                selectsRange={false}
                dateFormat={'MMM d, yyyy'}
                label='Select'
                handleChangeDate={handleChangeDateforDischarge}
              />
            </Grid>
          </Grid>
          {checkDateHospitalisationDisChargeDate && <FormHelperText error>{checkDateHospitalisationDisChargeDate}</FormHelperText>}
        </Box>
      );
    } else if (isEdit) {
      return (
        <DateRangePicker
          maxDate={new Date()}
          handleChangeStartDate={console.info}
          placement={'bottom-end'}
          handleChangeEndDate={console.info}
          startDate={reportsDate}
          endDate={reportsDate}
          showCalendarIcon={false}
          showIcon={false}
          selectsRange={false}
          disabled={!isEdit}
          dateFormat={'MMM d, yyyy'}
          preSelectedDate={reportsDate}
          handleChangeDate={handleChangeDate}
        />
      );
    } else {
      return (
        <Typography sx={{ borderBottom: '1px solid #667184' }}>
          <span>{DateTime.fromJSDate(new Date(reportsDate)).toFormat('MMM d, yyyy')}</span>
        </Typography>
      );
    }
  };

  React.useEffect(() => {
    if (props.attachmentReport) {
      if (props.attachmentReport?.attachments) {
        setAttachmentReportFiles(props.attachmentReport?.attachments);
      }

      if (props.attachmentReport?.name) {
        setReportName(props.attachmentReport?.name);
      }

      if (props.attachmentReport?.summary) {
        setReportSummary(props.attachmentReport?.summary);
      } else {
        setReportSummary('');
      }

      if (props.attachmentReport?.reports) {
        setAttachmentReportTests(props.attachmentReport?.reports);
      }

      if (props.attachmentReport?.reportDate) {
        setReportsDate(props.attachmentReport?.reportDate);
      }

      if (props.attachmentReport?.attachmentReportId === NEW_ATTACHMENT_REPORT_ID) {
        setIsEdit(true);
      }
      if (props.attachmentReport?.payload?.cardiacAdmit) {
        setCardiacAdmit(Boolean(props.attachmentReport?.payload?.cardiacAdmit));
      }

      if (props.attachmentReport?.payload?.hospitalizationDate) {
        const parsedDate = new Date(props.attachmentReport?.payload?.hospitalizationDate).toISOString();

        setHospitalisationDate(parsedDate);
      }
      if (props.attachmentReport?.payload?.dischargeDate) {
        const parsedDate = new Date(props.attachmentReport?.payload?.dischargeDate).toISOString();
        setdischargeDate(parsedDate);
      }
    }
  }, [props.attachmentReport]);

  let disabledNtn =
    _.isEmpty(reportName) ||
    !_.isEmpty(reportNameError) ||
    !_.isEmpty(summaryError) ||
    (props.attachmentReport?.type === 'REPORTS' ? _.isEmpty(attachmentReportTests) : _.isEmpty(attachmentReportFiles));

  if (props.attachmentReport?.type === 'HOSTPITALISATION') {
    disabledNtn = !_.isEmpty(summaryError) || !_.isEmpty(checkDateHospitalisationDisChargeDate) || _.isEmpty(hospitalizationDate) || _.isEmpty(attachmentReportFiles);
  }

  return (
    <Box
      id='patient-attachments-report-card'
      sx={{
        border: '1px solid #9BB4CC',
        borderRadius: '10px',
        padding: '14px',
        zIndex: '1',
      }}>
      {isOpenDeleteReport && (
        <ConfirmationDialog
          description='Once deleted, the action cannot be undone.'
          title='Are you sure you want to delete this report?'
          onClickConfirm={handleSubmitDeleteReport}
          isOpen={isOpenDeleteReport}
          onClose={handleCloseDeleteReport}
        />
      )}

      <FormControl fullWidth>
        {renderAttachmentData()}
        {!isEdit ? (
          <Typography noWrap sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {reportSummary}
          </Typography>
        ) : (
          <Box mb={props.attachmentReport.type === 'HOSTPITALISATION' ? '8px' : 0}>
            <Input
              placeholder={props.attachmentReport.type === 'HOSTPITALISATION' ? 'Enter notes if any.' : 'Summary'}
              disableUnderline={true}
              onChange={handleReportSummary}
              error={!_.isEmpty(summaryError)}
              value={reportSummary}
              sx={{
                border: '1px solid #9BB4CC',
                width: '100%',
                borderRadius: '5px',
                height: '32px',
                padding: '0 10px',
                '.MuiInput-input': {
                  padding: 0,
                },
                '::placeholder': {
                  color: '#3E4685',
                },
                marginTop: '10px',
              }}
            />
            {!_.isEmpty(summaryError) && <FormHelperText error>{summaryError}</FormHelperText>}
          </Box>
        )}
      </FormControl>

      <Grid container justifyContent={'space-between'} alignItems={'center'} mt={1}>
        {props.attachmentReport?.type === 'REPORTS' && (
          <>
            <Grid item xs={5.6}>
              {!isEdit ? (
                <Typography>Tests</Typography>
              ) : (
                <Select
                  placeholder={
                    <div style={{ display: 'flex', alignItems: 'center', color: 'grey' }}>
                      <AddIcon />
                      <Typography color='#33425B'>
                        Add Tests
                        {props.attachmentReport.type === 'REPORTS' && (
                          <Typography color='error' component='span'>
                            *
                          </Typography>
                        )}
                      </Typography>
                    </div>
                  }
                  value={addTestReactSelect}
                  name='Add Tests'
                  className='basic-single'
                  classNamePrefix='Add Tests'
                  isClearable
                  isOptionDisabled={(option: any) => option.disabled}
                  isSearchable
                  options={LAB_REPORT_TEST.map((item) => ({
                    label: item.profileName,
                    value: item.profileName,
                    disabled: !_.isEmpty(attachmentReportTests?.find((ele) => ele.name === item.profileName)),
                  }))}
                  onChange={handleChangeAddTest}
                  onKeyDown={handleChangeAddTest}
                />
              )}
            </Grid>
            <Divider orientation='vertical' style={{ width: 2, height: '35px' }} variant='middle' flexItem />
          </>
        )}

        <Grid item xs={props.attachmentReport?.type === 'REPORTS' ? 5.6 : 12}>
          {!isEdit ? (
            <Typography>Files</Typography>
          ) : (
            <Box
              sx={{
                border: '1px solid #9BB4CC',
                borderRadius: '5px',
                height: '35px',
                padding: '3px 10px',
              }}>
              <label htmlFor='file-upload' style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <AddIcon sx={{ color: 'grey', marginRight: '5px' }} />
                <Typography color='#33425B'>
                  Add Files
                  {['HOSTPITALISATION', 'PRESCRIPTIONS', 'OTHERS'].includes(props.attachmentReport.type) && (
                    <Typography color='error' component='span'>
                      *
                    </Typography>
                  )}
                </Typography>
              </label>
              <input
                id='file-upload'
                type='file'
                accept='.pdf, .jpeg, .jpg'
                style={{ display: 'none' }}
                disabled={checkAttachmentCount || !isEdit}
                onChange={handleFileInputChange}
                value={inputFiles}
              />
            </Box>
          )}
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 1 }}>
        {props.attachmentReport?.type === 'REPORTS' && (
          <Grid item container xs={5.5} gap={0.5}>
            {attachmentReportTests.map((ele: any, id: number) => (
              <LabReportItem
                key={id}
                isEdit={isEdit}
                labTestItems={ele}
                isOpen={ele.name === selectedAttachmentReportTest}
                handlClickReportChip={() => handleClickActiveChip(ele.name)}
                handleCloseReportChip={handleCloseReportActive}
                handleDeleteSelectedValues={handleDeleteSelectedValues}
                handleOnChange={handleChangeInputReports}
                handleAddTableColumn={handleAddCipTableColumn}
              />
            ))}
          </Grid>
        )}

        {props.attachmentReport?.type === 'REPORTS' && <Grid item xs={0.9}></Grid>}

        <Grid item container xs={props.attachmentReport?.type === 'REPORTS' ? 5.6 : 12} gap={0.5}>
          {attachmentReportFiles?.map((e: any, index: number) => (
            <Chip
              size='medium'
              label={isPatientAttachmentUploading && !attachmentReportFiles[index] ? <CircularProgress size={10} /> : e?.file?.name || e?.fileName}
              key={index}
              deleteIcon={<ZoomDeleteIcon />}
              {...(isEdit
                ? {
                    onDelete: () => {
                      if (isEdit) {
                        handleDeleteSelectedPdf(e);
                      }
                    },
                  }
                : {})}
              onClick={() => handleOpenPdf(e)}
            />
          ))}
        </Grid>
      </Grid>

      <Grid container justifyContent={'space-between'} mt={2.5} gap={3}>
        <Grid item xs>
          {renderAttachmentCalender()}
        </Grid>
        <Grid item container xs='auto' gap={2} justifyContent={'right'} height='10%'>
          {!isEdit && (
            <Button variant='contained' onClick={toggleEditBtn}>
              Edit
            </Button>
          )}
          {!isEdit && (
            <Button variant='contained' onClick={handleClickDelete}>
              Delete
            </Button>
          )}
          {isEdit && (
            <Button disabled={disabledNtn} variant='contained' onClick={handleSubmit}>
              Save
            </Button>
          )}
          {isEdit && (
            <Button variant='outlined' onClick={handleClickCancelEdit}>
              Cancel
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default AddReport;
