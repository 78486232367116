/** @format */

import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';

import HrIcon from '../../../../assets/HrIcon';
import O2Icon from '../../../../assets/O2Icon';
import SpbIcon from '../../../../assets/SpbIcon';
import WeightIcon from '../../../../assets/WeightIcon';
import MedicalEventIcon from '../../../../assets/MedicalEventIcon';
import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';

import { xAxisCorePropsInterfacePropsType } from '../../../../types/RCACharts.types';

export const vitalsChartsConfig = (props?: xAxisCorePropsInterfacePropsType | undefined, xAxisCore?: any, vitalsUnits?: any, maxMedicalEvents?: number, data?: any) => {
  return [
    {
      chartName: 'MEDICAL-EVENTS',
      label: 'Medical Events',
      chartTitle: 'Medical Events',
      unit: '',
      height: Math.max(maxMedicalEvents! * 25, 150),
      icon: <MedicalEventIcon />,
      referenceValue: 120,
      lastestValue: 120,
      data: data?.medicalEventsValues,
      coreOptions: {
        yAxisRange: {
          type: props?.range ? 'MANUAL' : 'AUTO',
          max: props?.upperBound,
          min: props?.lowerBound,
        },
        yAxisTicks: props?.yAxisTicks,
        xAxisTickSize: props?.xAxisTickSize,
        yAxisTickSize: props?.yAxisTickSize,
        customSVGElement: xAxisCore?.core?.svgElement,
        rangeYAxisOffset: props?.rangeYAxisOffset,
      },
      plugin: [
        {
          pluginName: 'GRID',
          options: {},
        },
        {
          pluginName: 'APPEND-SVG-ICON',
          options: {},
        },
      ],
    },
    {
      chartName: 'SBP',
      label: 'SBP',
      chartTitle: 'SBP',
      unit: vitalsUnits?.bloodPressure,
      height: 200,
      icon: <SpbIcon />,
      referenceValue: 120,
      lastestValue: 120,
      data: data?.sbpData,
      coreOptions: {
        yAxisRange: {
          type: props?.range ? 'MANUAL' : 'AUTO',
          max: props?.upperBound,
          min: props?.lowerBound,
        },
        yAxisTicks: props?.yAxisTicks,
        xAxisTickSize: props?.xAxisTickSize,
        yAxisTickSize: props?.yAxisTickSize,
        customSVGElement: xAxisCore?.core?.svgElement,
        rangeYAxisOffset: props?.rangeYAxisOffset,
      },
      plugin: [
        {
          pluginName: 'GRID',
          options: {},
        },
        {
          pluginName: 'MEDIAN-LINE',
          options: {},
        },
        {
          pluginName: 'DOT-TOOLTIP',
          options: {},
        },
        {
          pluginName: 'SVG-ICON-TOOLTIP',
          options: {},
        },
      ],
    },
    {
      chartName: 'DBP',
      label: 'DBP',
      chartTitle: 'DBP',
      unit: vitalsUnits?.bloodPressure,
      height: 200,
      icon: <SpbIcon />,
      referenceValue: 120,
      lastestValue: 120,
      data: data?.dbpData,
      coreOptions: {
        yAxisRange: {
          type: props?.range ? 'MANUAL' : 'AUTO',
          max: props?.upperBound,
          min: props?.lowerBound,
        },
        yAxisTicks: props?.yAxisTicks,
        xAxisTickSize: props?.xAxisTickSize,
        yAxisTickSize: props?.yAxisTickSize,
        customSVGElement: xAxisCore?.core?.svgElement,
        rangeYAxisOffset: props?.rangeYAxisOffset,
      },
      plugin: [
        {
          pluginName: 'GRID',
          options: {},
        },
        {
          pluginName: 'MEDIAN-LINE',
          options: {},
        },
        {
          pluginName: 'DOT-TOOLTIP',
          options: {},
        },
        {
          pluginName: 'SVG-ICON-TOOLTIP',
          options: {},
        },
      ],
    },
    {
      chartName: 'WEIGHT',
      label: 'Weight',
      chartTitle: 'Weight',
      unit: vitalsUnits?.weight,
      height: 200,
      icon: <WeightIcon />,
      referenceValue: 120,
      lastestValue: 120,
      data: data?.weightData,
      coreOptions: {
        yAxisRange: {
          type: props?.range ? 'MANUAL' : 'AUTO',
          max: props?.upperBound,
          min: props?.lowerBound,
        },
        yAxisTicks: props?.yAxisTicks,
        xAxisTickSize: props?.xAxisTickSize,
        yAxisTickSize: props?.yAxisTickSize,
        customSVGElement: xAxisCore?.core?.svgElement,
        rangeYAxisOffset: props?.rangeYAxisOffset,
      },
      plugin: [
        {
          pluginName: 'GRID',
          options: {},
        },
        {
          pluginName: 'MEDIAN-LINE',
          options: {},
        },
        {
          pluginName: 'DOT-TOOLTIP',
          options: {},
        },
        {
          pluginName: 'SVG-ICON-TOOLTIP',
          options: {},
        },
      ],
    },
    {
      chartName: 'HR-OMRON',
      label: 'HR',
      chartTitle: 'Pulse Rate',
      unit: vitalsUnits?.heartRate,
      height: 200,
      icon: <HrIcon />,
      referenceValue: 120,
      lastestValue: 120,
      data: data?.hrData,
      coreOptions: {
        yAxisRange: {
          type: props?.range ? 'MANUAL' : 'AUTO',
          max: props?.upperBound,
          min: props?.lowerBound,
        },
        yAxisTicks: props?.yAxisTicks,
        xAxisTickSize: props?.xAxisTickSize,
        yAxisTickSize: props?.yAxisTickSize,
        customSVGElement: xAxisCore?.core?.svgElement,
        rangeYAxisOffset: props?.rangeYAxisOffset,
      },
      plugin: [
        {
          pluginName: 'GRID',
          options: {},
        },
        {
          pluginName: 'MEDIAN-LINE',
          options: {},
        },
        {
          pluginName: 'DOT-TOOLTIP',
          options: {},
        },
        {
          pluginName: 'SVG-ICON-TOOLTIP',
          options: {},
        },
      ],
    },
    {
      chartName: 'HR-APPLE',
      label: 'Pulse Rate',
      chartTitle: 'Pulse Rate',
      unit: vitalsUnits?.heartRate,
      height: 200,
      icon: <HrIcon />,
      referenceValue: 120,
      lastestValue: 120,
      data: data?.appleWatchHrData,
      coreOptions: {
        yAxisRange: {
          type: props?.range ? 'MANUAL' : 'AUTO',
          max: props?.upperBound,
          min: props?.lowerBound,
        },
        yAxisTicks: props?.yAxisTicks,
        xAxisTickSize: props?.xAxisTickSize,
        yAxisTickSize: props?.yAxisTickSize,
        customSVGElement: xAxisCore?.core?.svgElement,
        rangeYAxisOffset: props?.rangeYAxisOffset,
      },
      plugin: [
        {
          pluginName: 'GRID',
          options: {},
        },
        {
          pluginName: 'MEDIAN-LINE',
          options: {},
        },
        {
          pluginName: 'DOT-TOOLTIP',
          options: {},
        },
        {
          pluginName: 'SVG-ICON-TOOLTIP',
          options: {},
        },
      ],
    },
    {
      chartName: 'SPO2',
      label: 'SPO2',
      chartTitle: 'SPO2',
      unit: '%',
      height: 200,
      icon: <O2Icon />,
      referenceValue: 120,
      lastestValue: 120,
      data: data?.appleWatchSpo2Data,
      coreOptions: {
        yAxisRange: {
          type: props?.range ? 'MANUAL' : 'AUTO',
          max: props?.upperBound,
          min: props?.lowerBound,
        },
        yAxisTicks: props?.yAxisTicks,
        xAxisTickSize: props?.xAxisTickSize,
        yAxisTickSize: props?.yAxisTickSize,
        customSVGElement: xAxisCore?.core?.svgElement,
        rangeYAxisOffset: props?.rangeYAxisOffset,
      },
      plugin: [
        {
          pluginName: 'GRID',
          options: {},
        },
        {
          pluginName: 'MEDIAN-LINE',
          options: {},
        },
        {
          pluginName: 'DOT-TOOLTIP',
          options: {},
        },
      ],
    },
    {
      chartName: 'STEPS',
      label: 'Steps',
      chartTitle: 'Steps',
      unit: 'count',
      height: 200,
      icon: <DirectionsWalkIcon htmlColor='#5C6A90' />,
      referenceValue: 120,
      lastestValue: 120,
      data: data?.appleWatchStepCount,
      coreOptions: {
        yAxisRange: {
          type: props?.range ? 'MANUAL' : 'AUTO',
          max: props?.upperBound,
          min: props?.lowerBound,
        },
        yAxisTicks: props?.yAxisTicks,
        xAxisTickSize: props?.xAxisTickSize,
        yAxisTickSize: props?.yAxisTickSize,
        customSVGElement: xAxisCore?.core?.svgElement,
        rangeYAxisOffset: props?.rangeYAxisOffset,
      },
      plugin: [
        {
          pluginName: 'GRID',
          options: {},
        },
        {
          pluginName: 'BAR',
          options: {},
        },
        // {
        //   pluginName: 'MEDIAN-LINE',
        //   options: {},
        // },
        // {
        //   pluginName: 'DOT-TOOLTIP',
        //   options: {},
        // },
      ],
    },

    {
      chartName: 'Fluid Intake',
      label: 'Fluid Intake',
      chartTitle: 'Fluid Intake',
      unit: 'Litre',
      timeformat: 'dd MMM',
      height: 200,
      icon: <WaterfallChartIcon htmlColor='#5C6A90' />,
      referenceValue: 0,
      lastestValue: 0,
      data: data?.fluidIntake,
      coreOptions: {
        yAxisRange: {
          type: props?.range ? 'MANUAL' : 'AUTO',
          max: props?.upperBound,
          min: props?.lowerBound,
        },
        yAxisTicks: props?.yAxisTicks,
        xAxisTickSize: props?.xAxisTickSize,
        yAxisTickSize: props?.yAxisTickSize,
        customSVGElement: xAxisCore?.core?.svgElement,
        rangeYAxisOffset: props?.rangeYAxisOffset,
      },
      plugin: [
        {
          pluginName: 'GRID',
          options: {},
        },
        {
          pluginName: 'MEDIAN-LINE',
          options: {},
        },
        {
          pluginName: 'DOT-TOOLTIP',
          options: {},
        },
      ],
    },
  ];
};
