/** @format */

import * as React from 'react';
import Draggable from 'react-draggable';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';

import ChatBox from './ChatBox';

import { BasicDetailsType } from '../../types/Patients.types';
import { PatientCareGiverType } from '../../types/PatientCareGiver';

interface ChatModalProps {
  isOpen: boolean;
  handleClose: () => void;

  patientBasicInfo: BasicDetailsType;

  userList: any[];

  patientCareGivers: PatientCareGiverType[];
}

const ChatModal: React.FunctionComponent<ChatModalProps> = (props) => {
  const _renderPaperComponent = React.useCallback((props: PaperProps) => {
    return (
      <Draggable handle='#draggable-dialog-title' cancel={'[class*="MuiDialogContent-root"], [class*="Mui-notdrag"]'}>
        <Paper {...props} style={{ margin: 0, height: '60vh', width: 500 }} />
      </Draggable>
    );
  }, []);

  return (
    <Dialog
      open={props.isOpen}
      fullWidth
      PaperComponent={_renderPaperComponent}
      hideBackdrop={true}
      disableEnforceFocus
      disableAutoFocus
      style={{
        top: '30%', // Position however you like
        left: '30%',
        height: 'auto',
        width: '500',
      }}
      disablePortal>
      <DialogTitle id='draggable-dialog-title' sx={{ p: 0, height: 'inherit' }}>
        <ChatBox patientBasicInfo={props.patientBasicInfo} userList={props.userList} handleClose={props.handleClose} patientCareGivers={props.patientCareGivers} />
      </DialogTitle>
    </Dialog>
  );
};

export default ChatModal;
